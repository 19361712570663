import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { FaCheckCircle, FaTimesCircle, FaTrash } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import 'react-tooltip/dist/react-tooltip.css';

const SellerIncomingOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const accessKey = localStorage.getItem('token');

  const fetchOrders = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessKey}`);
    myHeaders.append("Content-Type", "application/json");

    try {
      const response = await fetch("https://lionfish-app-nh929.ondigitalocean.app/orders/incoming/all/", {
        method: "GET",
        headers: myHeaders
      });
      
      if (response.status === 404) {
        setOrders([]);
        return;
      }
      
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      
      const result = await response.json();
      setOrders(result);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleAction = async (orderId, action) => {
    const url = `https://lionfish-app-nh929.ondigitalocean.app/orders/${orderId}/${action}/`;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessKey}`);
    myHeaders.append("Content-Type", "application/json");

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: myHeaders
      });

      if (!response.ok) {
        throw new Error(`Failed to ${action} order`);
      }

      setMessage(`Successfully ${action}ed order ${orderId}`);
      setTimeout(() => setMessage(""), 2000);
      fetchOrders();
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (orders.length === 0) return <div>No orders available</div>;

  return (
    <div className="table-responsive">
      {message && <div className="alert alert-success">{message}</div>}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Order ID</th>
            <th>User</th>
            <th>Amount</th>
            <th>Listing</th>
            <th>Order Type</th>
            <th>Target Site</th>
            <th>Balance Type</th>
            <th>Status</th>
            <th>Details</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.order_id}>
              <td>{order.order_id}</td>
              <td>{order.user}</td>
              <td>${order.amount.toFixed(2)}</td>
              <td>{order.listing}</td>
              <td>{order.order_type}</td>
              <td>{order.target_site}</td>
              <td>{order.balance_type}</td>
              <td>{order.status}</td>
              <td>{order.detail}</td>
              <td>
                <FaCheckCircle
                  style={{ cursor: "pointer", color: "green", marginRight: "10px" }}
                  data-tooltip-id="approve-tooltip"
                  data-tooltip-content="Approve Order"
                  onClick={() => handleAction(order.order_id, "accept")}
                />
                <FaTimesCircle
                  style={{ cursor: "pointer", color: "red", marginRight: "10px" }}
                  data-tooltip-id="reject-tooltip"
                  data-tooltip-content="Reject Order"
                  onClick={() => handleAction(order.order_id, "reject")}
                />
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => navigate(`/incomingorder/${order.order_id}`)}
                >
                  Show Details
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Tooltip id="approve-tooltip" />
      <Tooltip id="reject-tooltip" />
      <Tooltip id="delete-tooltip" />
    </div>
  );
};

export default SellerIncomingOrders;
