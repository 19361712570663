import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { FaCheckCircle, FaTimesCircle, FaEdit } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'; // Import CSS for versions before v5.13.0

const AllUsers = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const accesskey = localStorage.getItem('token');
   const handleApprove = async (listingId) => {
     try {
       const response = await fetch(`http://127.0.0.1:8000/listings/${listingId}/approve/`, {
         method: "POST",
         headers: {
           "Authorization": `Bearer ${accesskey}`,
           "Content-Type": "application/json",
         },
       });
   
       if (!response.ok) {
         throw new Error(`Error approving listing: ${response.status} - ${response.statusText}`);
       }
   
       const result = await response.json();
       alert(`Listing ${listingId} approved successfully!`);
       
       // Update the UI to remove the approved listing
       setData(data.filter((listing) => listing.listing_id !== listingId));
     } catch (error) {
       console.error(error);
       alert("Failed to approve the listing. Please try again.");
     }
   };
   
   const handleReject = async (listingId) => {
     try {
       const response = await fetch(`http://127.0.0.1:8000/listings/${listingId}/reject/`, {
         method: "POST",
         headers: {
           "Authorization": `Bearer ${accesskey}`,
           "Content-Type": "application/json",
         },
       });
   
       if (!response.ok) {
         throw new Error(`Error rejecting listing: ${response.status} - ${response.statusText}`);
       }
   
       const result = await response.json();
       alert(`Listing ${listingId} rejected successfully!`);
       
       // Update the UI to remove the rejected listing
       setData(data.filter((listing) => listing.listing_id !== listingId));
     } catch (error) {
       console.error(error);
       alert("Failed to reject the listing. Please try again.");
     }
   };
   
   
   const handleEdit = (listingId) => {
     console.log("Editing listing with ID:", listingId);
     // Implement the logic for editing the listing
   };
 
   useEffect(() => {
     const fetchData = async () => {
       const myHeaders = new Headers();
       myHeaders.append(
         "Authorization",
         `Bearer ${accesskey}` // Use backticks for string interpolation
     );
 
       const requestOptions = {
         method: "GET",
         headers: myHeaders,
         redirect: "follow",
       };
 
       try {
         const response = await fetch("https://lionfish-app-nh929.ondigitalocean.app/users/", requestOptions);
         if (!response.ok) {
           throw new Error(`Error: ${response.status} - ${response.statusText}`);
         }
         const user = localStorage.getItem('user')
         const result = await response.json();
         const ownListings = result.filter(
             (request) => request.status === "Pending"
           );
         setData(result);
         setLoading(false);
       } catch (error) {
         setError(error.message);
         setLoading(false);
       }
     };
 
     fetchData();
   }, []);
 

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="table-responsive">
  <table className="table table-transparent">
      <thead>
        <tr>
          <th>Email</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>User ID</th>
          <th>Country</th>
          <th>Phone</th>
          <th>User Type</th>
        </tr>
      </thead>
      <tbody>
        {data.map((user, index) => (
          <tr key={index}>
            <td>{user.email}</td>
            <td>{user.first_name || "N/A"}</td>
            <td>{user.last_name || "N/A"}</td>
            <td>{user.user_id || "N/A"}</td>
            <td>{user.country || "N/A"}</td>
            <td>{user.phone || "N/A"}</td>
            <td>{user.is_admin ? "Admin" : "User"}</td>
          </tr>
        ))}
      </tbody>
    </table>
      {/* Add Tooltip Components */}
      <Tooltip id="approve-tooltip" />
      <Tooltip id="reject-tooltip" />
      <Tooltip id="edit-tooltip" />
    </div>
  );
};

export default AllUsers;
