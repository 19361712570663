import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import BuyNowButton from "./BuyNowButton";

const Balance = () => {


      const [mainBalance, setMainBalance] = useState(null);
      const [referralBalance, setReferralBalance] = useState(null);
  const fetchBalances = async () => {
    const accessToken = localStorage.getItem("token"); // Get token from localStorage
    if (!accessToken) {
      console.error("Access token is missing!");
      return;
    }

    const userid = localStorage.getItem("user"); 
    console.log(userid);// Get token from localStorage
    if (!userid) {
      console.error("user is missing!");
      return;
    }
    const url = `https://lionfish-app-nh929.ondigitalocean.app/wallet/${userid}/`;

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        const data = await response.json();
        // Assuming the API returns an object with main_balance and referral_balance fields
        const { main_balance, referral_balance } = data;

        // Save to localStorage
        localStorage.setItem("main_balance", main_balance);
        localStorage.setItem("referral_balance", referral_balance);

        // Update state
        setMainBalance(main_balance);
        setReferralBalance(referral_balance);
      } else {
        console.error("Failed to fetch balances:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching balances:", error);
    }
  };

  // Check localStorage and fetch balances if necessary
  useEffect(() => {
    const storedMainBalance = localStorage.getItem("main_balance");
    const storedReferralBalance = localStorage.getItem("referral_balance");

    if (storedMainBalance && storedReferralBalance) {
      // Use balances from localStorage
      setMainBalance(storedMainBalance);
      setReferralBalance(storedReferralBalance);
    } else {
      // Fetch balances from API
      fetchBalances();
    }
  }, []);




  return (
    <div className="container">
        <div className="row">
            <div className="col-md-6">
          
            </div>
            <div className="col-md-6">

                </div>
        </div>
    </div>
  );
};

export default Balance;
