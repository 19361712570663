import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import "./CaseStudy.css";

const caseStudies = [
  {
    id: "1",
    image: "https://placehold.co/300x200",
    title: "How We Provide 11x Traffic in 4 Months with Simple Link!",
    description: "To help you achieve your financial goals and ensure every day provides a dedicated feed across the board.",
    link: "#",
  },
  {
    id: "2",
    image: "https://placehold.co/300x200",
    title: "How We Provide 11x Traffic in 4 Months with Simple Link!",
    description: "To help you achieve your financial goals and ensure every day provides a dedicated feed across the board.",
    link: "#",
  },
  {
    id: "3",
    image: "https://placehold.co/300x200",
    title: "How We Provide 11x Traffic in 4 Months with Simple Link!",
    description: "To help you achieve your financial goals and ensure every day provides a dedicated feed across the board.",
    link: "#",
  },
  {
    id: "4",
    image: "https://placehold.co/300x200",
    title: "How We Provide 11x Traffic in 4 Months with Simple Link!",
    description: "To help you achieve your financial goals and ensure every day provides a dedicated feed across the board.",
    link: "#",
  },
  {
    id: "5",
    image: "https://placehold.co/300x200",
    title: "How We Provide 11x Traffic in 4 Months with Simple Link!",
    description: "To help you achieve your financial goals and ensure every day provides a dedicated feed across the board.",
    link: "#",
  },
];

const CaseStudyCarousel = () => {
  return (
    <div className="case-study-container container mb-5">
      {/* Header Section */}
      <div className="row mb-4 align-items-center">
        <div className="col-md-8">
          <h2 className="subheader">These Data Driven Results Are The Proof</h2>
        </div>
        <div className="col-md-4 text-md-end">
          <button className="btn btn-primary">View All Case Studies</button>
        </div>
      </div>

      {/* Swiper Carousel */}
      <Swiper
        modules={[Navigation]}
        spaceBetween={20}
        slidesPerView={1} // Default for small screens
        navigation
        breakpoints={{
          640: { slidesPerView: 1 }, // Screens <640px
          768: { slidesPerView: 2 }, // Screens 640px - 1024px
          1025: { slidesPerView: 3 }, // Screens >1024px
        }}
      >
        {caseStudies.map((caseStudy) => (
          <SwiperSlide key={caseStudy.id}>
            <div className="card case-study-card h-100">
              <img src={caseStudy.image} className="card-img-top" alt={caseStudy.title} />
              <div className="card-body">
                <h5 className="card-title">{caseStudy.title}</h5>
                <p className="card-text">{caseStudy.description}</p>
                <a href={caseStudy.link} className="btn btn-link text-primary">
                  View Full Study →
                </a>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CaseStudyCarousel;
