import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";

const ProjectOrderDetails = () => {
  const { projectId, orderId } = useParams();

  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchOrderDetails = async () => {
      setLoading(true);
      setError("");

      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Access token is missing!");
        return;
      }

      const url = `https://lionfish-app-nh929.ondigitalocean.app/projects/${projectId}/orders/${orderId}/`;
      const myHeaders = new Headers({
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      });

      try {
        const response = await fetch(url, { method: "GET", headers: myHeaders });
        if (response.ok) {
          const result = await response.json();
          setOrder(result);
        } else {
          setError("Failed to fetch order details.");
        }
      } catch (error) {
        console.error(error);
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [orderId, projectId]);

  return (
    <div style={{ padding: "1rem", maxWidth: "100%", overflowX: "auto" }}>
      <h2>Order Details</h2>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : order ? (
        <Table bordered hover responsive className="mt-4">
          <thead>
            <tr>
              <th>Amount</th>
              <th>Listing</th>
              <th>Order Type</th>
              <th>Target Site</th>
              <th>Balance Type</th>
              <th>Status</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>${order.amount.toFixed(2)}</td>
              <td>{order.listing}</td>
              <td>{order.order_type}</td>
              <td>
                <a href={order.target_site} target="_blank" rel="noopener noreferrer">
                  {order.target_site}
                </a>
              </td>
              <td>{order.balance_type}</td>
              <td>{order.status}</td>
              <td>{order.detail}</td>
            </tr>
          </tbody>
        </Table>
      ) : (
        <Alert variant="info">No order details found.</Alert>
      )}
    </div>
  );
};

export default ProjectOrderDetails;
