import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import NavCom from "../components/home/Nav";
import { Helmet } from "react-helmet";

const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>About LinkScope</title>
        <meta
          name="description"
          content="LinkScope is an advanced backlink marketplace for both link sellers and webmasters. We offer tools to manage and track your link profile."
        />
        <meta name="keywords" content="Link building, Backlinks, SEO, Marketplace" />
        <link rel="icon" href="/path-to-favicon.ico" />
      </Helmet>

      <NavCom />

      <div className="container-fluid px-0">
        {/* Hero Section with gradient background */}
        <div className="bg-primary contactbg text-white py-5">
          <div className="container text-center">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <h1 className="display-3 fw-bold mb-4">About Us</h1>
                <p className="lead mb-0 fw-light fs-4">Transforming the Future of Link Building</p>
              </div>
            </div>
          </div>
        </div>

        <div className="container py-5">
          {/* Origin Story Section */}
          <section className="mb-5">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <h2 className="h2 mb-4 text-center">How It All Started</h2>
                <div className="bg-light p-4 p-lg-5 rounded-4 shadow-sm">
                  <p className="text-center mb-4 lead">Our journey began with a simple conversation and a cup of coffee...</p>
                  <div className="px-lg-4">
                    <p>On an autumn morning in my office, I was sitting on the balcony with a mug of coffee. My mind was on the verge of diaspora from the work.</p>
                    <p>By that time, I had gained 10 years of global reputation for providing white-hat backlinks and link-building services to various clients. But somewhere, I was not feeling satisfied. It was like I couldn't bring up any acceleration or unique ideas to this industry. Also, I wanted to scale up my whole process to support my dedicated clients more with these limited resources.</p>
                    <p>Then suddenly, one of my managers asked "Why not automate the whole process? Our backlink experts can efficiently use their time to figure out more link scopes!" The idea pulled my soul down that was lost up in the skies until the moment.</p>
                    <p>I turned my head to the guy, and he thought I was about to throw the mug at his face. To his surprise, I laughed and said "Why not create an automation not just for the office, but for everyone looking for link building?"</p>
                    <p className="mb-0 fw-bold">That's how LinkScope started its journey.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Company Info Section */}
          <section className="mb-5">
            <div className="row justify-content-center">
              <div className="col-lg-10 text-center">
                <h2 className="h2 mb-4">Let's Tell You About Our Company</h2>
                <div className="bg-white p-4 p-lg-5 rounded-4 shadow-sm">
                  <p className="lead">Well, I've given you a glimpse of my agency. We've been working with multiple local and international brands to provide authority referrals to their online presence.</p>
                  <p className="mb-4">LinkScope is a team of 20 people consisting of professionals like Partnership managers, SEO experts, Strategists, Content Marketers, ML experts, Data analysts, and many others. Till 2024, we touched the feet of serving more than 1000 clients successfully. Most of the projects achieved an outstanding feat after our link-building campaigns.</p>
                  <button className="btn btn-primary mainbutton px-4 py-2">See Case Studies</button>
                </div>
              </div>
            </div>
          </section>

          {/* Benefits Section with cards */}
          <section className="mb-5">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <h2 className="h2 mb-4 text-center">How LinkScope Saves Your Time & Money</h2>
                <div className="row g-4">
                  <div className="col-md-6">
                    <div className="h-100 bg-light p-4 rounded-4 shadow-sm">
                      <h3 className="h5 mb-3 text-primary">Streamlined Process</h3>
                      <p>You've seen job marketplaces like Fiverr and Upwork, right? Outsourcers come to look for freelancers to do their jobs and freelancers look for the perfect job with handsome money. LinkScope is almost the same at the primary level. You can sell your link opportunities here or buy the backlinks directly from the webmaster or author.</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="h-100 bg-light p-4 rounded-4 shadow-sm">
                      <h3 className="h5 mb-3 text-primary">Quality Assurance</h3>
                      <p>Here's where we are different. In our automated backlink marketplace, every buyer or seller is verified, as well as their featured scopes. We also make sure the price always remains fair according to the market. The LinkScope team remains between buyers and sellers to ensure fair and valid deals.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Features Section with cards */}
          <section className="mb-5">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <h2 className="h2 mb-4 text-center">What Sets Us Apart</h2>
                <div className="row row-cols-1 row-cols-md-2 g-4">
                  <div className="col">
                    <div className="h-100 p-4 border rounded-4 shadow-sm bg-white">
                      <h3 className="h4 mb-4 text-primary">Core Features</h3>
                      <ul className="list-unstyled">
                        <li className="mb-3 d-flex align-items-center">
                          <span className="text-primary me-2">✓</span>
                          <span>More than 100,000 links from 60+ niches</span>
                        </li>
                        <li className="mb-3 d-flex align-items-center">
                          <span className="text-primary me-2">✓</span>
                          <span>Updated metrics (DA, Traffic, Site Age)</span>
                        </li>
                        <li className="mb-3 d-flex align-items-center">
                          <span className="text-primary me-2">✓</span>
                          <span>4-click backlink purchase process</span>
                        </li>
                        <li className="mb-3 d-flex align-items-center">
                          <span className="text-primary me-2">✓</span>
                          <span>All-in-one client dashboard</span>
                        </li>
                        <li className="mb-3 d-flex align-items-center">
                          <span className="text-primary me-2">✓</span>
                          <span>Data-driven suggestions</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col">
                    <div className="h-100 p-4 border rounded-4 shadow-sm bg-white">
                      <h3 className="h4 mb-4 text-primary">Advanced Tools</h3>
                      <ul className="list-unstyled">
                        <li className="mb-3 d-flex align-items-center">
                          <span className="text-primary me-2">✓</span>
                          <strong>Link Monitor</strong> - Comprehensive tracking
                        </li>
                        <li className="mb-3 d-flex align-items-center">
                          <span className="text-primary me-2">✓</span>
                          <strong>AI-Powered Content</strong> - SEO-optimized writing
                        </li>
                        <li className="mb-3 d-flex align-items-center">
                          <span className="text-primary me-2">✓</span>
                          <strong>Smart Suggestions</strong> - Data-driven insights
                        </li>
                        <li className="mb-3 d-flex align-items-center">
                          <span className="text-primary me-2">✓</span>
                          <strong>Complete CRM</strong> - End-to-end management
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* CTA Section */}
          <section className="text-center">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="bg-primary bg-gradient text-white p-5 rounded-4 shadow">
                  <h2 className="h2 mb-4">Get Started with LinkScope Today</h2>
                  <p className="lead mb-4">Why waste your energy when you have a flawless AI assistant to handle your website's link profile? Leave all your worries to us.</p>
                  <p className="mb-4">Start your journey towards programmatic SEO right away.</p>
                  <button className="btn btn-light mainbutton px-5 py-3 fw-bold">Sign Up Now</button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default AboutPage;