import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import { Star } from 'lucide-react'; // For the star icon (install lucide-react if needed)
import './Hero.css';

const testimonials = [
  {
    quote: "LinkScope made finding quality backlinks super easy. The platform is simple, and I got my links placed faster than I expected!",
    name: "James P,",
    title: "Blogger, London",
    rating: 5.0,
  },
  {
    quote: "As a blogger, selling links has never been this straightforward. I listed my site, got offers quickly, and payments were smooth",
    name: "Martin Weber",
    title: "Commercial Building Owner",
    rating: 5.0,
  },
  {
    quote: "Great experience! I found niche-specific sites with good traffic, and the whole process was hassle-free. Will use it again.",
    name: "Sofia M",
    title: "SEO Specialist, New York",
    rating: 5.0,
  },
  {
    quote: "I needed backlinks for a new project, and LinkScope helped me find trustworthy sites within minutes. Super convenient!",
    name: "Carlos D",
    title: "Content Manager, Madrid",
    rating: 5.0,
  },
  {
    quote: "Affordable pricing, quick process, and quality links. What more could you ask for? Highly recommend for anyone needing backlinks.",
    name: "Lina S",
    title: "E-commerce Owner, Berlin",
    rating: 5.0,
  },

];

const TestimonialCarousel = () => {
  return (
    <div className="container my-5">
      {/* Header */}
      <div className="row mb-4">
     
      </div>

      {/* Swiper Carousel */}
      <Swiper
        modules={[Pagination, Navigation]}
        spaceBetween={20}
        navigation
        pagination={{ clickable: true }}
        slidesPerView={1} // Default for fallback
        breakpoints={{
          640: { slidesPerView: 1 }, // Mobile
          768: { slidesPerView: 2 }, // Tablets
          1024: { slidesPerView: 3 }, // Desktops
        }}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <div className="card  testimonial-card shadow-sm">
              <div className="card-body">
                <p className="testimonial-quote mb-4">"{testimonial.quote}"</p>
                <div className="d-flex flex-column align-items-start">
                  <h5 className="testimonial-name">{testimonial.name}</h5>
                  <p className="testimonial-title text-muted">{testimonial.title}</p>
                  <div className="d-flex align-items-center">
                    <Star size={16} color="#F59E0B" /> {/* Star Icon */}
                    <span className="ms-2">{testimonial.rating}</span>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TestimonialCarousel;
