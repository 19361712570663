import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

const UserDetails = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    country: "",
    state: "",
    city: "",
    address: "",
    postal_code: "",
    phone: "",
    company: ""
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoading(true);
      setError("");

      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Access token is missing!");
        return;
      }

      const userid = localStorage.getItem("user");
      if (!userid) {
        console.error("User is missing!");
        return;
      }

      const url = `https://lionfish-app-nh929.ondigitalocean.app/users/${userid}/`;

      const myHeaders = new Headers({
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      });

      try {
        const response = await fetch(url, { method: "GET", headers: myHeaders });
        if (response.ok) {
          const result = await response.json();
          setUser(result);
          setFormData({
            first_name: result.first_name || "",
            last_name: result.last_name || "",
            country: result.country || "",
            state: result.state || "",
            city: result.city || "",
            address: result.address || "",
            postal_code: result.postal_code || "",
            phone: result.phone || "",
            company: result.company || ""
          });
        } else {
          setError("Failed to fetch user details.");
        }
      } catch (error) {
        console.error(error);
        setError("An error occurred while fetching user details.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [userId]);

  const handleEditClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = localStorage.getItem("token");
    if (!token) {
      setError("Access token is missing!");
      setLoading(false);
      return;
    }

    const userid = localStorage.getItem("user");
    if (!userid) {
      setError("User is missing!");
      setLoading(false);
      return;
    }

    const url = `https://lionfish-app-nh929.ondigitalocean.app/users/${userid}/`;
    const myHeaders = new Headers({
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json"
    });

    const requestBody = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      country: formData.country,
      state: formData.state,
      city: formData.city,
      address: formData.address,
      postal_code: formData.postal_code,
      phone: formData.phone,
      company: formData.company
    };

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(requestBody)
      });

      if (response.ok) {
        const result = await response.json();
        setUser(result);
        setError(""); // Clear any previous errors
        setShowModal(false); // Close modal
        setLoading(false); // Hide loading spinner
      } else {
        setError("Failed to update user details.");
        setLoading(false);
      }
    } catch (error) {
      setError("An error occurred while updating user details.");
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: "1rem", maxWidth: "100%", overflowX: "auto" }}>
      <h2>User Details</h2>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : user ? (
        <>
        <div className="col-md-6">
          <a href="/dashboard"><button className="btn btn-primary">Switch Account</button></a>
        </div>
          <Table hover responsive className="mt-4">
            <tbody>
              <tr><th>User ID</th><td>{user.user_id}</td></tr>
              <tr><th>Email</th><td>{user.email}</td></tr>
              <tr><th>First Name</th><td>{user.first_name || "N/A"}</td></tr>
              <tr><th>Last Name</th><td>{user.last_name || "N/A"}</td></tr>
              <tr><th>Country</th><td>{user.country || "N/A"}</td></tr>
              <tr><th>State</th><td>{user.state || "N/A"}</td></tr>
              <tr><th>City</th><td>{user.city || "N/A"}</td></tr>
              <tr><th>Address</th><td>{user.address || "N/A"}</td></tr>
              <tr><th>Postal Code</th><td>{user.postal_code || "N/A"}</td></tr>
              <tr><th>Phone</th><td>{user.phone || "N/A"}</td></tr>
              <tr><th>Company</th><td>{user.company || "N/A"}</td></tr>
            </tbody>
          </Table>
          <Button variant="primary" className="btn mainbutton btn-primary px-5" onClick={handleEditClick}>Edit</Button>
        </>
      ) : (
        <Alert variant="info">No user details found.</Alert>
      )}

      {/* Modal for Editing */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEditSubmit}>
            <Form.Group controlId="first_name">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="last_name">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="country">
              <Form.Label>Country</Form.Label>
              <Form.Control
                type="text"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="state">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="postal_code">
              <Form.Label>Postal Code</Form.Label>
              <Form.Control
                type="text"
                name="postal_code"
                value={formData.postal_code}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="phone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="company">
              <Form.Label>Company</Form.Label>
              <Form.Control
                type="text"
                name="company"
                value={formData.company}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Button  type="submit" className="btn mainbutton btn-primary mt-3">
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserDetails;
