import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import BuyNowButton from "./BuyNowButton";
import InputGroup from "react-bootstrap/InputGroup";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle, FaTimesCircle, FaEdit, FaTrashAlt } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'; // Import CSS for versions before v5.13.0
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Pagination from "react-bootstrap/Pagination";
import NicheFilter from "./NicheFilter";
const FavoriteListings = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [favoriteListings, setFavoriteListings] = useState([]);
  const accesskey = localStorage.getItem("token");

  const [orderData, setOrderData] = useState([]);

  const [filteredData, setFilteredData] = useState([]);
  const [message, setMessage] = useState(""); // initialize message state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedListing, setSelectedListing] = useState(null);
  const [projects, setProjects] = useState([]); // Stores user projects
  const [searchTerm, setSearchTerm] = useState("");  // Search term state
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleCloseFailedModal = () => setShowFailedModal(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailedModal, setShowFailedModal] = useState(false);
  const [sortBy, setSortBy] = useState(""); // Sort by field (e.g., 'site_url', 'amount')
  const [sortOrder, setSortOrder] = useState("asc"); // Sort direction ('asc' or 'desc')

  // Handle sorting
  const handleSort = (sortBy) => {
    // Toggle the sort order between "asc" and "desc"
    const newSortOrder = sortBy === sortBy && sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder); // Update the sort order
    setSortBy(sortBy); // Update the sort field

    const sortedListings = [...filteredData].sort((a, b) => {
      let valueA = a[sortBy];
      let valueB = b[sortBy];

      // If sorting by number (e.g., price, traffic)
      if (typeof valueA === "number" && typeof valueB === "number") {
        return newSortOrder === "asc" ? valueA - valueB : valueB - valueA;
      }

      // If sorting by text (e.g., site_url)
      if (typeof valueA === "string" && typeof valueB === "string") {
        return newSortOrder === "asc" ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      }

      return 0; // No sorting applied if field is undefined
    });

    // Update filteredData with sorted result
    setFilteredData(sortedListings);
  };


  // Sort listings by the selected field and order

  const handleDelete = async (listingId) => {
    try {
      const response = await fetch(`https://lionfish-app-nh929.ondigitalocean.app/favourites/remove/${listingId}/`, {
        method: "DELETE",
        headers: {
          "Authorization": `Bearer ${accesskey}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Error deleting listing: ${response.status} - ${response.statusText}`);
      }
      window.location.reload();

      // Update the UI to remove the deleted listing
    } catch (error) {
      console.error(error);
      alert("Failed to delete the listing. Please try again.");
    }
  };
  
  const [formData, setFormData] = useState({
    listing_id: null,
    order_type: "Standard",
    target_site: "",
    detail: "",
    balance_type: "main_balance",
    quantity: 1,
    coupon_code: '',
    coupon_details: null,
    service_type: "",
    post_link: "",
    anchor_text: "",
    destination_link: "",
    article_option: "",
    keyword: "",
    article_link: ""
  });
  const [filters, setFilters] = useState({
    type: "",
    niche: "",
    backlink_type: "",
    language: "",
    trafficMin: "",
    trafficMax: "",
    daMin: "",
    daMax: "",
    drMin: "",
    drMax: "",
    tatMin: 0,
    tatMax: 100,
    priceMin: "",
    priceMax: "",
  });

  const accessKey = localStorage.getItem("token");
  const [currentPage, setCurrentPage] = useState(1);
  const listingsPerPage = 20;

  const handleBuyNowClick = (listing) => {
    setSelectedListing(listing);
    setFormData((prevData) => ({
      ...prevData,
      listing_id: listing.listing_id,
      order_type: "Standard",
    }));
    setShowModal(true);
    fetchProjects(); // Fetch user projects when modal opens

  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormData({
      listing_id: null,
      order_type: "Standard",
      target_site: "",
      detail: "",
      balance_type: "main_balance",
      quantity: 1,
    });
    setSelectedListing(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  const fetchProjects = async () => {
    setError("");

    const token = localStorage.getItem("token");
    if (!token) {
      setError("Access token is missing!");
      return;
    }

    const url = `https://lionfish-app-nh929.ondigitalocean.app/projects/all/`;
    const myHeaders = new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    });

    try {
      const response = await fetch(url, { method: "GET", headers: myHeaders });
      if (response.ok) {
        const result = await response.json();
        setProjects(result);
      } else {
        setError("Failed to fetch project details.");
      }
    } catch (error) {
      setError("An error occurred while fetching data.");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
      // Prepare the detail object based on the listing type and selections
  let detailObject = {};
  
  if (selectedListing.type === "Link Insert" || formData.service_type === "Link Insert") {
    detailObject = {
      service_type: "Link Insert",
      post_link: formData.post_link,
      anchor_text: formData.anchor_text,
      additional_info: formData.destination_link
    };
  } else if (selectedListing.type === "Guest Post" || formData.service_type === "Guest Post") {
    if (formData.article_option === "write_article") {
      detailObject = {
        service_type: "Guest Post",
        keyword: formData.keyword,
        additional_info: formData.destination_link
      };
    } else {
      detailObject = {
        service_type: "Guest Post",

        article_link: formData.article_link,
        anchor_text: formData.anchor_text,
        additional_info: formData.destination_link
      };
    }
  }
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessKey}`);
    myHeaders.append("Content-Type", "application/json");

    const url =
      formData.order_method === "project"
        ? "https://lionfish-app-nh929.ondigitalocean.app/projects/orders/"
        : "https://lionfish-app-nh929.ondigitalocean.app/orders/";
const body = {
  listing_id: formData.listing_id,
  order_type: formData.order_type,
  target_site: formData.target_site,
  detail: JSON.stringify(detailObject),
  balance_type: formData.balance_type,
  quantity: formData.quantity,
  coupon_code: formData.coupon_code || null,
  ...(formData.order_method === "project" && { project_id: formData.project_id }) // Conditionally add project_id if order_method is "project"

};

console.log("Request Body:", body); // Log the body object before sending

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: JSON.stringify(body), // Convert body to a JSON string
};
    
    try {
      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      const result = await response.json();
      console.log("Order created successfully:", result);
      setMessage("Your order created successfully. Go to the order or project page to manage it.");
      setOrderData(result);

      setShowSuccessModal(true);

      // Delay clearing the message after 3 seconds

      
    } catch (error) {
      console.error("Error creating order:", error);
      setMessage("Please make sure you or your project owner has enough balance and try again.");
      setShowFailedModal(true);

      // Delay clearing the message after 3 seconds
 
    } finally {
      setLoading(false);

      setShowModal(false);
      // Delay closing the modal for 3 seconds

    }
  };

  const handleOrderPage = () => {
    const site = `/order/${orderData.order.order_id}`; // Construct the URL
    navigate(site); // Navigate to the constructed URL
  };

  const handleFavorite = async (listingid) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzQzNTI5NTU1LCJpYXQiOjE3MzkyMDk1NTUsImp0aSI6ImNmNDE3ODVlZTdiZTQ5ZmE5MWJlMWFkNzJiZGQ2MjUyIiwidXNlcl9pZCI6InNob2hvcmFiYXJlZmluIn0.nxT1_YeaS3uLvJmA-_0uNr7mWLyqsM3AUSSz-ro7Oeg");
  
    const raw = "";
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
  
    try {
      const response = await fetch(`https://lionfish-app-nh929.ondigitalocean.app/listings/${listingid}/favourite/`, requestOptions);
      const result = await response.text();
      alert('Added as favorite');
      console.log(result);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const fetchFavoriteListings = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessKey}`);  
   
  
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
     
      redirect: "follow"
    };
  
    try {
      const response = await fetch("https://lionfish-app-nh929.ondigitalocean.app/listings/favourite/", requestOptions);
      const result = await response.json();  // It's better to parse the response as JSON if the server returns JSON data
      console.log(result);
      return result; // Optionally return the result for further use
    } catch (error) {
      console.error("Error fetching favorites:", error);
    }
  };
  
  

  useEffect(() => {
    const fetchData = async () => {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${accessKey}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      try {
        const response = await fetch(
          "https://lionfish-app-nh929.ondigitalocean.app/listings/favourite/",
          requestOptions
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
        const reversedResult  = await response.json();
        const resulte = reversedResult.reverse();
        const result = resulte.filter((item) => item.listing.status === "Active") // Ensure active status
        .map((item) => item.listing);

        setData(result);
        setFilteredData(result);
      } catch (error) {
        console.error("Error fetching listings:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchFavorites = async () => {
      const favoriteListings = await fetchFavoriteListings();  // Call the fetch function you created
      setFavoriteListings(favoriteListings);  // Store the favorite listings in state
    };


    fetchFavorites();

    fetchData();
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const applyFilters = () => {
    const {
      type,
      niche,
      backlink_type,
      language,
      trafficMin,
      trafficMax,
      daMin,
      daMax,
      drMin,
      drMax,
      tatMin,
      tatMax,
      priceMin,
      priceMax,
    } = filters;
    
    const filtered = data.filter((listing) => {
      return (
        (!type || listing.type === type) &&
        (!niche || listing.niche === niche) &&
        (!backlink_type || listing.backlink_type === backlink_type) &&
        (!language || listing.language === language) &&
        (!trafficMin || listing.traffic >= parseInt(trafficMin)) &&
        (!trafficMax || listing.traffic <= parseInt(trafficMax)) &&
        (!daMin || listing.da >= parseInt(daMin)) &&
        (!daMax || listing.da <= parseInt(daMax)) &&
        (!drMin || listing.dr >= parseInt(drMin)) &&
        (!drMax || listing.dr <= parseInt(drMax)) &&
        (!tatMin || listing.tat >= parseInt(tatMin)) &&
        (!tatMax || listing.tat <= parseInt(tatMax)) &&
        (!priceMin || listing.amount >= parseFloat(priceMin)) &&
        (!priceMax || listing.amount <= parseFloat(priceMax))
      );
    });



    setFilteredData(filtered);


    setCurrentPage(1); // Reset to the first page after filtering
  };

  const resetFilters = () => {
    setFilters({
      type: "",
      niche: "",
      backlink_type: "",
      language: "",
      trafficMin: "",
      trafficMax: "",
      daMin: "",
      daMax: "",
      drMin: "",
      drMax: "",
      tatMin: "",
      tatMax: "",
      priceMin: "",
      priceMax: "",
    });
    setFilteredData(data);

    setCurrentPage(1); // Reset to the first page
  };

  const getUniqueValues = (field) => {
    return [...new Set(data.map((item) => item[field]))];
  };



  const handleSearch = (e) => {
    e.preventDefault(); // Prevent page reload on form submit

    const filtered = data.filter((listing) => {
      return Object.values(listing).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

    setFilteredData(filtered);
    setCurrentPage(1);  // Reset to the first page after search
  };

  const indexOfLastListing = currentPage * listingsPerPage;
  const indexOfFirstListing = indexOfLastListing - listingsPerPage;
  const currentListings = filteredData.slice(indexOfFirstListing, indexOfLastListing);

  const isFavorite = (listingId) => {
    return Array.isArray(favoriteListings) && favoriteListings.includes(listingId);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="table-responsive">
      {/* Filter Form */}

      {/* Table */}
      <div className="d-flex justify-content-between ">
        {/* Search Bar */}
     
        </div>
        <Table className="table table-transparent">
      <thead>
        <tr className="headertr">
          <th>
            <Form onSubmit={handleSearch}>
              <Form.Control
                type="text"
                placeholder="Sitename..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && handleSearch(e)}
              />
            </Form>
          </th>
          <th onClick={() => handleSort("type")} style={{ cursor: "pointer" }}>
  Type
  <span style={{ fontSize: "16px", color: "#007bff", marginLeft: "8px" }}>
    ↑
  </span>
  <span style={{ fontSize: "16px", color: "#007bff", marginLeft: "8px" }}>
    ↓
  </span>
</th>
<th onClick={() => handleSort("niche")} style={{ cursor: "pointer" }}>
  Niche
  <span style={{ fontSize: "16px", color: "#007bff", marginLeft: "8px" }}>
    ↑
  </span>
  <span style={{ fontSize: "16px", color: "#007bff", marginLeft: "8px" }}>
    ↓
  </span>
</th>
<th onClick={() => handleSort("backlink_type")} style={{ cursor: "pointer" }}>
  Backlink Type
  <span style={{ fontSize: "16px", color: "#007bff", marginLeft: "8px" }}>
    ↑
  </span>
  <span style={{ fontSize: "16px", color: "#007bff", marginLeft: "8px" }}>
    ↓
  </span>
</th>
<th onClick={() => handleSort("language")} style={{ cursor: "pointer" }}>
  Language
  <span style={{ fontSize: "16px", color: "#007bff", marginLeft: "8px" }}>
    ↑
  </span>
  <span style={{ fontSize: "16px", color: "#007bff", marginLeft: "8px" }}>
    ↓
  </span>
</th>
<th onClick={() => handleSort("traffic")} style={{ cursor: "pointer" }}>
  Traffic
  <span style={{ fontSize: "16px", color: "#007bff", marginLeft: "8px" }}>
    ↑
  </span>
  <span style={{ fontSize: "16px", color: "#007bff", marginLeft: "8px" }}>
    ↓
  </span>
</th>
<th onClick={() => handleSort("da")} style={{ cursor: "pointer" }}>
  DA
  <span style={{ fontSize: "16px", color: "#007bff", marginLeft: "8px" }}>
    ↑
  </span>
  <span style={{ fontSize: "16px", color: "#007bff", marginLeft: "8px" }}>
    ↓
  </span>
</th>
<th onClick={() => handleSort("dr")} style={{ cursor: "pointer" }}>
  DR
  <span style={{ fontSize: "16px", color: "#007bff", marginLeft: "8px" }}>
    ↑
  </span>
  <span style={{ fontSize: "16px", color: "#007bff", marginLeft: "8px" }}>
    ↓
  </span>
</th>
<th onClick={() => handleSort("tat")} style={{ cursor: "pointer" }}>
  TAT 
  <span style={{ fontSize: "16px", color: "#007bff", marginLeft: "8px" }}>
    ↑
  </span>
  <span style={{ fontSize: "16px", color: "#007bff", marginLeft: "8px" }}>
    ↓
  </span>
</th>
<th > Sample Link</th>
<th onClick={() => handleSort("amount")} style={{ cursor: "pointer" }}>
  Price
  <span
              style={{
                fontSize: "16px",
                color: "#007bff",
                marginLeft: "8px",
                visibility: sortOrder === "asc" ? "visible" : "hidden",
              }}
            >
              ↑
            </span>
            <span
              style={{
                fontSize: "16px",
                color: "#007bff",
                marginLeft: "8px",
                visibility: sortOrder === "desc" ? "visible" : "hidden",
              }}
            >
              ↓
            </span>
</th>
<th >Action</th>

        </tr>
      </thead>
      <tbody>
        {currentListings.map((listing) => (
          <tr key={listing.listing_id} className="bodytr">
            <td className="text-start linktd">
              <a
                href={`https://${listing.site_url}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {listing.site_url}
              </a>
            </td>
            <td>{listing.type}</td>
            <td>{listing.niche}</td>
            <td>{listing.backlink_type}</td>
            <td>{listing.language}</td>
            <td>{listing.traffic}</td>
            <td>{listing.da}</td>
            <td>{listing.dr}</td>
            <td>{listing.tat} Days</td>
            <td>
            {listing.sample ? (
    <a href={listing.sample} target="_blank" rel="noopener noreferrer">
      See sample
    </a>
  ) : (
    <span>See sample</span>
  )}
            </td>
            <td>      
              <BuyNowButton
                price={listing.amount.toFixed(2)}
                onClick={() => handleBuyNowClick(listing)}
              />
       
            </td>
            <td>
                          <span
                            className="action-icon ms-3"
                            data-tooltip-id="delete-tooltip"
                            data-tooltip-content="Delete Listing"
                            onClick={() => handleDelete(listing.listing_id)}
                          >
                            <FaTrashAlt size={20} color="gray" style={{ cursor: "pointer" }} />
                          </span>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>

      {/* Pagination */}
      <Pagination className="justify-content-center">
        {Array.from(
          { length: Math.ceil(filteredData.length / listingsPerPage) },
          (_, i) => (
            <Pagination.Item
              key={i + 1}
              active={i + 1 === currentPage}
              onClick={() => handlePageChange(i + 1)}
            >
              {i + 1}
            </Pagination.Item>
          )
        )}
      </Pagination>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Buy Your Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {message && <div className="message">{message}</div>}

  {selectedListing && (
    <Form onSubmit={handleSubmit}>
      {/* Order Method Selection */}
      <Form.Group className="mb-3">
        <Form.Label>Order Method</Form.Label>
        <Form.Control
          as="select"
          name="order_method"
          value={formData.order_method}
          onChange={handleChange}
        >
          <option value="direct">Direct Order</option>
          <option value="project">Order Under a Project</option>
        </Form.Control>
      </Form.Group>

      {/* Project Selection (if order_method is project) */}
      {formData.order_method === "project" && (
        <Form.Group className="mb-3">
          <Form.Label>Select Project</Form.Label>
          <Form.Control
            as="select"
            name="project_id"
            value={formData.project_id}
            onChange={handleChange}
            required
          >
            <option value="">-- Select a Project --</option>
            {projects.map((project) => (
              <option key={project.id} value={project.id}>
                {project.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      )}

      {/* Service Type Selection for "Both" type listings */}
      {selectedListing.type === "Both" && (
        <Form.Group className="mb-3">
          <Form.Label>Service Type</Form.Label>
          <Form.Control
            as="select"
            name="service_type"
            value={formData.service_type}
            onChange={handleChange}
            required
          >
            <option value="">Select Service Type</option>
            <option value="Link Insert">Link Insert</option>
            <option value="Guest Post">Guest Post</option>
          </Form.Control>
        </Form.Group>
      )}

      {/* Fields for Link Insert */}
      {(selectedListing.type === "Link Insert" || formData.service_type === "Link Insert") && (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Targeted Site Post URL</Form.Label>
            <InputGroup>
    {/* Fixed site URL on the left */}
    <InputGroup.Text>{`${selectedListing.site_url}`}</InputGroup.Text>
    
    {/* Editable input field for the extension */}
    <Form.Control
      type="text"
      name="post_link"
      value={formData.post_link}
      onChange={(e) => {
        const newExtension = e.target.value.startsWith("/") 
          ? e.target.value 
          : `/${e.target.value}`; // Ensure it always starts with '/'
        handleChange({ target: { name: "post_link", value: newExtension } });
      }}
      placeholder="e.g. rest-of-the-link"
      required
    />
  </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Your Landing Page</Form.Label>
            <Form.Control
              type="text"
              name="target_site"
              value={formData.target_site}
              onChange={handleChange}
              placeholder="Enter your landing page link"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Anchor Text</Form.Label>
            <Form.Control
              type="text"
              name="anchor_text"
              value={formData.anchor_text}
              onChange={handleChange}
              placeholder="Enter anchor text"
              required
            />
          </Form.Group>
  
        </>
      )}

      {/* Fields for Guest Post */}
      {(selectedListing.type === "Guest Post" || formData.service_type === "Guest Post") && (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Article Option</Form.Label>
            <Form.Control
              as="select"
              name="article_option"
              value={formData.article_option}
              onChange={(e) => {
                handleChange(e);
                setFormData(prev => ({
                  ...prev,
                  order_type: e.target.value === "write_article" ? "Premium" : "Standard"
                }));
              }}
              required
            >
              <option value="">Select Option</option>
              <option value="write_article">I want you to write Article</option>
              <option value="provide_article">I will Provide Article</option>
            </Form.Control>
          </Form.Group>

          {formData.article_option === "write_article" && (
            <Form.Group className="mb-3">
              <Form.Label>Keyword</Form.Label>
              <Form.Control
                type="text"
                name="keyword"
                value={formData.keyword}
                onChange={handleChange}
                placeholder="Enter keyword"
                required
              />
            </Form.Group>
          )}

<Form.Group className="mb-3">
            <Form.Label>Your Landing Page</Form.Label>
            <Form.Control
              type="text"
              name="target_site"
              value={formData.target_site}
              onChange={handleChange}
              placeholder="Enter your landing page link"
              required
            />
          </Form.Group>

          {formData.article_option === "provide_article" && (
            <>


              <Form.Group className="mb-3">
                <Form.Label>Article Google Doc Link</Form.Label>
                <Form.Control
                  type="text"
                  name="article_link"
                  value={formData.article_link}
                  onChange={handleChange}
                  placeholder="Enter Google Doc link"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Anchor Text</Form.Label>
                <Form.Control
                  type="text"
                  name="anchor_text"
                  value={formData.anchor_text}
                  onChange={handleChange}
                  placeholder="Enter anchor text"
                  required
                />
              </Form.Group>
   
            </>
          )}
        </>
      )}

      {/* Target Site Field */}
      <Form.Group className="mb-3">
        <Form.Label>Additional Notes</Form.Label>
        <Form.Control
          type="text"
          name="destination_link"
          value={formData.destination_link}
          onChange={handleChange}
          placeholder="Write any custom instructions"
          required
        />
      </Form.Group>

      {/* Balance Type Selection */}
      <Form.Group className="mb-3">
        <Form.Label>Balance Type</Form.Label>
        <Form.Control
          as="select"
          name="balance_type"
          value={formData.balance_type}
          onChange={handleChange}
        >
          <option value="main_balance">Main Balance</option>
          <option value="referral_balance">Referral Balance</option>
        </Form.Control>
      </Form.Group>

      {/* Coupon Code Section */}
<Form.Group className="mb-3">
  <Form.Label>Coupon Code</Form.Label>
  <div className="d-flex gap-2">
    <Form.Control
      type="text"
      name="coupon_code"
      value={formData.coupon_code || ''}
      onChange={handleChange}
      placeholder="Enter coupon code"
    />
    <Button 
      variant="outline-secondary" 
      onClick={async () => {
        if (!formData.coupon_code) return;
        try {
          const response = await fetch(
            `https://lionfish-app-nh929.ondigitalocean.app/coupons/${formData.coupon_code}/`,
            {
              headers: {
                Authorization: `Bearer ${accessKey}`,
              },
            }
          );
          if (response.ok) {
            const couponData = await response.json();
            setFormData(prev => ({
              ...prev,
              coupon_details: couponData
            }));
          } else {
            setFormData(prev => ({...prev, coupon_details: null}));
            alert('Invalid coupon code');
          }
        } catch (error) {
          console.error('Coupon validation failed:', error);
        }
      }}
    >
      Apply
    </Button>
  </div>
  {formData.coupon_details && (
    <div className="mt-2 text-success">
      Applied: {formData.coupon_details.name} ({formData.coupon_details.amount}% off)
    </div>
  )}
</Form.Group>

      {/* Price Display */}
      <p>
        Price:{" "}
        {formData.coupon_details ? (
          <>
            <span style={{ textDecoration: "line-through" }}>
              ${(formData.order_type === "Premium" ? selectedListing.premium_price : selectedListing.amount).toFixed(2)}
            </span>{" "}
            <span className="text-success">
              ${((formData.order_type === "Premium" ? selectedListing.premium_price : selectedListing.amount) * (1 - formData.coupon_details.amount / 100)).toFixed(2)}
            </span>
          </>
        ) : (
          `$${formData.order_type === "Premium" ? selectedListing.premium_price.toFixed(2) : selectedListing.amount.toFixed(2)}`
        )}
      </p>

      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  )}
</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>













      {/* Order Success Modal */}
      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Order Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          🎉 Your order has been placed successfully!
        </Modal.Body>
        <Modal.Footer>
        <Button variant="success" onClick={handleOrderPage}>See Order Details</Button>
        <Button variant="success" onClick={handleCloseSuccessModal}>Buy Another</Button>

        </Modal.Footer>
      </Modal>

      {/* Order Failed Modal */}
      <Modal show={showFailedModal} onHide={handleCloseFailedModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Order Failed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ❌ Something went wrong. Please try again.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseFailedModal}>Retry</Button>
        </Modal.Footer>
      </Modal>




    </div>
  );
};

export default FavoriteListings;