// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../static/images/halftone.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-hero-section {
    background: linear-gradient(90deg, rgba(106, 13, 173, 0.99), rgba(74, 10, 140, 0.99)),    url(${___CSS_LOADER_URL_REPLACEMENT_0___} );
    
  background-size: cover; /* Ensure the background fills the section */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; 
    color: white; /* Text color */
    padding: 60px 0;
  }
  
  .custom-hero-title {
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  .custom-hero-subtitle {
    font-size: 1.2rem;
    font-weight: 300;
  }
  
`, "",{"version":3,"sources":["webpack://./src/components/home/HeroSection.css"],"names":[],"mappings":"AAAA;IACI,kIAAkI;;EAEpI,sBAAsB,EAAE,4CAA4C;EACpE,2BAA2B,EAAE,gCAAgC;EAC7D,4BAA4B;IAC1B,YAAY,EAAE,eAAe;IAC7B,eAAe;EACjB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;EAClB","sourcesContent":[".custom-hero-section {\n    background: linear-gradient(90deg, rgba(106, 13, 173, 0.99), rgba(74, 10, 140, 0.99)),    url('../../static/images/halftone.png' );\n    \n  background-size: cover; /* Ensure the background fills the section */\n  background-position: center; /* Center the background image */\n  background-repeat: no-repeat; \n    color: white; /* Text color */\n    padding: 60px 0;\n  }\n  \n  .custom-hero-title {\n    font-size: 2.5rem;\n    font-weight: bold;\n  }\n  \n  .custom-hero-subtitle {\n    font-size: 1.2rem;\n    font-weight: 300;\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
