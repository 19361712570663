import React, { useState, useEffect } from "react";
import { Tooltip, Modal, Button, Form, Alert } from "react-bootstrap";

const BulkListing = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const accesskey = localStorage.getItem('token');

  const handleFileChange = (event) => {
    setFile(event.target.files[0]); // Set the selected file
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      alert("Please select a CSV file.");
      return;
    }

    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    const formData = new FormData();
    formData.append("file", file); // Append the CSV file

    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${accesskey}`
    );

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
    };

    try {
      const response = await fetch("https://lionfish-app-nh929.ondigitalocean.app/bulk-create-listings/", requestOptions);
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      const result = await response.json();

      // Check for a success response
      if (result) {
        setSuccessMessage("Listings successfully uploaded!");
      } else {
        setError("There was an error in your CSV.");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="row">
        <div>
          <h3>Create Bulk Listing</h3>
        </div>
      </div>

      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="csvFile">
          <Form.Label>Attach CSV File</Form.Label>
          <Form.Control
            type="file"
            accept=".csv"
            onChange={handleFileChange}
          />
        </Form.Group>

        <Button variant="primary" type="submit" disabled={loading}>
          {loading ? "Uploading..." : "Upload Listings"}
        </Button>
      </Form>

      {/* Success or Error Messages */}
      {successMessage && <Alert variant="success" className="mt-3">{successMessage}</Alert>}
      {error && <Alert variant="danger" className="mt-3">{error}</Alert>}

      {/* Add Tooltip Components */}

    </div>
  );
};

export default BulkListing;
