import React, { useState, useEffect, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';

const NicheSearchDropdown = ({ data, filteredData, onSelect, value }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  // Update search term when value changes
  useEffect(() => {
    if (value) {
      setSearchTerm(value.type === 'niche' ? value.value : `${value.parent} > ${value.value}`);
    } else {
      setSearchTerm('');
    }
  }, [value]);

  // Memoize getNicheMap function to improve performance
  const getNicheMap = useCallback(() => {
    const nicheMap = new Map();
    
    data.forEach(item => {
      if (!nicheMap.has(item.niche)) {
        nicheMap.set(item.niche, new Set());
      }
      
      if (item.subniche) {
        const subniches = item.subniche
          .split(',')
          .map(s => s.trim())
          .filter(Boolean); // Remove empty strings
        
        subniches.forEach(sub => {
          nicheMap.get(item.niche).add(sub);
        });
      }
    });
    
    return nicheMap;
  }, [data]);

  // Update suggestions based on search term with debouncing
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (searchTerm.trim() === '') {
        setSuggestions([]);
        return;
      }
  
      const nicheMap = getNicheMap();
      const filteredSuggestions = [];
      const searchTermLower = searchTerm.toLowerCase();
      
      nicheMap.forEach((subniches, niche) => {
        // Check if niche matches
        if (niche.toLowerCase().includes(searchTermLower)) {
          const count = filteredData.filter(item => 
            item.niche.toLowerCase() === niche.toLowerCase()
          ).length;
          
          if (count > 0) {
            filteredSuggestions.push({ 
              type: 'niche', 
              value: niche, 
              count 
            });
          }
        }
  
        // Check subniches with exact parent niche
        subniches.forEach(subniche => {
          if (subniche.toLowerCase().includes(searchTermLower)) {
            // Update count calculation to match the exact filtering logic
            const count = filteredData.filter(item => 
              item.niche.toLowerCase() === niche.toLowerCase() && // Exact match for parent niche
              item.subniche && 
              item.subniche
                .toLowerCase()
                .split(',')
                .map(s => s.trim())
                .includes(subniche.toLowerCase()) // Exact match for subniche
            ).length;
            
            if (count > 0) {
              filteredSuggestions.push({ 
                type: 'subniche', 
                value: subniche, 
                parent: niche,
                count
              });
            }
          }
        });
      });  

      // Sort suggestions: niches first, then subniches, both alphabetically
      filteredSuggestions.sort((a, b) => {
        if (a.type !== b.type) {
          return a.type === 'niche' ? -1 : 1;
        }
        if (a.count !== b.count) {
          return b.count - a.count;
        }
        return a.value.localeCompare(b.value);
      });
  
      console.log('Generated suggestions:', filteredSuggestions); // Debug log
      setSuggestions(filteredSuggestions);
    }, 300);
  
    return () => clearTimeout(debounceTimer);
  }, [searchTerm, data, filteredData, getNicheMap]);

  // Handle selection from dropdown
  const handleSelect = (suggestion) => {
    const displayText = suggestion.type === 'niche' 
      ? suggestion.value 
      : `${suggestion.parent} > ${suggestion.value}`;
    
    setSearchTerm(displayText);
    
    // Create the filter object with the correct structure
    const filterObject = suggestion.type === 'niche' 
      ? {
          type: 'niche',
          value: suggestion.value,
        }
      : {
          type: 'subniche',
          value: suggestion.value,
          parent: suggestion.parent, // This is important for filtering
        };
    
    console.log('Selected filter object:', filterObject); // Debug log
    onSelect(filterObject);
    setShowDropdown(false);
  };

  // Handle clear selection
  const handleClear = () => {
    setSearchTerm('');
    onSelect(null);
    setSuggestions([]);
  };

  return (
    <div className="position-relative">
      <div className="d-flex">
        <Form.Control
          type="text"
          placeholder="Search niche or subniche..."
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setShowDropdown(true);
          }}
          onFocus={() => setShowDropdown(true)}
          onBlur={(e) => {
            // Only hide dropdown if click is outside suggestion list
            if (!e.relatedTarget?.closest('.suggestion-list')) {
              setTimeout(() => setShowDropdown(false), 200);
            }
          }}
        />
        {searchTerm && (
          <button
            className="btn btn-link position-absolute"
            style={{ right: '10px', top: '50%', transform: 'translateY(-50%)' }}
            onClick={handleClear}
          >
            ×
          </button>
        )}
      </div>
      
      {showDropdown && suggestions.length > 0 && (
        <ListGroup 
          className="position-absolute w-100 shadow-sm suggestion-list" 
          style={{ 
            zIndex: 1000, 
            maxHeight: '300px', 
            overflowY: 'auto',
            backgroundColor: 'white' 
          }}
        >
          {suggestions.map((suggestion, index) => (
            <ListGroup.Item 
              key={`${suggestion.type}-${suggestion.value}-${index}`}
              action
              onClick={() => handleSelect(suggestion)}
              className="d-flex align-items-center justify-content-between"
              style={{
                cursor: 'pointer',
                padding: '8px 12px'
              }}
            >
              <div>
                {suggestion.type === 'niche' ? (
                  <strong>{suggestion.value}</strong>
                ) : (
                  <span>
                    <small className="text-muted">{suggestion.parent} › </small>
                    {suggestion.value}
                  </span>
                )}
              </div>
              <div 
                className="badge bg-light text-dark rounded-pill"
                style={{
                  minWidth: '24px',
                  height: '24px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {suggestion.count}
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

export default NicheSearchDropdown;