import React from "react";

const BuyNowButton = ({ price, onClick }) => {
  return (
    <div
      style={{
        paddingRight: 12,
        background: "#251D61",
        borderRadius: 100,
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 12,
        display: "inline-flex",
      }}
    >
      <div
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 12,
          paddingBottom: 12,
          background: "linear-gradient(270deg, #7A39ED 21%, #5244E4 100%)",
          borderRadius: 40,
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 8,
          display: "flex",
          cursor: "pointer", // Makes it clickable
        }}
        onClick={onClick} // Call the onClick handler when clicked
      >
        <div
          style={{
            color: "#FDFEFF",
            fontSize: 16,
            fontFamily: "Inter",
            fontWeight: "500",
            lineHeight: "20px",
            wordWrap: "break-word",
          }}
        >
          Buy
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          color: "#FDFEFF",
          fontSize: 16,
          fontFamily: "Inter",
          fontWeight: "500",
          lineHeight: "26px",
          wordWrap: "break-word",
        }}
      >
        ${price}
      </div>
    </div>
  );
};

export default BuyNowButton;
