import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import BuyNowButton from "../BuyNowButton";

const MyListings = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const accesskey = localStorage.getItem('token')
  const handleBuyNowClick = () => {
    alert("Buy Now clicked!");
  };

  useEffect(() => {
    const fetchData = async () => {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${accesskey}` // Use backticks for string interpolation
    );

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch("https://lionfish-app-nh929.ondigitalocean.app/listings", requestOptions);
        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
        const user = localStorage.getItem('user')
        const result = await response.json();
        const ownListings = result.filter(
            (request) => request.user === user
          );
        setData(ownListings);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="table-responsive">
      <Table className="table table-transparent ">
        <thead>
          <tr className="headertr">
            <th>Site</th>
            <th>Type</th>
            <th>Niche</th>
            <th>Language</th>
            <th>Traffic</th>
            <th>DA</th>
            <th>DR</th>
            <th>TAT</th>

            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data.map((listing, index) => (
            <tr key={listing.listing_id} className="bodytr">
              <td>
                <a href={listing.site_url} target="_blank" rel="noopener noreferrer">
                  {listing.site_url}
                </a>
              </td>
              <td>{listing.type}</td>
              <td>{listing.niche}</td>
              <td>{listing.language}</td>
              <td>{listing.traffic.toLocaleString()}</td>
              <td>{listing.da}</td>
              <td>{listing.dr}</td>
              <td>{listing.tat} Hours</td>

              <td>
                {listing.status}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default MyListings;
