import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

const MyOrderDetails = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchOrderDetails = async () => {
      setLoading(true);
      setError("");

      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Access token is missing!");
        return;
      }

      const url = `https://lionfish-app-nh929.ondigitalocean.app/orders/id/${orderId}/`;
      const myHeaders = new Headers({
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      });

      try {
        const response = await fetch(url, { method: "GET", headers: myHeaders });
        if (response.ok) {
          const result = await response.json();
          setOrder(result);
        } else {
          setError("Failed to fetch order details.");
        }
      } catch (error) {
        console.error(error);
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  const handleMarkComplete = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Access token is missing!");
      return;
    }

    const url = `https://lionfish-app-nh929.ondigitalocean.app/orders/${orderId}/confirm/`;
    const myHeaders = new Headers({
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json"
    });

    try {
      const response = await fetch(url, { method: "POST", headers: myHeaders });
      if (response.ok) {
        setMessage(`Order ${orderId} marked as complete successfully.`);
        setTimeout(() => setMessage(""), 2000);
      } else {
        setError("Failed to mark order as complete.");
      }
    } catch (error) {
      console.error(error);
      setError("An error occurred while updating the order.");
    }
  };

  return (
    <div style={{ padding: "1rem", maxWidth: "100%", overflowX: "auto" }}>
      <h2>Order Details</h2>
      {message && <Alert variant="success">{message}</Alert>}
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : order ? (
        <>
          <Table bordered hover responsive className="mt-4">
            <thead>
              <tr>
                <th>Amount</th>
                <th>Listing</th>
                <th>Order Type</th>
                <th>Target Site</th>
                <th>Balance Type</th>
                <th>Status</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>${order.amount.toFixed(2)}</td>
                <td>{order.listing}</td>
                <td>{order.order_type}</td>
                <td>
                  <a href={order.target_site} target="_blank" rel="noopener noreferrer">
                    {order.target_site}
                  </a>
                </td>
                <td>{order.balance_type}</td>
                <td>{order.status}</td>
                <td>{order.detail}</td>
              </tr>
            </tbody>
          </Table>
          <Button variant="success" onClick={handleMarkComplete} className="mt-3">
            Mark Order as Complete
          </Button>
        </>
      ) : (
        <Alert variant="info">No order details found.</Alert>
      )}
    </div>
  );
};

export default MyOrderDetails;
