import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const OrderDetails = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [confirmationError, setConfirmationError] = useState("");
  const [confirmationSuccess, setConfirmationSuccess] = useState("");
  const [showModal, setShowModal] = useState(false); // Modal state
  const [revisionRequired, setRevisionRequired] = useState(false); // Track if revision is required
  const [listingDetails, setListingDetails] = useState(null);


  useEffect(() => {
    const fetchOrderDetails = async () => {
      setLoading(true);
      setError("");
      setConfirmationError("");
      setConfirmationSuccess("");

      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Access token is missing!");
        return;
      }

      const url = `https://lionfish-app-nh929.ondigitalocean.app/orders/id/${orderId}/`;
      const myHeaders = new Headers({
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      });

      try {
        const response = await fetch(url, { method: "GET", headers: myHeaders });
        if (response.ok) {
          const result = await response.json();
          setOrder(result);
          console.log(result);

          // If listing ID exists, fetch the listing details
          if (result.listing) {
            const listingUrl = `https://lionfish-app-nh929.ondigitalocean.app/listings/${result.listing}`;
            const listingResponse = await fetch(listingUrl, { method: "GET", headers: myHeaders });
            if (listingResponse.ok) {
              const listingResult = await listingResponse.json();
              setListingDetails(listingResult); // Set the listing details
              console.log(listingResult);
            } else {
              setError("Failed to fetch listing details.");
            }
          }
        } else {
          setError("Failed to fetch order details.");
        }
      } catch (error) {
        console.error(error);
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  // Handle order confirmation (mark order as complete)
  const handleConfirmOrder = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Access token is missing!");
      return;
    }

    const url = `https://lionfish-app-nh929.ondigitalocean.app/orders/${orderId}/confirm/`;
    const myHeaders = new Headers({
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json"
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: myHeaders
      });

      if (response.ok) {
        setConfirmationSuccess("Order confirmed as complete!");
        setConfirmationError("");
        setOrder(prevOrder => ({
          ...prevOrder,
          status: "Completed" // Update order status locally
        }));
      } else {
        setConfirmationError("Failed to confirm order. Please try again.");
        setConfirmationSuccess("");
      }
    } catch (error) {
      console.error(error);
      setConfirmationError("An error occurred while confirming the order.");
      setConfirmationSuccess("");
    }
  };

  // Handle request for revision
  const handleRequestRevision = async () => {
    setConfirmationSuccess(""); // Reset success message
    setConfirmationError(""); // Reset error message

    // Add your revision logic here
    console.log("Revision requested for order:", orderId);
    setOrder(prevOrder => ({
      ...prevOrder,
      status: "Revision Requested"
    }));
  };

  // Handle opening modal
  const handleOpenModal = () => setShowModal(true);

  // Handle closing modal
  const handleCloseModal = () => setShowModal(false);

  return (
    <div style={{ padding: "1rem", maxWidth: "100%", overflowX: "auto" }}>
      <h2>Order Details</h2>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : order ? (
        <Table bordered hover responsive className="mt-4">
          <thead>
            <tr>
              <th>Amount</th>
              <th>Listing</th>
              <th>Website Name</th>
              <th>Article Requirement</th>
              <th>Target Site</th>
              <th>Min. Content(words)</th>
              <th>External Link Allow (number)</th>
              <th>Balance Type</th>
              <th>Status</th>
              <th>Details</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>${order.amount.toFixed(2)}</td>
              <td>{order.listing}</td>
              <td>{order.site_url}</td>

              {(() => {
                // Parse the detail field
                const orderDetail = JSON.parse(order.detail);

                return orderDetail.service_type === "Guest Post" ? (
                  order.order_type === "Standard" ? (
                    <td>Buyer is providing Article</td>
                  ) : (
                    <td>Buyer asked you to write article</td>
                  )
                ) : (
                  <td>No article needed</td>
                );
              })()}
              <td>
                <a href={order.target_site} target="_blank" rel="noopener noreferrer">
                  {order.target_site}
                </a>
              </td>
              <td>
                  {listingDetails.min_content}
              
              </td>
              <td>
                  {listingDetails.externallinkallow}
              
              </td>
      
              <td>{order.balance_type}</td>
              <td>{order.status}</td>
              <td>
                {
                  // Parse the JSON string in 'detail' and display key-value pairs
                  Object.entries(JSON.parse(order.detail)).map(([key, value], index) => (
                    <div key={index}>
                      <strong>{key}:</strong> {value}
                    </div>
                  ))
                }
              </td>
              <td>
                {order.status === "Delivered" && (
                  <button className="btn btn-primary mainbutton" onClick={handleOpenModal}>
                    Response
                  </button>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      ) : (
        <Alert variant="info">No order details found.</Alert>
      )}

      {/* Show success or error messages */}
      {confirmationSuccess && <Alert variant="success">{confirmationSuccess}</Alert>}
      {confirmationError && <Alert variant="danger">{confirmationError}</Alert>}

      {/* Modal for Response */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Order Response</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Did you receive everything? Make sure to check everything Before confirming your order</p>
        
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
    <Button
      variant="success"
      onClick={() => { handleConfirmOrder(); handleCloseModal(); }}
      style={{ width: '48%' }}
    >
      Yes, Mark Done
    </Button>
    <Button
      variant="danger"
      onClick={() => { handleRequestRevision(); handleCloseModal(); }}
      style={{ width: '48%' }}
    >
      No, I need revision
    </Button>
  </Modal.Footer>

      </Modal>
    </div>
  );
};

export default OrderDetails;
