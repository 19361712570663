import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import BuyNowButton from "./BuyNowButton";
import InputGroup from "react-bootstrap/InputGroup";
import NicheSearchDropdown from "./NicheSearchDropdown";
import { useNavigate } from "react-router-dom";
import VerifiedBadge from "../static/images/verifiedbadge.svg";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Pagination from "react-bootstrap/Pagination";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const normalizeType = (type) => {
  if (!type) return '';
  
  // Map of possible variations to standardized values
  const typeMap = {
    'guest post': 'Guest Post',
    'guestpost': 'Guest Post',
    'link insert': 'Link Insert',
    'linkinsert': 'Link Insert',
    'both': 'Both'
  };
  
  const normalizedInput = type.toLowerCase().trim();
  return typeMap[normalizedInput] || type;
};

const ListingsTable = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [favoriteListings, setFavoriteListings] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedListing, setSelectedListing] = useState(null);
  const [projects, setProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleCloseFailedModal = () => setShowFailedModal(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailedModal, setShowFailedModal] = useState(false);
  const [selectedNicheFilter, setSelectedNicheFilter] = useState(null);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const splitSubniches = (subnicheString) => {
    if (!subnicheString) return [];
    // Split by comma and clean up each subniche
    return subnicheString
      .split(',')
      .map(s => s.trim())
      .filter(Boolean); // Remove empty strings
  };

  const cleanupSubniche = (subniche) => {
    if (!subniche) return '';
    return subniche.replace(/,\s*,/g, ',').trim();
  };

  const handleSort = (sortByField) => {
    const newSortOrder = sortByField === sortBy 
      ? (sortOrder === "asc" ? "desc" : "asc") 
      : "asc";
    
    setSortBy(sortByField);
    setSortOrder(newSortOrder);
    
    // No need to sort here, as useEffect will trigger applyFilters
  };

  const renderSortIndicator = (field) => {
    return (
      <>
        <span style={{ 
          fontSize: "16px", 
          color: "#007bff", 
          marginLeft: "8px",
          visibility: sortBy === field && sortOrder === "asc" ? "visible" : "hidden"
        }}>
          ↑
        </span>
        <span style={{ 
          fontSize: "16px", 
          color: "#007bff", 
          marginLeft: "8px",
          visibility: sortBy === field && sortOrder === "desc" ? "visible" : "hidden"
        }}>
          ↓
        </span>
      </>
    );
  };

  const [formData, setFormData] = useState({
    listing_id: null,
    order_type: "Standard",
    target_site: "",
    detail: "",
    balance_type: "main_balance",
    quantity: 1,
    coupon_code: '',
    coupon_details: null,
    service_type: "",
    post_link: "",
    anchor_text: "",
    destination_link: "",
    article_option: "",
    keyword: "",
    article_link: ""
  });

  const [filters, setFilters] = useState({
    type: "",
    niche: "",
    subniche: "",
    backlink_type: "",
    language: "",
    trafficMin: "",
    trafficMax: "",
    daMin: "",
    daMax: "",
    drMin: "",
    drMax: "",
    tatMin: 0,
    tatMax: 10,
    priceMin: "",
    priceMax: "",
  });

  const accessKey = localStorage.getItem("token");
  const [currentPage, setCurrentPage] = useState(1);
  const listingsPerPage = 10;

  const handleBuyNowClick = (listing) => {
    setSelectedListing(listing);
    setFormData((prevData) => ({
      ...prevData,
      listing_id: listing.listing_id,
      order_type: "Standard",
    }));
    setShowModal(true);
    fetchProjects();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormData({
      listing_id: null,
      order_type: "Standard",
      target_site: "",
      detail: "",
      balance_type: "main_balance",
      quantity: 1,
    });
    setSelectedListing(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const fetchProjects = async () => {
    setError("");

    const token = localStorage.getItem("token");
    if (!token) {
      setError("Access token is missing!");
      return;
    }

    const url = `https://lionfish-app-nh929.ondigitalocean.app/projects/all/`;
    const myHeaders = new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    });

    try {
      const response = await fetch(url, { method: "GET", headers: myHeaders });
      if (response.ok) {
        const result = await response.json();
        setProjects(result);
      } else {
        setError("Failed to fetch project details.");
      }
    } catch (error) {
      setError("An error occurred while fetching data.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let detailObject = {};

    if (selectedListing.type === "Link Insert" || formData.service_type === "Link Insert") {
      detailObject = {
        service_type: "Link Insert",
        post_link: formData.post_link,
        anchor_text: formData.anchor_text,
        additional_info: formData.destination_link
      };
    } else if (selectedListing.type === "Guest Post" || formData.service_type === "Guest Post") {
      if (formData.article_option === "write_article") {
        detailObject = {
          service_type: "Guest Post",
          keyword: formData.keyword,
          anchor_text: formData.anchor_text,
          additional_info: formData.destination_link
        };
      } else {
        detailObject = {
          service_type: "Guest Post",
          article_link: formData.article_link,
          anchor_text: formData.anchor_text,
          additional_info: formData.destination_link
        };
      }
    }

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessKey}`);
    myHeaders.append("Content-Type", "application/json");

    const url =
      formData.order_method === "project"
        ? "https://lionfish-app-nh929.ondigitalocean.app/projects/orders/"
        : "https://lionfish-app-nh929.ondigitalocean.app/orders/";

    const body = {
      listing_id: formData.listing_id,
      order_type: formData.order_type,
      target_site: formData.target_site,
      detail: JSON.stringify(detailObject),
      balance_type: formData.balance_type,
      quantity: formData.quantity,
      coupon_code: formData.coupon_code || null,
      ...(formData.order_method === "project" && { project_id: formData.project_id })
    };

    console.log("Request Body:", body);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    };

    try {
      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      const result = await response.json();
      console.log("Order created successfully:", result);
      setMessage("Your order created successfully. Go to the order or project page to manage it.");
      setOrderData(result);
      setShowSuccessModal(true);
    } catch (error) {
      console.error("Error creating order:", error);
      setMessage("Please make sure you or your project owner has enough balance and try again.");
      setShowFailedModal(true);
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  const handleOrderPage = () => {
    const site = `/order/${orderData.order.order_id}`;
    navigate(site);
  };

  const handleNicheSelection = (selection) => {
    console.log('Selected filter:', selection); // Debug log
    setSelectedNicheFilter(selection);
  };

  const handleFavorite = async (listingid) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessKey}`);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow"
    };

    try {
      const response = await fetch(`https://lionfish-app-nh929.ondigitalocean.app/listings/${listingid}/favourite/`, requestOptions);
      const result = await response.text();
      alert('Added as favorite');
      console.log(result);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const fetchFavoriteListings = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessKey}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    try {
      const response = await fetch("https://lionfish-app-nh929.ondigitalocean.app/listings/favourite/", requestOptions);
      const result = await response.json();
      console.log(result);
      return result;
    } catch (error) {
      console.error("Error fetching favorites:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${accessKey}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      try {
        const response = await fetch(
          "https://lionfish-app-nh929.ondigitalocean.app/listings",
          requestOptions
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
        const reversedResult  = await response.json();
        const resulte = reversedResult.reverse();
        const result = resulte.filter((item) => item.status === "Active");

        setData(result);
        setFilteredData(result);
        console.log(result);
      } catch (error) {
        console.error("Error fetching listings:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchFavorites = async () => {
      const favoriteListings = await fetchFavoriteListings();
      setFavoriteListings(favoriteListings);
    };

    fetchFavorites();
    fetchData();
  }, []);

  const cleanUrl = (url) => {
    try {
      return new URL(url).hostname.replace(/^www\./, '');
    } catch (e) {
      return url.replace(/^https?:\/\/(www\.)?/, '');
    }
  };

  useEffect(() => {
    applyFilters();
  }, [filters, selectedNicheFilter, searchTerm, data, sortBy, sortOrder]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const applyFilters = () => {
    console.log('Applying filters with sort:', sortBy, sortOrder);
  
    let filtered = data.filter((listing) => {
      // Basic filters remain the same
      const basicFilters = (
        (!filters.type || normalizeType(listing.type) === filters.type) &&
        (!filters.backlink_type || listing.backlink_type === filters.backlink_type) &&
        (!filters.language || listing.language === filters.language) &&
        (!filters.trafficMin || listing.traffic >= parseInt(filters.trafficMin)) &&
        (!filters.trafficMax || listing.traffic <= parseInt(filters.trafficMax)) &&
        (!filters.daMin || listing.da >= parseInt(filters.daMin)) &&
        (!filters.daMax || listing.da <= parseInt(filters.daMax)) &&
        (!filters.drMin || listing.dr >= parseInt(filters.drMin)) &&
        (!filters.drMax || listing.dr <= parseInt(filters.drMax)) &&
        (!filters.tatMin || listing.tat >= parseInt(filters.tatMin)) &&
        (!filters.tatMax || listing.tat <= parseInt(filters.tatMax)) &&
        (!filters.priceMin || listing.amount >= parseFloat(filters.priceMin)) &&
        (!filters.priceMax || listing.amount <= parseFloat(filters.priceMax))
      );
  
      // Niche/subniche filter logic
      const nicheFilter = !selectedNicheFilter ? true : 
        selectedNicheFilter.type === 'niche' 
          ? listing.niche.toLowerCase() === selectedNicheFilter.value.toLowerCase()
          : (listing.niche.toLowerCase() === selectedNicheFilter.parent.toLowerCase() && 
             listing.subniche && 
             listing.subniche
               .toLowerCase()
               .split(',')
               .map(s => s.trim())
               .includes(selectedNicheFilter.value.toLowerCase()));
  
      // Search term filter
      const searchFilter = !searchTerm ? true :
        listing.site_url.toLowerCase().includes(searchTerm.toLowerCase()) ||
        listing.niche.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (listing.subniche && listing.subniche.toLowerCase().includes(searchTerm.toLowerCase())) ||
        listing.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (listing.backlink_type && listing.backlink_type.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (listing.language && listing.language.toLowerCase().includes(searchTerm.toLowerCase()));
  
      return basicFilters && nicheFilter && searchFilter;
    });
  
    // Apply sorting if there's an active sort
    if (sortBy) {
      filtered.sort((a, b) => {
        let valueA = a[sortBy];
        let valueB = b[sortBy];
  
        // Handle numeric fields
        if (["traffic", "da", "dr", "tat", "amount"].includes(sortBy)) {
          valueA = Number(valueA) || 0;
          valueB = Number(valueB) || 0;
        }
  
        // Handle string comparison
        if (typeof valueA === "string" && typeof valueB === "string") {
          return sortOrder === "asc" 
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        }
  
        // Handle numeric comparison
        return sortOrder === "asc" 
          ? valueA - valueB 
          : valueB - valueA;
      });
    }
  
    setFilteredData(filtered);
  };

  const resetFilters = () => {
    setFilters({
      type: "",
      backlink_type: "",
      language: "",
      trafficMin: "",
      trafficMax: "",
      daMin: "",
      daMax: "",
      drMin: "",
      drMax: "",
      tatMin: 0,
      tatMax: 10,
      priceMin: "",
      priceMax: "",
    });
    setSelectedNicheFilter(null);
    setSearchTerm("");
  };

  const getUniqueValues = (field) => {
    return [...new Set(data.map((item) => item[field]))];
  };

  const handleSearch = (e) => {
    e.preventDefault();

    const filtered = data.filter((listing) => {
      return Object.values(listing).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  const indexOfLastListing = currentPage * listingsPerPage;
  const indexOfFirstListing = indexOfLastListing - listingsPerPage;
  const currentListings = filteredData.slice(indexOfFirstListing, indexOfLastListing);

  const isFavorite = (listingId) => {
    return Array.isArray(favoriteListings) && favoriteListings.includes(listingId);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const renderPaginationItems = () => {
    const totalPages = Math.ceil(filteredData.length / listingsPerPage);
    const SIBLINGS_COUNT = 2; // Number of siblings on each side
    
    let items = [];
    
    // Always show first page
    items.push(
      <Pagination.Item 
        key={1} 
        active={1 === currentPage}
        onClick={() => handlePageChange(1)}
      >
        1
      </Pagination.Item>
    );
  
    // Calculate the range of pages to show
    const leftSibling = Math.max(currentPage - SIBLINGS_COUNT, 2);
    const rightSibling = Math.min(currentPage + SIBLINGS_COUNT, totalPages - 1);
  
    // Add ellipsis after first page if needed
    if (leftSibling > 2) {
      items.push(<Pagination.Ellipsis key="ellipsis-1" disabled/>);
    }
  
    // Add pages between siblings
    for (let i = leftSibling; i <= rightSibling; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
  
    // Add ellipsis before last page if needed
    if (rightSibling < totalPages - 1) {
      items.push(<Pagination.Ellipsis key="ellipsis-2" disabled/>);
    }
  
    // Always show last page if there is more than one page
    if (totalPages > 1) {
      items.push(
        <Pagination.Item
          key={totalPages}
          active={totalPages === currentPage}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }
  
    return items;
  };

  return (
    <div className="table-responsive" style={{ position: 'relative', overflowX: 'auto' }}>
      <div className="row filter">
        <Form className="mb-3">
          <div className="row">
            <div className="col-md-1">
            <Form.Group>
            <Form.Label>Type</Form.Label>
            <Form.Control
              as="select"
              name="type"
              value={filters.type}
              onChange={handleFilterChange}
            >
              <option value="">Select</option>
              {[...new Set(data.map(item => normalizeType(item.type)))].sort().map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
            </div>
            <div className="col-md-2">
            <Form.Group>
  <Form.Label>Niche/Subniche</Form.Label>
  <NicheSearchDropdown 
    data={data} // All listings data
    filteredData={filteredData} // Currently filtered data
    onSelect={(selection) => {
      console.log('Selection in ListingsTable:', selection); // Debug log
      handleNicheSelection(selection);
    }}
    value={selectedNicheFilter}
  />
</Form.Group>
            </div>
            <div className="col-md-1">
              <Form.Group>
                <Form.Label>Backlink Type</Form.Label>
                <Form.Control
                  as="select"
                  name="backlink_type"
                  value={filters.backlink_type}
                  onChange={handleFilterChange}
                >
                  <option value="">All</option>
                  {getUniqueValues("backlink_type").map((backlinkType) => (
                    <option key={backlinkType} value={backlinkType}>
                      {backlinkType}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
            <div className="col-md-1">
              <Form.Group>
                <Form.Label>Language</Form.Label>
                <Form.Control
                  as="select"
                  name="language"
                  value={filters.language}
                  onChange={handleFilterChange}
                >
                  <option value="">All</option>
                  {getUniqueValues("language").map((language) => (
                    <option key={language} value={language}>
                      {language}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
            <div className="col-md-1">
              <Form.Group>
                <Form.Label>Traffic Min</Form.Label>
                <Form.Control
                  type="number"
                  name="trafficMin"
                  value={filters.trafficMin}
                  onChange={handleFilterChange}
                  placeholder="Min"
                />
              </Form.Group>
            </div>
            <div className="col-md-1">
              <Form.Group>
                <Form.Label>Traffic Max</Form.Label>
                <Form.Control
                  type="number"
                  name="trafficMax"
                  value={filters.trafficMax}
                onChange={handleFilterChange}
                placeholder="Max"
              />
            </Form.Group>
          </div>
          <div className="col-md-1">
            <Form.Group>
              <Form.Label>DA Min</Form.Label>
              <Form.Control
                type="number"
                name="daMin"
                value={filters.daMin}
                onChange={handleFilterChange}
                placeholder="Min"
              />
            </Form.Group>
          </div>
          <div className="col-md-1">
            <Form.Group>
              <Form.Label>DA Max</Form.Label>
              <Form.Control
                type="number"
                name="daMax"
                value={filters.daMax}
                onChange={handleFilterChange}
                placeholder="Max"
              />
            </Form.Group>
          </div>
          <div className="col-md-1">
            <Form.Group>
              <Form.Label>DA Max</Form.Label>
              <Form.Control
                type="number"
                name="daMax"
                value={filters.daMax}
                onChange={handleFilterChange}
                placeholder="Max"
              />
            </Form.Group>
          </div>
          <div className="col-md-1">
            <Form.Group>
              <Form.Label>DR Min</Form.Label>
              <Form.Control
                type="number"
                name="drMin"
                value={filters.drMin}
                onChange={handleFilterChange}
                placeholder="Min"
              />
            </Form.Group>
          </div>
        </div>

        <div className="row mt-3 align-items-end">
       
          <div className="col-md-2">
            <Form.Group>
              <Form.Label>TAT Max (Days)</Form.Label>
              <Form.Control
                 type="range"
                name="tatMax"
                value={filters.tatMax}
                onChange={handleFilterChange}
                placeholder="Max"
                min="0"
                max="30"
                step="1" 
              />
            <p>Selected Value: {filters.tatMax}</p> {/* Display the current value */}

            </Form.Group>
          </div>
          <div className="col-md-2">
            <Form.Group>
              <Form.Label>Price Min</Form.Label>
              <Form.Control
                type="number"
                name="priceMin"
                value={filters.priceMin}
                onChange={handleFilterChange}
                placeholder="Min"
              />
            </Form.Group>
          </div>
          <div className="col-md-2">
            <Form.Group>
              <Form.Label>Price Max</Form.Label>
              <Form.Control
                type="number"
                name="priceMax"
                value={filters.priceMax}
                onChange={handleFilterChange}
                placeholder="Max"
              />
            </Form.Group>
          </div>
          <div className="col-md-4 d-flex align-items-end">
            <Button variant="primary" onClick={applyFilters}>
              Apply Filters
            </Button>
            <Button variant="secondary" className="ms-2" onClick={resetFilters}>
              Reset Filters
            </Button>
          </div>
        </div>
      </Form>

      </div>
      {/* Table */}
      <div className="d-flex justify-content-between ">
        {/* Search Bar */}
     
        </div>
        <Table className="">
        <thead>
  <tr className="headertr">
    <th style={{ 
      left: 0,           
      backgroundColor: 'white',
      zIndex: 1,
      maxWidth: '250px',
    }}>
      <Form onSubmit={handleSearch}>
        <Form.Control
          type="text"
          placeholder="Sitename..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleSearch(e)}
          style={{ width: '100%' }}
        />
      </Form>
    </th>
    <th onClick={() => handleSort("dr")} style={{ cursor: "pointer" }}>
      DR
      {renderSortIndicator("dr")}
    </th>
    <th onClick={() => handleSort("traffic")} style={{ cursor: "pointer" }}>
      Traffic
      {renderSortIndicator("traffic")}
    </th>
    <th onClick={() => handleSort("niche")} style={{ cursor: "pointer" }}>
      Niche
      {renderSortIndicator("niche")}
    </th>
    <th onClick={() => handleSort("subniche")} style={{ cursor: "pointer" }}>
      SubNiche
      {renderSortIndicator("subniche")}
    </th>
    <th onClick={() => handleSort("type")} style={{ cursor: "pointer" }}>
      Type
      {renderSortIndicator("type")}
    </th>
    <th onClick={() => handleSort("language")} style={{ cursor: "pointer" }}>
      Language
      {renderSortIndicator("language")}
    </th>
    <th onClick={() => handleSort("tat")} style={{ cursor: "pointer" }}>
      TAT
      {renderSortIndicator("tat")}
    </th>
    <th>Sample Link</th>
    <th onClick={() => handleSort("amount")} style={{ cursor: "pointer" }}>
      Price
      {renderSortIndicator("amount")}
    </th>
  </tr>
</thead>
<tbody>
  {currentListings.map((listing) => (
    <tr key={listing.listing_id} className="bodytr">
      <td
        className="text-start linktd"
        style={{
          left: 0,
          backgroundColor: "white",
          zIndex: 1,
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          position: "sticky",
          minWidth: "200px",
          maxWidth: "200px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {listing.sample && (
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id={`tooltip-${listing.listing_id}`}>
                  Guaranteed Placement
                </Tooltip>
              }
            >
              <img 
                src={VerifiedBadge} 
                alt="Verified Sample" 
                style={{ 
                  width: "24px", 
                  height: "auto",
                  cursor: "help" 
                }} 
              />
            </OverlayTrigger>
          )}
          <a
            href={`https://${cleanUrl(listing.site_url)}?utm_source=linkscope&utm_medium=list&utm_campaign=BL`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textDecoration: "none",
              color: "black",
            }}
          >
            {cleanUrl(listing.site_url)}
          </a>
        </div>
      </td>
      <td>{listing.dr}</td>
      <td>{listing.ahreftraffic}</td>
      <td>{listing.niche}</td>
      <td>{truncateText(cleanupSubniche(listing.subniche), 10)}</td>
      <td>{normalizeType(listing.type)}</td>
      <td>{listing.language}</td>
      <td>{listing.tat} Days</td>
      <td>
        {listing.sample ? (
          <a href={`${listing.sample}?utm_source=linkscope&utm_medium=list&utm_campaign=BL`} target="_blank" rel="noopener noreferrer">
            sample
          </a>
        ) : (
          <span>sample</span>
        )}
      </td>
      <td>
        {!isFavorite(listing.listing_id) && (
          <button 
            onClick={() => handleFavorite(listing.listing_id)} 
            style={{ marginTop: '10px', padding: '5px 10px', border: 'none', borderRadius: '5px' }}
            title="Mark as favorite"
          >
            ❤️
          </button>
        )}
        <BuyNowButton
          price={listing.amount}
          onClick={() => handleBuyNowClick(listing)}
        />
      </td>
    </tr>
  ))}
</tbody>
    </Table>

      {/* Pagination */}
      <Pagination className="justify-content-center mt-3">
        <Pagination.First 
          onClick={() => handlePageChange(1)} 
          disabled={currentPage === 1}
        />
        <Pagination.Prev 
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        />
        
        {renderPaginationItems()}
        
        <Pagination.Next 
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === Math.ceil(filteredData.length / listingsPerPage)}
        />
        <Pagination.Last 
          onClick={() => handlePageChange(Math.ceil(filteredData.length / listingsPerPage))}
          disabled={currentPage === Math.ceil(filteredData.length / listingsPerPage)}
        />
      </Pagination>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Buy Your Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>

  {selectedListing && (
    <Form onSubmit={handleSubmit} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
    {/* Order Method Selection */}
      <Form.Group className="mb-3">
        <Form.Label>Order Method</Form.Label>
        <Form.Control
          as="select"
          name="order_method"
          value={formData.order_method}
          onChange={handleChange}
        >
          <option value="direct">Direct Order</option>
          <option value="project">Order Under a Project</option>
        </Form.Control>
      </Form.Group>

      {/* Project Selection (if order_method is project) */}
      {formData.order_method === "project" && (
        <Form.Group className="mb-3">
          <Form.Label>Select Project</Form.Label>
          <Form.Control
            as="select"
            name="project_id"
            value={formData.project_id}
            onChange={handleChange}
            required
          >
            <option value="">-- Select a Project --</option>
            {projects.map((project) => (
              <option key={project.id} value={project.id}>
                {project.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      )}

      {/* Service Type Selection for "Both" type listings */}
      {normalizeType(selectedListing.type) === "Both" && (
  <Form.Group className="mb-3">
    <Form.Label>Service Type</Form.Label>
    <Form.Control
      as="select"
      name="service_type"
      value={formData.service_type}
      onChange={handleChange}
      required
    >
      <option value="">Select Service Type</option>
      <option value="Link Insert">Link Insert</option>
      <option value="Guest Post">Guest Post</option>
    </Form.Control>
  </Form.Group>
)}

      {/* Fields for Link Insert */}
      {(normalizeType(selectedListing.type) === "Link Insert" || formData.service_type === "Link Insert") && (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Targeted Site Post URL</Form.Label>
            <InputGroup>
    {/* Fixed site URL on the left */}
    <InputGroup.Text>{`${selectedListing.site_url}`}</InputGroup.Text>
    
    {/* Editable input field for the extension */}
    <Form.Control
      type="text"
      name="post_link"
      value={formData.post_link}
      onChange={(e) => {
        const newExtension = e.target.value.startsWith("/") 
          ? e.target.value 
          : `/${e.target.value}`; // Ensure it always starts with '/'
        handleChange({ target: { name: "post_link", value: newExtension } });
      }}
      placeholder="e.g. rest-of-the-link"
      required
    />
  </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Your Landing Page</Form.Label>
            <Form.Control
              type="text"
              name="target_site"
              value={formData.target_site}
              onChange={handleChange}
              placeholder="Enter your landing page link"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Anchor Text</Form.Label>
            <Form.Control
              type="text"
              name="anchor_text"
              value={formData.anchor_text}
              onChange={handleChange}
              placeholder="Enter anchor text"
              required
            />
          </Form.Group>
  
        </>
      )}

      {/* Fields for Guest Post */}
      {(normalizeType(selectedListing.type) === "Guest Post" || formData.service_type === "Guest Post") && (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Article Option</Form.Label>
            <Form.Control
              as="select"
              name="article_option"
              value={formData.article_option}
              onChange={(e) => {
                handleChange(e);
                setFormData(prev => ({
                  ...prev,
                  order_type: e.target.value === "write_article" ? "Premium" : "Standard"
                }));
              }}
              required
            >
              <option value="">Select Option</option>
              <option value="write_article">I want you to write Article</option>
              <option value="provide_article">I will Provide Article</option>
            </Form.Control>
          </Form.Group>

          {formData.article_option === "write_article" && (
            <>
            <Form.Group className="mb-3">
              <Form.Label>Keyword</Form.Label>
              <Form.Control
                type="text"
                name="keyword"
                value={formData.keyword}
                onChange={handleChange}
                placeholder="Enter keyword"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Anchor Text</Form.Label>
                <Form.Control
                  type="text"
                  name="anchor_text"
                  value={formData.anchor_text}
                  onChange={handleChange}
                  placeholder="Enter anchor text"
                  required
                />
              </Form.Group>
            </>
            
      
            
          )}

<Form.Group className="mb-3">
            <Form.Label>Your Landing Page</Form.Label>
            <Form.Control
              type="text"
              name="target_site"
              value={formData.target_site}
              onChange={handleChange}
              placeholder="Enter your landing page link"
              required
            />
          </Form.Group>


          

          {formData.article_option === "provide_article" && (
            <>


              <Form.Group className="mb-3">
                <Form.Label>Article Google Doc Link</Form.Label>
                <Form.Control
                  type="text"
                  name="article_link"
                  value={formData.article_link}
                  onChange={handleChange}
                  placeholder="Enter Google Doc link"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Anchor Text</Form.Label>
                <Form.Control
                  type="text"
                  name="anchor_text"
                  value={formData.anchor_text}
                  onChange={handleChange}
                  placeholder="Enter anchor text"
                  required
                />
              </Form.Group>
   
            </>
          )}
        </>
      )}

      {/* Target Site Field */}
      <Form.Group className="mb-3">
  <Form.Label>Additional Notes</Form.Label>
  <Form.Control
    as="textarea"
    name="destination_link"
    value={formData.destination_link}
    onChange={handleChange}
    placeholder="Write any custom instructions"
    required
  />
</Form.Group>

      {/* Balance Type Selection */}
      <Form.Group className="mb-3">
        <Form.Label>Balance Type</Form.Label>
        <Form.Control
          as="select"
          name="balance_type"
          value={formData.balance_type}
          onChange={handleChange}
        >
          <option value="main_balance">Main Balance</option>
        </Form.Control>
      </Form.Group>

      {/* Coupon Code Section */}
<Form.Group className="mb-3">
  <Form.Label>Coupon Code</Form.Label>
  <div className="d-flex gap-2">
    <Form.Control
      type="text"
      name="coupon_code"
      value={formData.coupon_code || ''}
      onChange={handleChange}
      placeholder="Enter coupon code"
    />
    <Button 
      variant="outline-secondary" 
      onClick={async () => {
        if (!formData.coupon_code) return;
        try {
          const response = await fetch(
            `https://lionfish-app-nh929.ondigitalocean.app/coupons/${formData.coupon_code}/`,
            {
              headers: {
                Authorization: `Bearer ${accessKey}`,
              },
            }
          );
          if (response.ok) {
            const couponData = await response.json();
            setFormData(prev => ({
              ...prev,
              coupon_details: couponData
            }));
          } else {
            setFormData(prev => ({...prev, coupon_details: null}));
            alert('Invalid coupon code');
          }
        } catch (error) {
          console.error('Coupon validation failed:', error);
        }
      }}
    >
      Apply
    </Button>
  </div>
  {formData.coupon_details && (
    <div className="mt-2 text-success">
      Applied: {formData.coupon_details.name} ({formData.coupon_details.amount}% off)
    </div>
  )}
</Form.Group>

      {/* Price Display */}
      <p>
        Price:{" "}
        {formData.coupon_details ? (
          <>
            <span style={{ textDecoration: "line-through" }}>
              ${(formData.order_type === "Premium" ? selectedListing.premium_price : selectedListing.amount).toFixed(2)}
            </span>{" "}
            <span className="text-success">
              ${((formData.order_type === "Premium" ? selectedListing.premium_price : selectedListing.amount) * (1 - formData.coupon_details.amount / 100)).toFixed(2)}
            </span>
          </>
        ) : (
          `$${formData.order_type === "Premium" ? selectedListing.premium_price.toFixed(2) : selectedListing.amount.toFixed(2)}`
        )}
      </p>

      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  )}
</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>













      {/* Order Success Modal */}
      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Order Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          🎉 Your order has been placed successfully!
        </Modal.Body>
        <Modal.Footer>
        <Button variant="success" onClick={handleOrderPage}>See Order Details</Button>
        <Button variant="success" onClick={handleCloseSuccessModal}>Buy Another</Button>

        </Modal.Footer>
      </Modal>

      {/* Order Failed Modal */}
      <Modal show={showFailedModal} onHide={handleCloseFailedModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Order Failed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ❌ Something went wrong. Please try again.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseFailedModal}>Retry</Button>
        </Modal.Footer>
      </Modal>




    </div>
  );
};

export default ListingsTable;