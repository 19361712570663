import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleIcon  } from "../helper/icons";

const RegisterPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    user_id: "",
    email: "",
    password: "",
    first_name: "",
    last_name: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    };

    try {
      const response = await fetch(
        "https://lionfish-app-nh929.ondigitalocean.app/auth/register/",
        //"https://linkscope.io/backend/auth/register/",
        requestOptions
      );
      if (response.ok) {
        alert("Registration successful");
        navigate("/verification");
      } else {
        const errorData = await response.json();
        console.error("Registration failed:", errorData);
        alert(errorData.message || "Registration failed. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="container-fluid vh-100 d-flex">
      {/* Left Section */}
      <div className="col-md-6 d-flex flex-column align-items-center justify-content-center bg-light">
        <div className="mb-4">
          <a href="/" className="text-decoration-none">
            Back to Home
          </a>
        </div>
        <div className="w-75">
          <h2 className="mb-4">Sign Up as Customer</h2>
          <button className="google-signup">
    <GoogleIcon/>
      Sign up with Google
    </button>
    <p>or</p>
    <hr></hr>

          <form onSubmit={handleSubmit}>
    
              <>
                <div className="mb-3 form-group">
                  <label className="form-label">First Name*</label>
                  <input
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="John"
                    aria-label="First Name"
                    required
                  />
                </div>
                <div className="mb-3 form-group">
                  <label className="form-label">Last Name*</label>
                  <input
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Doe"
                    aria-label="Last Name"
                    required
                  />
                </div>
                <div className="mb-3 form-group">
                  <label className="form-label">Username*</label>
                  <input
                    type="text"
                    name="user_id"
                    value={formData.user_id}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter a unique username"
                    aria-label="Username"
                    required
                  />
                </div>
                <div className="mb-3 form-group">
                  <label className="form-label">Email*</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="example@example.com"
                    aria-label="Email"
                    required
                  />
                </div>
                <div className="mb-3 form-group">
                  <label className="form-label">Password*</label>
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter a secure password"
                    aria-label="Password"
                    required
                  />
                </div>
              </>
      

          
    
        <div className="mt-3 d-flex infog justify-content-between">
              <p className="subcon">
                Already have an account? <a href="/signin">Signin</a>
              </p>
              <p className="subcon">
                <a href="/forgot">Forgot Password?</a>
              </p>
            </div>
            <div className="d-flex justify-content-between mt-4">
              
                <button type="submit" className="btn primarybutton">
                  Submit
                </button>
  
            </div>
    
          </form>
        </div>
        <footer className="d-flex align-items-end mt-5">© 2025 All rights reserved to LinkScop</footer>

      </div>


      {/* Right Section */}
      <div className="col-md-6 d-none d-md-flex align-items-end justify-content-center bg-secondary text-white">
      <div className="footer-links d-flex justify-content-between p-5">
      <a href="/contact">Contact</a>
      <a href="/privacy-policy">Privacy Policy</a>
      <a href="/terms">Terms of Use</a>
      <a href="/blog">Blog</a>
    </div>      </div>
    </div>
  );
};

export default RegisterPage;
