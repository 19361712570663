import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";

const CategoryListings = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const listingsPerPage = 10;
  const { nichename } = useParams();
  const navigate = useNavigate();

  // Fetch Data Function
  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://lionfish-app-nh929.ondigitalocean.app/categories/public/${nichename}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const result = await response.json();
      console.log(result);
      setData(result || []);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [nichename]);

  // Fake Pagination Logic
  const totalPages = 10; // Always show 10 pages for fake pagination
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle Navigation
  const handleNextClick = () => {
    const accesskey = localStorage.getItem("token");
    navigate(accesskey ? "/dashboard/" : "/signin/");
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mt-4">
      <h2>Category Listings</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Site URL</th>
            <th>Price</th>
            <th>Type</th>
            <th>Language</th>
            <th>Traffic</th>
            <th>DA</th>
            <th>DR</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((listing) => (
              <tr key={listing.listing_id}>
                <td>
                  <a href={listing.site_url} target="_blank" rel="noopener noreferrer">
                    {listing.site_url}
                  </a>
                </td>
                <td>${listing.amount}</td>
                <td>{listing.type}</td>
                <td>{listing.language}</td>
                <td>{listing.traffic}</td>
                <td>{listing.da}</td>
                <td>{listing.dr}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="10" className="text-center">
                No Data Available
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Fake Pagination */}
      

      {/* Next Button */}
      <div className="text-end mt-3">
        <Button onClick={handleNextClick} variant="primary">Next</Button>
      </div>
    </div>
  );
};

export default CategoryListings;
