import React, { useState, useEffect } from "react";
import AdminHorizontal from "../components/admin/AdminHorizontal";
import AdminVertical from "../components/admin/AdminVertical";
import Commission from "../components/admin/Comission";
import CountTotalUser from "../components/admin/CountTotalUser";

const AdminDashboard = () => {
  const [isVerticalMenuVisible, setVerticalMenuVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orders, setOrders] = useState([]);

  // Order Counts
  const [allOrders, setAllOrders] = useState(0);
  const [pendingOrders, setPendingOrders] = useState(0);
  const [submittedOrders, setSubmittedOrders] = useState(0);
  const [deliveredOrders, setDeliveredOrders] = useState(0);
  const [completedOrders, setCompletedOrders] = useState(0);
  const [rejectOrders, setRejectOrders] = useState(0);


  const toggleVerticalMenu = () => {
    setVerticalMenuVisible(!isVerticalMenuVisible);
  };

  const accessKey = localStorage.getItem("token");

  const fetchOrders = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessKey}`);
    myHeaders.append("Content-Type", "application/json");

    try {
      const response = await fetch(
        "https://lionfish-app-nh929.ondigitalocean.app/all/orders",
        {
          method: "GET",
          headers: myHeaders,
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const result = await response.json();
      setOrders(result); // Store orders in state
      setAllOrders(result.length); // Total orders count

      // Filter orders based on status
      setPendingOrders(result.filter(order => order.status === "Pending").length);
      setSubmittedOrders(result.filter(order => order.status === "Active").length); // "Active" → "Submitted"
      setDeliveredOrders(result.filter(order => order.status === "Delivered").length);
      setCompletedOrders(result.filter(order => order.status === "Completed").length);
      setRejectOrders(result.filter(order => order.status === "Rejected").length);

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <div className="d-flex">
      {/* Vertical Menu */}
      <div
        className={`vertical-menu-container ${
          isVerticalMenuVisible ? "d-block" : "d-none d-md-block"
        }`}
      >
        <AdminVertical />
      </div>

      {/* Main Content Area */}
      <div style={{ flex: 1 }}>
        {/* Horizontal Menu with Toggle */}
        <AdminHorizontal onToggleVerticalMenu={toggleVerticalMenu} />
        
        <div className="p-4">
          <div className="row">
            <div className="col-md-3 filter">
              <Commission />
            </div>
            <div className="col-md-3 filter">
              <CountTotalUser />
            </div>
            <div className="col-md-3 filter">
              <h2>Total Orders</h2>
              <h2>{allOrders}</h2>

            </div>
          </div>

          {/* Order Status Counts */}
          <div className="row mt-4">
            <div className="col-md-3 filter">
              <h2>Pending Orders</h2>
              <h2>{pendingOrders}</h2>

            </div>
            <div className="col-md-3 filter">
              <h2>Submitted Orders</h2>
              <h2>{submittedOrders}</h2>

            </div>
            <div className="col-md-3 filter">
              <h2>Delivered Orders</h2>
              <h2>{deliveredOrders}</h2>

            </div>
            <div className="col-md-3 filter">
              <h2>Completed Orders</h2>
              <h2> {completedOrders}</h2>

            </div>

            <div className="col-md-3 filter">
              <h2>Rejected Orders</h2>
              <h2> {rejectOrders}</h2>

            </div>
          </div>

          {/* Error Message */}
          {error && <p className="text-danger">Error: {error}</p>}

          {/* Loading Indicator */}
          {loading && <p>Loading orders...</p>}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
