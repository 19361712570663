import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook

const NewListing = () => {
  const navigate = useNavigate(); // Initialize the navigate function
  const [formData, setFormData] = useState({
    site_url: "",
    amount: "",
    type: "Dofollow",
    niche: "",
    language: "English",
    traffic: "",
    da: "",
    dr: "",
    tat: "",
    backlink_type: "",
    sample: "",
    premium_flag: false,
    premium_price: "",
    email: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const getDomainFromUrl = (url) => {
    try {
      const domain = new URL(url).hostname;
      return domain.replace("www.", "");
    } catch (e) {
      return "";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError(null);
    setSuccess(false);

    const siteDomain = getDomainFromUrl(formData.site_url);
    const emailDomain = formData.email.split("@")[1];

    if (siteDomain !== emailDomain) {
      setError("Email domain should match the site URL domain to submit a listing.");
      setLoading(false);
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      setError("Access token is missing.");
      setLoading(false);
      return;
    }

    const requestData = {
      site_url: formData.site_url,
      amount: parseFloat(formData.amount),
      type: formData.type,
      niche: formData.niche,
      language: formData.language,
      traffic: parseInt(formData.traffic),
      da: parseInt(formData.da),
      dr: parseInt(formData.dr),
      tat: parseInt(formData.tat),
      backlink_type: formData.backlink_type,
      sample: formData.sample,
      premium_flag: formData.premium_flag,
      premium_price: parseFloat(formData.premium_price),
      email: formData.email,
    };

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(requestData),
    };

    try {
      const response = await fetch("https://lionfish-app-nh929.ondigitalocean.app/listings/", requestOptions);

      if (response.ok) {
        const result = await response.json();
        setSuccess(true);
        setFormData({
          site_url: "",
          amount: "",
          type: "Dofollow",
          niche: "",
          language: "English",
          traffic: "",
          da: "",
          dr: "",
          tat: "",
          backlink_type: "",
          sample: "",
          premium_flag: false,
          premium_price: "",
          email: "",
        });

        // Navigate to the verification page with listing_id
        navigate(`/verifylisting/${result.listing_id}`);
      } else {
        const result = await response.json();
        setError(result.message || "Failed to create listing.");
      }
    } catch (error) {
      console.error(error);
      setError("An error occurred while creating the listing.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Create New Listing</h2>
      {success && <Alert variant="success">Listing created successfully!</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}
      <div className="row">
        <div className="col-md-6 filter mx-auto p-5">
          <Form onSubmit={handleSubmit}>
            {/* Site URL */}
            <Form.Group controlId="site_url">
              <Form.Label>Site URL</Form.Label>
              <Form.Control
                type="url"
                placeholder="Enter site URL"
                name="site_url"
                value={formData.site_url}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            {/* Amount */}
            <Form.Group controlId="amount">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter amount"
                name="amount"
                value={formData.amount}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            {/* Type */}
            <Form.Group controlId="type">
              <Form.Label>Type</Form.Label>
              <Form.Control
                as="select"
                name="type"
                value={formData.type}
                onChange={handleInputChange}
                required
              >
                <option value="Dofollow">Dofollow</option>
                <option value="Nofollow">Nofollow</option>
              </Form.Control>
            </Form.Group>

            {/* Niche */}
            <Form.Group controlId="niche">
              <Form.Label>Niche</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter niche"
                name="niche"
                value={formData.niche}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            {/* Language */}
            <Form.Group controlId="language">
              <Form.Label>Language</Form.Label>
              <Form.Control
                as="select"
                name="language"
                value={formData.language}
                onChange={handleInputChange}
                required
              >
                <option value="English">English</option>
                <option value="Spanish">Spanish</option>
                <option value="French">French</option>
                {/* Add more languages as needed */}
              </Form.Control>
            </Form.Group>

            {/* Traffic */}
            <Form.Group controlId="traffic">
              <Form.Label>Traffic</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter traffic count"
                name="traffic"
                value={formData.traffic}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            {/* DA */}
            <Form.Group controlId="da">
              <Form.Label>DA (Domain Authority)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter DA"
                name="da"
                value={formData.da}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            {/* DR */}
            <Form.Group controlId="dr">
              <Form.Label>DR (Domain Rating)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter DR"
                name="dr"
                value={formData.dr}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            {/* TAT */}
            <Form.Group controlId="tat">
              <Form.Label>TAT (Turnaround Time)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter TAT (in hours)"
                name="tat"
                value={formData.tat}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            {/* Backlink Type */}
            <Form.Group controlId="backlink_type">
              <Form.Label>Backlink Type</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter backlink type"
                name="backlink_type"
                value={formData.backlink_type}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            {/* Sample */}
            <Form.Group controlId="sample">
              <Form.Label>Sample</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter sample information"
                name="sample"
                value={formData.sample}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            {/* Premium Flag */}
            <Form.Group controlId="premium_flag">
              <Form.Check
                type="checkbox"
                label="Premium Listing"
                name="premium_flag"
                checked={formData.premium_flag}
                onChange={handleInputChange}
              />
            </Form.Group>

            {/* Premium Price */}
            {formData.premium_flag && (
              <Form.Group controlId="premium_price">
                <Form.Label>Premium Price</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter premium price"
                  name="premium_price"
                  value={formData.premium_price}
                  onChange={handleInputChange}
                />
              </Form.Group>
            )}

            {/* Email */}
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            {/* Submit Button */}
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? "Creating..." : "Create Listing"}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default NewListing;
