import React, { useState } from "react";
import HorizontalMenu from "../components/HorizontalMenu";
import VerticalMenu from "../components/VerticalMenu";
import Balance from "../components/Balance";
import WithdrawRequest from "../components/WithdrawRequest";
import PendingWithdraws from "../components/PendingWithdraw";
import TransactionList from "../components/TransactionList";
import AddFundsModal from "../components/AddFundsModal";

const BalancePage = () => {
  const [isVerticalMenuVisible, setVerticalMenuVisible] = useState(false);
  const [isWithdrawModalVisible, setWithdrawModalVisible] = useState(false);
  const [isAddFundsModalVisible, setAddFundsModalVisible] = useState(false);
  const [addFundsAmount, setAddFundsAmount] = useState(0);

  const toggleVerticalMenu = () => {
    setVerticalMenuVisible(!isVerticalMenuVisible);
  };

  const openWithdrawModal = () => setWithdrawModalVisible(true);
  const closeWithdrawModal = () => setWithdrawModalVisible(false);
  
  const openAddFundsModal = () => setAddFundsModalVisible(true);
  const closeAddFundsModal = () => {
    setAddFundsModalVisible(false);
    setAddFundsAmount(0);
  };

  return (
    <div className="d-flex">
      {/* Vertical Menu */}
      <div
        className={`vertical-menu-container ${
          isVerticalMenuVisible ? "d-block" : "d-none d-md-block"
        }`}
      >
        <VerticalMenu />
      </div>

      {/* Main Content Area */}
      <div style={{ flex: 1 }}>
        <HorizontalMenu onToggleVerticalMenu={toggleVerticalMenu} />
        <div className="p-4">
        <div className="row mx-auto">
          <div className="col-md-6 mx-auto">
            <div className="row mx-auto justify-content-between">
            <div className="mb-4 col-md-4 d-flex gap-2">
            <button
              onClick={openAddFundsModal}
              className="btn btn-success"
            >
              Add Funds
            </button>

          
          </div>
          <div className="mb-4 col-md-4 d-flex gap-2">
           

            <button
              onClick={openWithdrawModal}
              className="btn btn-primary"
            >
              Make a Withdrawal Request
            </button>
          </div>
            </div>
 
          </div>

        </div>

          <PendingWithdraws />
          <TransactionList />
        </div>
      </div>

      {/* Add Funds Modal */}
      {isAddFundsModalVisible && (
        <AddFundsModal
          amount={addFundsAmount}
          setAmount={setAddFundsAmount}
          onClose={closeAddFundsModal}
          onSuccess={closeAddFundsModal}
        />
      )}

      {/* Withdraw Request Modal */}
      {isWithdrawModalVisible && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "2rem",
              borderRadius: "8px",
              width: "90%",
              maxWidth: "400px",
              position: "relative",
            }}
          >
            <button
              onClick={closeWithdrawModal}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "transparent",
                border: "none",
                fontSize: "1.5rem",
                cursor: "pointer",
              }}
            >
              &times;
            </button>
            <WithdrawRequest closeModal={closeWithdrawModal} />
            </div>
        </div>
      )}
    </div>
  );
};

export default BalancePage;