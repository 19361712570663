import React, { useState } from "react";
import ResellerHorizontalMenu from "../../components/reseller/Horizontal";
import ResellerVerticalMenu from "../../components/reseller/Vertical";
import MyOrderDetails from "../../components/reseller/MyOrderDetails";
import Messaging from "../../components/Messaging";
const MyOrderSingle = () => {
  const [isVerticalMenuVisible, setVerticalMenuVisible] = useState(false);

  const toggleVerticalMenu = () => {
    setVerticalMenuVisible(!isVerticalMenuVisible);
  };

  return (
    <div className="d-flex">
      {/* Vertical Menu */}
      <div
        className={`vertical-menu-container ${
          isVerticalMenuVisible ? "d-block" : "d-none d-md-block"
        }`}
      >
        <ResellerVerticalMenu />
      </div>

      {/* Main Content Area */}
      <div style={{ flex: 1 }}>
        {/* Horizontal Menu with Toggle */}
        <ResellerHorizontalMenu onToggleVerticalMenu={toggleVerticalMenu} />
        <div className="p-4">
            <MyOrderDetails/>
            <Messaging/>
        </div>
      </div>
    </div>
  );
};

export default MyOrderSingle;