// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-container .nav-links {
    display: flex;
    gap: 32px;
    flex-grow: 1; /* This will push the actions to the right */
    font-size: 1.2rem;
    font-weight: 600;
    color: #08080D;
  }
  
  .nav-container .nav-actions {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;
    
  }
  
  .login-button {
    padding: 12px 20px;
    border-radius: 40px;
    font-weight: 500;
  }
  
  .signup-button {
    padding: 12px 20px;
    border-radius: 40px;
    font-weight: 500;
    background: linear-gradient(270deg, #7a39ed 21%, #5244e4 100%);
    color: #fff;
    border: none;
  }
  
  .btn-outline-primary{
    color: #7a39ed !important;
    border-color: #5244e4;
  }
  .btn-outline-primary:hover{
    color: white !important;
    border-color: #5244e4;
    background: linear-gradient(270deg, #7a39ed 21%, #5244e4 100%);

  }

  .bg-light{
    z-index: 3 !important;
  }
  @media screen and (max-width: 400px) {
    img.nav-logo {
      width: 150px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/home/NavCom.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,YAAY,EAAE,4CAA4C;IAC1D,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,yBAAyB;;EAE3B;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,8DAA8D;IAC9D,WAAW;IACX,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;EACvB;EACA;IACE,uBAAuB;IACvB,qBAAqB;IACrB,8DAA8D;;EAEhE;;EAEA;IACE,qBAAqB;EACvB;EACA;IACE;MACE,YAAY;IACd;EACF","sourcesContent":[".nav-container .nav-links {\n    display: flex;\n    gap: 32px;\n    flex-grow: 1; /* This will push the actions to the right */\n    font-size: 1.2rem;\n    font-weight: 600;\n    color: #08080D;\n  }\n  \n  .nav-container .nav-actions {\n    display: flex;\n    gap: 16px;\n    align-items: center;\n    justify-content: flex-end;\n    \n  }\n  \n  .login-button {\n    padding: 12px 20px;\n    border-radius: 40px;\n    font-weight: 500;\n  }\n  \n  .signup-button {\n    padding: 12px 20px;\n    border-radius: 40px;\n    font-weight: 500;\n    background: linear-gradient(270deg, #7a39ed 21%, #5244e4 100%);\n    color: #fff;\n    border: none;\n  }\n  \n  .btn-outline-primary{\n    color: #7a39ed !important;\n    border-color: #5244e4;\n  }\n  .btn-outline-primary:hover{\n    color: white !important;\n    border-color: #5244e4;\n    background: linear-gradient(270deg, #7a39ed 21%, #5244e4 100%);\n\n  }\n\n  .bg-light{\n    z-index: 3 !important;\n  }\n  @media screen and (max-width: 400px) {\n    img.nav-logo {\n      width: 150px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
