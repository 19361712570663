import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { FaCheckCircle, FaTimesCircle, FaEdit } from "react-icons/fa";
import { Tooltip, Modal, Button, Form } from "react-bootstrap";
import 'react-tooltip/dist/react-tooltip.css'; // Import CSS for versions before v5.13.0

const Commission = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [newCommissionPercentage, setNewCommissionPercentage] = useState(""); // State for input value

  const accesskey = localStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${accesskey}` // Use backticks for string interpolation
      );

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch("https://lionfish-app-nh929.ondigitalocean.app/commission-percentage/", requestOptions);
        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
        const result = await response.json();

        setData(result);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleModalClose = () => setShowModal(false); // Close modal
  const handleModalShow = () => setShowModal(true); // Open modal

  const handleChange = (event) => setNewCommissionPercentage(event.target.value); // Handle input change

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Make sure the new commission percentage is a valid number
    if (isNaN(newCommissionPercentage) || newCommissionPercentage <= 0) {
      alert("Please enter a valid commission percentage.");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${accesskey}`
    );
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ commission_percentage: parseFloat(newCommissionPercentage) }),
      redirect: "follow",
    };

    try {
      const response = await fetch("https://lionfish-app-nh929.ondigitalocean.app/commission-percentage/", requestOptions);
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      // Reload the data to show updated commission percentage
      const result = await response.json();
      setData(result);
      handleModalClose(); // Close modal after submission
    } catch (error) {
      alert("Error updating commission percentage: " + error.message);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <div className="row">
        <div>
          <h3>Commission Percentage</h3>
          <h2>{data.commission_percentage} %</h2>
          <button type="button" className="btn btn-primary mainbutton" onClick={handleModalShow}>Update Commission Percentage</button>
        </div>
      </div>

      {/* Modal for updating commission percentage */}
      <Modal show={showModal} onHide={handleModalClose} >
        <Modal.Header closeButton>
          <Modal.Title>Update Commission Percentage</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="commissionPercentage">
              <Form.Label>New Commission Percentage</Form.Label>
              <Form.Control
                type="number"
                value={newCommissionPercentage}
                onChange={handleChange}
                placeholder="Enter new commission percentage"
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="btn btn-primary mainbutton">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Add Tooltip Components */}
 
    </div>
  );
};

export default Commission;
