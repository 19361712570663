import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import {
  OverviewIcon,
  LinkIcon,
  WalletIcon,
  SigninIcon,
  UserIcon,
} from "../../helper/icons";
import Logout from "../Logout";
import linkscopelogo from "../../static/images/logo.png";

const AdminVertical = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [expandedSubmenu, setExpandedSubmenu] = useState(null);

  const location = useLocation();

  const toggleMenu = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleSubmenu = (submenuKey) => {
    setExpandedSubmenu((prevKey) => (prevKey === submenuKey ? null : submenuKey));
  };

  const isSelected = (path) => location.pathname === path;
  const isSubmenuSelected = (submenu) =>
    submenu.some((item) => isSelected(item.href));

  return (
    <div
      style={{
        height: "100vh",
        width: isCollapsed ? "80px" : "250px",
        transition: "width 0.3s",
        overflow: "hidden",
        whiteSpace: "nowrap",
        boxShadow: "2px 0 5px rgba(0,0,0,0.1)",
      }}
      className="d-flex flex-column vertical"
    >
      {/* Header Section with Toggle Button */}
      <div
        style={{
          display: "flex",
          justifyContent: isCollapsed ? "center" : "space-between",
          alignItems: "center",
          height: "60px",
          borderBottom: "1px solid #43434C",
          cursor: "pointer",
          padding: isCollapsed ? "0 10px" : "0 20px",
        }}
      >
        <div className="tog" onClick={toggleMenu}>
          <FaBars size={20} />
        </div>
        {!isCollapsed && (
         <a href="/admin/dashboard" style={{ textDecoration: "none" }}>
         <div
           className="headerlogo"
           style={{
             fontSize: "16px",
             fontWeight: "bold",
             marginLeft: "10px",
           }}
         >
           <img src={linkscopelogo} alt="Logo" />
         </div>
       </a>
        )}
      </div>

      <Nav className="flex-column">
        {/* Links */}
        {[
          {
            href: "/admin/dashboard",
            icon: <OverviewIcon width={20} height={20} />,
            label: "Dashboard",
          },
          {
            href: "/admin/allusers",
            icon: <UserIcon width={20} height={20} />,
            label: "All Users",
          },
          {
            href: "/admin/orders",
            icon: <UserIcon width={20} height={20} />,
            label: "All Orders",
          },
          {
            href: "/admin/listings",
            icon: <LinkIcon width={20} height={20} />,
            label: "Listings",
            submenu: [
              { href: "/admin/listings/", label: "All Listings" },
              { href: "/admin/listings/pending", label: "Pending Listings" },
              { href: "/admin/listings/approved", label: "Approved Listings" },
              { href: "/admin/listings/rejected", label: "Rejected Listings" },
              { href: "/admin/listings/bulk", label: "Bulk Listings" },

            ],
          },
          {
            href: "/admin/balance",
            icon: <WalletIcon width={20} height={20} />,
            label: "Balance",
          },
          {
            href: "/admin/niche",
            icon: <WalletIcon width={20} height={20} />,
            label: "Niche",
          },
          {
            href: "/admin/coupon",
            icon: <WalletIcon width={20} height={20} />,
            label: "Coupon",
          },
        ].map(({ href, icon, label, submenu }, index) => (
          <div key={href}>
            <Nav.Link
              href={!submenu ? href : undefined}
              className={`text-light ${isSelected(href) ? "selected" : ""}`}
              onClick={submenu ? () => toggleSubmenu(index) : undefined}
            >
              <div
                className="navitem"
                style={{
                  width: "100%",
                  height: "100%",
                  padding: "13px 20px",
                  borderRadius: 30,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: isCollapsed ? "center" : "flex-start",
                  gap: isCollapsed ? 0 : 12,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 24,
                    height: 24,
                  }}
                >
                  {icon}
                </div>
                {!isCollapsed && (
                  <span
                    style={{
                      fontSize: 16,
                      fontFamily: "Inter",
                      fontWeight: 500,
                      lineHeight: "26px",
                    }}
                  >
                    {label}
                  </span>
                )}
              </div>
            </Nav.Link>

            {/* Submenu */}
            {submenu &&
              (expandedSubmenu === index || isSubmenuSelected(submenu)) && (
                <div
                  style={{
                    marginLeft: isCollapsed ? 0 : "20px",
                    paddingLeft: "10px",
                  }}
                >
                  {submenu.map(({ href, label }) => (
                    <Nav.Link
                      href={href}
                      key={href}
                      className={`text-light ${
                        isSelected(href) ? "selected" : ""
                      }`}
                    >
                      <div
                        className="submenu-item"
                        style={{
                          padding: "8px 20px",
                          borderRadius: 30,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: isCollapsed ? "center" : "flex-start",
                        }}
                      >
                        {!isCollapsed && (
                          <span
                            style={{
                              fontSize: 14,
                              fontFamily: "Inter",
                              fontWeight: 400,
                              lineHeight: "22px",
                              color: "#b0b0b0",
                            }}
                          >
                            {label}
                          </span>
                        )}
                      </div>
                    </Nav.Link>
                  ))}
                </div>
              )}
          </div>
        ))}

        {/* Bottom Section */}
        <div className="mt-auto bottom">
          <Nav href="/logout" className="text-light">
            <div
              className="navitem"
              style={{
                width: "100%",
                height: "100%",
                padding: "13px 20px",
                borderRadius: 30,
                display: "flex",
                alignItems: "center",
                justifyContent: isCollapsed ? "center" : "flex-start",
                gap: isCollapsed ? 0 : 12,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 24,
                  height: 24,
                }}
              >
                <SigninIcon width={20} height={20} />
              </div>
              {!isCollapsed && (
                <span
                  style={{
                    fontSize: 16,
                    fontFamily: "Inter",
                    fontWeight: 500,
                    lineHeight: "26px",
                    color: "white",
                  }}
                >
                  <Logout />
                </span>
              )}
            </div>
          </Nav>
        </div>
      </Nav>
    </div>
  );
};

export default AdminVertical;
