// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-section {
    background-color: #08080D; /* Dark background */
    color: #ffffff; /* White text */
    margin-top: 0px;

  }
  
  .footer-logo {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .footer-description {
    font-size: 1.2rem;
    line-height: 1.5;
    color: #bfbfbf;
  }
  
  .footer-title {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    color: #ffffff;
  }
  
  .footer-link {
    font-size: 1.2rem;
    color: #bfbfbf !important;
    text-decoration: none;
  }
  
  .footer-link:hover {
    color: #ffffff;
  }
  
  .input-group .form-control {
    border-radius: 4px 0 0 4px;
    border: none;
  }
  
  .input-group .btn {
    border-radius: 0 4px 4px 0;
  }
  
  .footer-divider {
    border-top: 1px solid #333;
  }
  
  .social-links {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .social-icon {
    font-size: 1.2rem;
    color: #bfbfbf;
    text-decoration: none;
  }
  
  .social-icon:hover {
    color: #ffffff;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/home/Footer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB,EAAE,oBAAoB;IAC/C,cAAc,EAAE,eAAe;IAC/B,eAAe;;EAEjB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,iBAAiB;IACjB,yBAAyB;IACzB,qBAAqB;EACvB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,0BAA0B;IAC1B,YAAY;EACd;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,SAAS;EACX;;EAEA;IACE,iBAAiB;IACjB,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".footer-section {\n    background-color: #08080D; /* Dark background */\n    color: #ffffff; /* White text */\n    margin-top: 0px;\n\n  }\n  \n  .footer-logo {\n    font-size: 1.5rem;\n    font-weight: bold;\n  }\n  \n  .footer-description {\n    font-size: 1.2rem;\n    line-height: 1.5;\n    color: #bfbfbf;\n  }\n  \n  .footer-title {\n    font-size: 1.1rem;\n    margin-bottom: 1rem;\n    color: #ffffff;\n  }\n  \n  .footer-link {\n    font-size: 1.2rem;\n    color: #bfbfbf !important;\n    text-decoration: none;\n  }\n  \n  .footer-link:hover {\n    color: #ffffff;\n  }\n  \n  .input-group .form-control {\n    border-radius: 4px 0 0 4px;\n    border: none;\n  }\n  \n  .input-group .btn {\n    border-radius: 0 4px 4px 0;\n  }\n  \n  .footer-divider {\n    border-top: 1px solid #333;\n  }\n  \n  .social-links {\n    display: flex;\n    justify-content: center;\n    gap: 15px;\n  }\n  \n  .social-icon {\n    font-size: 1.2rem;\n    color: #bfbfbf;\n    text-decoration: none;\n  }\n  \n  .social-icon:hover {\n    color: #ffffff;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
