import React from 'react';
import StripePayment from './StripePayment';

const AddFundsModal = ({ amount, setAmount, onClose, onSuccess }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          padding: "2rem",
          borderRadius: "8px",
          width: "90%",
          maxWidth: "400px",
          position: "relative",
        }}
      >
        <button
          onClick={onClose}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            background: "transparent",
            border: "none",
            fontSize: "1.5rem",
            cursor: "pointer",
          }}
        >
          &times;
        </button>
        <h3>Add Funds to Wallet</h3>
        <div className="mb-3">
          <label htmlFor="amount" className="form-label">
            Amount (USD)
          </label>
          <input
            type="number"
            className="form-control"
            id="amount"
            min="1"
            step="1"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Enter amount"
          />
        </div>
        
        {amount > 0 && (
          <StripePayment 
            amount={amount} 
            onSuccess={onSuccess}
          />
        )}
      </div>
    </div>
  );
};

export default AddFundsModal;