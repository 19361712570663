import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";

const AllProjects = () => {
  const [projects, setProjects] = useState([]); // Stores the project data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false); // Controls modal visibility
  const [newProjectName, setNewProjectName] = useState(""); // Stores the new project name
  const [apiError, setApiError] = useState(""); // Error for the modal
  const [successMessage, setSuccessMessage] = useState(""); // Stores success message
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      setLoading(true);
      setError("");

      const token = localStorage.getItem("token");
      if (!token) {
        setError("Access token is missing!");
        setLoading(false);
        return;
      }

      const url = `https://lionfish-app-nh929.ondigitalocean.app/projects/all/`;
      const myHeaders = new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      });

      try {
        const response = await fetch(url, { method: "GET", headers: myHeaders });
        if (response.ok) {
          const result = await response.json();
          setProjects(result);
        } else {
          setError("Failed to fetch project details.");
        }
      } catch (error) {
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const handleShowMore = (projectId) => {
    navigate(`/projects/${projectId}`);
  };

  const handleCreateProject = async () => {
    setApiError("");

    const token = localStorage.getItem("token");
    if (!token) {
      setApiError("Access token is missing!");
      return;
    }

    const url = `https://lionfish-app-nh929.ondigitalocean.app/projects/`;
    const myHeaders = new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    });

    const raw = JSON.stringify({
      name: newProjectName,
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: myHeaders,
        body: raw,
      });

      if (response.ok) {
        setNewProjectName(""); // Clear input
        setShowModal(false); // Close modal
        setSuccessMessage("Project created successfully!"); // Show success message
        setTimeout(() => {
          window.location.reload(); // Refresh the page after 2 seconds
        }, 2000);
      } else {
        const result = await response.json();
        setApiError(result.message || "Failed to create the project.");
      }
    } catch (error) {
      setApiError("An error occurred while creating the project.");
    }
  };

  return (
    <div style={{ padding: "1rem", maxWidth: "100%", overflowX: "auto" }}>
      <div className="row align-items-center">
        <div className="col-md-6 d-flex justify-content-start">
          <h2 className="text-start">All Projects</h2>
        </div>
        <div className="col-md-6 d-flex justify-content-end">
          <button
            type="button"
            className="mainbutton btn btn-primary"
            onClick={() => setShowModal(true)}
          >
            Create New Project
          </button>
        </div>
      </div>

      {successMessage && (
        <Alert variant="success" className="mt-3">
          {successMessage}
        </Alert>
      )}

      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : projects && projects.length > 0 ? (
        <Table bordered hover responsive className="mt-4">
          <thead>
            <tr>
              <th>Project Name</th>
              <th>Owner</th>
              <th>Team Members</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project) => (
              <tr key={project.id}>
                <td>{project.name}</td>
                <td>{project.owner.user_id}</td>
                <td>
                  {project.team_members && project.team_members.length > 0 ? (
                    project.team_members.map((member, index) => (
                      <span key={index}>
                        {member.user_id}
                        {index < project.team_members.length - 1 && ", "}
                      </span>
                    ))
                  ) : (
                    <em>No team members</em>
                  )}
                </td>
                <td>
                  <button
                    type="button"
                    className="mainbutton btn btn-primary"
                    onClick={() => handleShowMore(project.id)}
                  >
                    Show More
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Alert variant="info">No projects found.</Alert>
      )}

      {/* Modal for Creating New Project */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="projectNameInput">
              <Form.Label>Project Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter project name"
                value={newProjectName}
                onChange={(e) => setNewProjectName(e.target.value)}
              />
            </Form.Group>
            {apiError && <Alert variant="danger" className="mt-3">{apiError}</Alert>}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleCreateProject}
            disabled={!newProjectName.trim()}
          >
            Create Project
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AllProjects;
