import React, { useState } from "react";

const faqData = [
  {
    question: "How to buy quality backlinks?",
    answer:
      "You need to focus on the SEO metrics like domain authority (DA), organic traffic, site age, etc. Then check the relevance of the site with your niche. Finally, make sure you buy links from a trusted seller. In LinkScope, you can do all these tasks in just a few clicks.",
  },
  {
    question: "Should you buy backlinks?",
    answer:
      "You should buy backlinks if you want to create a decent validity or credibility of your site and content. When your site earns a stack of referral domains, it’ll automatically rank in SERPs.",
  },
  {
    question: "Is it safe to buy backlinks?",
    answer:
      "Only when you are able to make the deal with a reliable source. Our system filters and removes spammy or low quality backlinks at primary stages. Users only see the authentic links with updated data. We try to provide competitive deals, but not cheap backlinks that will reduce SEO score.",
  },
  {
    question: "Do backlinks help SEO?",
    answer:
      "To earn authoritativeness in the eyes of crawlers, websites must have backlinks from authority sites. The more a domain gets quality backlinks, the better it will rank or perform.",
  },
  {
    question: "How much do backlinks cost?",
    answer:
      "The price on one backlink depends on which site is giving you the link, how authoritative the site is, and how relevant the site is with your platform. Here in our marketplace, the price of backlinks start from 5$. It can sometimes exceed 500$ depending on the quality.",
  },
];

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="container my-5">
      <div className="row">
        {/* Left Column */}
        <div className="col-md-6">
          <h2 className="mb-4 subheader">Frequently Asked Questions</h2>
        </div>
        {/* Right Column */}
        <div className="col-md-6">
          <div className="accordion">
            {faqData.map((item, index) => (
              <div className="accordion-item mb-3" key={index}>
                <div
                  className="accordion-header d-flex justify-content-between align-items-center p-3 border rounded"
                  style={{
                    cursor: "pointer",
                    backgroundColor: activeIndex === index ? "#f8f9fa" : "white",
                  }}
                  onClick={() => toggleAnswer(index)}
                >
                  <h5 className="mb-0">{item.question}</h5>
                  <span>
                    {activeIndex === index ? (
                      <span>&#x2212; {/* Minus Sign */}</span>
                    ) : (
                      <span>&#x2b; {/* Plus Sign */}</span>
                    )}
                  </span>
                </div>
                {activeIndex === index && (
                  <div className="accordion-body p-3 mt-2 border rounded">
                    {item.answer}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQSection;