import React, { useState, useEffect } from "react";

const TransactionList = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {

    const fetchBalances = async () => {
        const accessToken = localStorage.getItem("token"); // Get token from localStorage
        if (!accessToken) {
          console.error("Access token is missing!");
          return;
        }
  
        const userid = localStorage.getItem("user"); // Get user ID from localStorage
        if (!userid) {
          console.error("User ID is missing!");
          return;
        }
  
        const url = `https://lionfish-app-nh929.ondigitalocean.app/wallet/${userid}/`;
  
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${accessToken}`);
  
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
  
        try {
          const response = await fetch(url, requestOptions);
          if (response.ok) {
            const data = await response.json();
            const { main_balance, referral_balance } = data;
  
            // Save to localStorage
            localStorage.setItem("main_balance", main_balance);
            localStorage.setItem("referral_balance", referral_balance);
            
          } else {
            console.error("Failed to fetch balances:", response.statusText);
          }
        } catch (error) {
          console.error("Error fetching balances:", error);
        }
      };





    const fetchTransactions = async () => {
      const myHeaders = new Headers();
      const token = localStorage.getItem("token"); // Get token from localStorage
      if (!token) {
        console.error("Access token is missing!");
        return;
      }

      const userid = localStorage.getItem("user"); 
    console.log(userid);// Get token from localStorage
    if (!userid) {
      console.error("user is missing!");
      return;
    }
    const url = `https://lionfish-app-nh929.ondigitalocean.app/wallet/${userid}/transactions/`;
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch( 
            url,requestOptions
        );
        if (response.ok) {
          const result = await response.json();
          setTransactions(result); // Set transactions from the API response
        } else {
          setError("No Transactions.");
        }
      } catch (error) {
        console.error("Error:", error);
        setError("An error occurred while fetching transactions.");
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
    fetchBalances(); // Fetch balances

  }, []);

  return (
    <div style={{ maxWidth: "800px", margin: "0 auto", padding: "1rem" }}>
      <h2>Transaction History</h2>
      {loading ? (
        <p>Loading transactions...</p>
      ) : error ? (
        <p style={{ color: "red" }}>{error}</p>
      ) : transactions.length === 0 ? (
        <p>No transactions found.</p>
      ) : (
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "1rem",
          }}
        >
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Transaction ID</th>
              <th style={tableHeaderStyle}>User</th>
              <th style={tableHeaderStyle}>Amount</th>
              <th style={tableHeaderStyle}>Type</th>
              <th style={tableHeaderStyle}>Date</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => (
              <tr key={transaction.transaction_id}>
                <td style={tableCellStyle}>{transaction.transaction_id}</td>
                <td style={tableCellStyle}>{transaction.user}</td>
                <td style={tableCellStyle}>${transaction.amount.toFixed(2)}</td>
                <td style={tableCellStyle}>{transaction.type}</td>
                <td style={tableCellStyle}>
                  {new Date(transaction.date).toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

const tableHeaderStyle = {
  border: "1px solid #ddd",
  padding: "8px",
  backgroundColor: "#f2f2f2",
  textAlign: "left",
};

const tableCellStyle = {
  border: "1px solid #ddd",
  padding: "8px",
  textAlign: "left",
};

export default TransactionList;
