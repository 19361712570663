import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Dashboard from './pages/Dashboard.js';
import RegisterPage from './pages/Register.js';
import LoginPage from './pages/Signin.js';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import BalancePage from './pages/BalancePage.js';
import ResellerDashboard from './pages/ResellerDashboard.js';
import MyListingsPage from './pages/reseller/MyListingPage.js'
import AdminDashboard from './pages/AdminDashboard.js';
import SeeListingpage from './pages/admin/SeeListingPage.js';
import ApprovedListingPage from './pages/admin/ApprovedListingPage.js';
import PendingListingPage from './pages/admin/PendingListingPage.js';
import AllUserPage from './pages/admin/AllUserPage.js';
import Home from './pages/Home.js';
import MailVerification from './pages/MailVerification.js';
import PasswordReset from './pages/ResetPassword.js';
import OrderPage from './pages/AllOrders.js';
import OrderDetailsPage from './pages/OrderDetailsPage.js';
import ProfilePage from './pages/ProfilePage.js';
import RejectListingPage from './pages/admin/RejectListingPage.js';
import AdminWithdrawPage from './pages/admin/WithdrawReqPage.js';
import ProjectPage from './pages/ProjectPage.js';
import SingleProject from './pages/ProjectsSinglePage.js';
import TeamPage from './pages/TeamPage.js';
import ListingPage from './pages/ListingPage.js';
import AllIncomingOrders from './pages/admin/OrdersPage.js';
import ProjectOrderDetailsPage from './pages/ProjectOrderDetailsPage.js';
import NewListingPage from './pages/reseller/NewListingPage.js';
import VerifyNewListing from './pages/reseller/VerifyNewListing.js';
import BulkListingPage from './pages/admin/BulkListingPage.js';

import CategoriesPage from './pages/admin/CategoriesPage.js';
import SingleCategoryPage from './pages/admin/SingleCategory.js';
import CategoryPublicPage from './pages/CategoryPublicPage.js';
import AdminCouponPage from './pages/admin/AdminCouponPage.js';
import AllMyOrders from './pages/reseller/AllMyOrders.js';
import AboutPage from './pages/AboutPage.js';
import FavortiePage from './pages/FavoritePage.js';
import ContactPage from './pages/ContactPage.js';
import CompletedOrderPage from './pages/CompletedOrderPage.js';
import RejectedOrderPage from './pages/RejectedOrderPage.js';
import PendingOrdersPage from './pages/PendingOrdersPage.js';
import ActiveOrderPage from './pages/ActiveOrderPage.js';
import MyOrderSingle from './pages/reseller/MyOrderSingle.js';
import About from './components/home/About.js';
function App() {
  return (
    <AuthProvider>
    <Router>
      <div className="App">
      <Routes>

      <Route path="/" element={
                     
                                <Home />
                        
                        } 
                    />
      <Route path="/about" element={
                     
                     <AboutPage />
             
             } 
         />

<Route path="/contact" element={
                     
                     <ContactPage />
             
             } 
         />

      <Route path="/dashboard" element={
                            <PrivateRoute>
                                <Dashboard />
                            </PrivateRoute>
                        } 
                    />
        <Route path="/favorite" element={
                            <PrivateRoute>
                                <FavortiePage />
                            </PrivateRoute>
                        } 
                    />

                    
      <Route path="/listing" element={
                            <PrivateRoute>
                                <ListingPage />
                            </PrivateRoute>
                        } 
                    />

<Route path="/admin/dashboard" element={
                            <PrivateRoute>
                                <AdminDashboard />
                            </PrivateRoute>
                        } 
                    />

<Route path="/admin/allusers" element={
                            <PrivateRoute>
                                <AllUserPage />
                            </PrivateRoute>
                        } 
                    />
<Route path="/admin/orders" element={
                            <PrivateRoute>
                                <AllIncomingOrders />
                            </PrivateRoute>
                        } 
                    />
<Route path="/admin/listings" element={
                            <PrivateRoute>
                                <SeeListingpage />
                            </PrivateRoute>
                        } 
                    />

<Route path="/admin/listings/approved" element={
                            <PrivateRoute>
                                <ApprovedListingPage />
                            </PrivateRoute>
                        } 
                    />


<Route path="/admin/listings/rejected" element={
                            <PrivateRoute>
                                <RejectListingPage />
                            </PrivateRoute>
                        } 
                    />

<Route path="/admin/listings/pending" element={
                            <PrivateRoute>
                                <PendingListingPage />
                            </PrivateRoute>
                        } 
                    />
<Route path="/admin/listings/bulk" element={
                            <PrivateRoute>
                                <BulkListingPage />
                            </PrivateRoute>
                        } 
                    />

<Route path="/admin/balance/" element={
                            <PrivateRoute>
                                <AdminWithdrawPage />
                            </PrivateRoute>
                        } 
                    />      
<Route path="/admin/coupon/" element={
                            <PrivateRoute>
                                <AdminCouponPage />
                            </PrivateRoute>
                        } 
                    />  
 <Route path="/admin/niche/" element={
                            <PrivateRoute>
                                <CategoriesPage />
                            </PrivateRoute>
                        } 
                    />     
  <Route path="/admin/niche/:nichename" element={
                            <PrivateRoute>
                                <SingleCategoryPage />
                            </PrivateRoute>
                        } 
                    />                      
                                   
                                  
<Route path="/dashboard/reseller" element={
                            <PrivateRoute>
                                <ResellerDashboard />
                            </PrivateRoute>
                        } 
                    />


<Route path="/mylistings" element={
                            <PrivateRoute>
                                <MyListingsPage />
                            </PrivateRoute>
                        } 
                    />
<Route path="/incomingorders" element={
                            <PrivateRoute>
                                <AllMyOrders />
                            </PrivateRoute>
                        } 
                    />
<Route path="/incomingorder/:orderId" element={
                            <PrivateRoute>
                                <MyOrderSingle />
                            </PrivateRoute>
                        } 
                    />
<Route path="/newlisting" element={
                            <PrivateRoute>
                                <NewListingPage />
                            </PrivateRoute>
                        } 
                    />

<Route path="/verifylisting/:listing_id" element={
                            <PrivateRoute>
                                <VerifyNewListing />
                            </PrivateRoute>
                        } 
                    />


<Route path="/balance" element={
                            <PrivateRoute>
                                <BalancePage />
                            </PrivateRoute>
                        } 
                    />

<Route path="/orders" element={
                            <PrivateRoute>
                                <OrderPage />
                            </PrivateRoute>
                        } 
                    />


<Route path="/pendingorders" element={
                            <PrivateRoute>
                                <PendingOrdersPage />
                            </PrivateRoute>
                        } 
                    />


<Route path="/completedorders" element={
                            <PrivateRoute>
                                <CompletedOrderPage />
                            </PrivateRoute>
                        } 
                    />
<Route path="/rejectedorders" element={
                            <PrivateRoute>
                                <RejectedOrderPage />
                            </PrivateRoute>
                        } 
                    />

<Route path="/activeorders" element={
                            <PrivateRoute>
                                <ActiveOrderPage />
                            </PrivateRoute>
                        } 
                    />

<Route path="/order/:orderId" element={
                            <PrivateRoute>
                                <OrderDetailsPage />
                            </PrivateRoute>
                        } 
                    />
<Route path="/profile/" element={
                            <PrivateRoute>
                                <ProfilePage />
                            </PrivateRoute>
                        } 
                    />

<Route path="/projects/" element={
                            <PrivateRoute>
                                <ProjectPage />
                            </PrivateRoute>
                        } 
                    />
<Route path="/projects/:projectId" element={
                            <PrivateRoute>
                                <SingleProject />
                            </PrivateRoute>
                        } 
                    />
<Route path="/projects/:projectId/:orderId" element={
                            <PrivateRoute>
                                <ProjectOrderDetailsPage />
                            </PrivateRoute>
                        } 
                    />
<Route path="projects/team/" element={
                            <PrivateRoute>
                                <TeamPage />
                            </PrivateRoute>
                        } 
                    />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/signin" element={<LoginPage />} />
      <Route path="/verification" element={<MailVerification />} />
      <Route path="/forgot" element={<PasswordReset />} />
      <Route path="/category/:nichename" element={
                     
                                <CategoryPublicPage />
                 
                        } 
                    />

        </Routes>
        </div>
        </Router>
        </AuthProvider>

 
  );
}

export default App;
