import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

const CategoryHeaderFooter = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [headerText, setHeaderText] = useState("");
  const [footerText, setFooterText] = useState("");
  const { nichename } = useParams();

  const accesskey = localStorage.getItem("token");
  const navigate = useNavigate();

  // Fetch Data Function
  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://lionfish-app-nh929.ondigitalocean.app/header-footer/${nichename}/`,
        {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${accesskey}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 404) {
        // If the API returns 404, set default text
        setData({});
        setHeaderText("Enter Text or Html");
        setFooterText("Enter Text or Html");
        setLoading(false);
        return;
      }

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const result = await response.json();
      setData(result);
      setHeaderText(result.header_text || "Enter Text or Html");
      setFooterText(result.footer_text || "Enter Text or Html");
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  // Run fetchData when component loads
  useEffect(() => {
    fetchData();
  }, [nichename]);

  // Handle Submit (Update API and Refresh Data)
  const handleSubmit = async () => {
    try {
      const response = await fetch(
        `https://lionfish-app-nh929.ondigitalocean.app/header-footer/${nichename}/`,
        {
          method: "POST",
          headers: {
            "Authorization": `Bearer ${accesskey}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            header_text: headerText,
            footer_text: footerText,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      await response.json();
      setShowModal(false); // Close the modal
      fetchData(); // Refresh the data after updating

    } catch (error) {
      alert("Failed to update data: " + error.message);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <div className="row">
   
        <h2>Category Name: {data.category}</h2>
        <Button className="btn btn-primary mainbutton mx-auto my-2"   style={{ maxWidth: "400px" }}    onClick={() => setShowModal(true)}>
          Edit Header & Footer Text
        </Button>

        <h3>This is Header Text or HTML</h3>

      <div className="col-md-8 filter mx-auto">
   
        <div dangerouslySetInnerHTML={{ __html: data.header_text }} />


      </div>
      <h3>This is Footer Text or HTML</h3>


      <div className="col-md-8 filter mx-auto">
   
   <div dangerouslySetInnerHTML={{ __html: data.footer_text }} />

 </div>

      </div>
      {/* Bootstrap Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Header & Footer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Header Text</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={headerText}
                onChange={(e) => setHeaderText(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Footer Text</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={footerText}
                onChange={(e) => setFooterText(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CategoryHeaderFooter;
