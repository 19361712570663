import React from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const refreshToken = localStorage.getItem("refresh_token"); // Get refresh token from localStorage
      const accessToken = localStorage.getItem("token"); // Get access token from localStorage
      console.log("Refresh Token:", refreshToken); // Debugging log
      if (!refreshToken) {
        console.error("No refresh token found!");
        clearLocalStorage();
        navigate("/signin"); // Redirect to login page
        return;
      }

      // Set up headers for the API request, including Authorization token
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken}`); // Attach the access token

      // Prepare the body for the logout request
      const raw = JSON.stringify({
        refresh: refreshToken,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      // Making the logout API request
      const response = await fetch("https://lionfish-app-nh929.ondigitalocean.app/auth/logout/", requestOptions);

      if (response.ok) {
        console.log("Logout successful");
        clearLocalStorage(); // Clear local storage
        window.location.reload(); // Reload the page
      } else {
        console.error("Logout failed:", response.statusText);
        clearLocalStorage(); // Clear storage even if logout fails
        window.location.reload(); // Reload the page
      }
    } catch (error) {
      console.error("Error during logout:", error);
      clearLocalStorage();
      window.location.reload(); // Reload the page in case of an error
    }
  };

  const clearLocalStorage = () => {
    localStorage.clear(); // Clear all localStorage data
  };

  return (
    <button onClick={handleLogout} className="btn primarybutton">
      Logout
    </button>
  );
};

export default Logout;
