import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { Button, Modal, Form } from "react-bootstrap";

const CouponAdmin = () => {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const [newCoupon, setNewCoupon] = useState({ name: "", type: "Percentage", code: "", amount: "" });

  const accessKey = localStorage.getItem('token');

  const fetchCoupons = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessKey}`);
    myHeaders.append("Content-Type", "application/json");

    try {
      const response = await fetch("https://lionfish-app-nh929.ondigitalocean.app/coupons/", {
        method: "GET",
        headers: myHeaders
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const result = await response.json();
      setCoupons(result.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCoupons();
  }, []);

  const handleCreateCoupon = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessKey}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(newCoupon);

    try {
      const response = await fetch("https://lionfish-app-nh929.ondigitalocean.app/create-coupons/", {
        method: "POST",
        headers: myHeaders,
        body: raw
      });

      if (!response.ok) {
        throw new Error("Failed to create coupon");
      }

      setMessage("Coupon created successfully");
      setTimeout(() => setMessage(""), 2000);
      setShowModal(false);
      fetchCoupons();
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className=" mx-auto">
      {message && <div className="alert alert-success">{message}</div>}
      <div className="row">
        <div className="col-md-6">      <h2>Available Coupons</h2>
        </div>
        <div className="col-md-6">      <Button onClick={() => setShowModal(true)} className="btn btn-primary mainbutton">Create New Coupon</Button>
        </div>

      </div>
    
      <div className="filter mx-auto col-md-8">
      <Table striped bordered hover >
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Code</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {coupons.map((coupon, index) => (
            <tr key={index}>
              <td>{coupon.name}</td>
              <td>{coupon.type}</td>
              <td>{coupon.code}</td>
              <td>{coupon.amount}%</td>
            </tr>
          ))}
        </tbody>
      </Table>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" onChange={(e) => setNewCoupon({ ...newCoupon, name: e.target.value })} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Type</Form.Label>
              <Form.Control as="select" onChange={(e) => setNewCoupon({ ...newCoupon, type: e.target.value })}>
                <option value="Percentage">Percentage</option>
                <option value="Fixed">Fixed</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Code</Form.Label>
              <Form.Control type="text" onChange={(e) => setNewCoupon({ ...newCoupon, code: e.target.value })} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Amount</Form.Label>
              <Form.Control type="number" onChange={(e) => setNewCoupon({ ...newCoupon, amount: e.target.value })} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
          <Button variant="primary" onClick={handleCreateCoupon}>Create</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CouponAdmin;
