import React, { useState } from "react";

const WithdrawRequest = ({ closeModal }) => {
  const [amount, setAmount] = useState("");
  const [paymentAddress, setPaymentAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const mainBalance = parseFloat(localStorage.getItem("main_balance")) || 0;

  const handleWithdrawRequest = async (e) => {
    e.preventDefault();
    setResponseMessage("");

    if (!paymentAddress.trim()) {
      setResponseMessage("Please enter a valid payment address");
      return;
    }

    if (parseFloat(amount) > mainBalance) {
      setResponseMessage("You don't have enough balance to make this withdrawal.");
      return;
    }

    setLoading(true);

    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      amount: parseFloat(amount),
      payment_address: paymentAddress.trim()
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://lionfish-app-nh929.ondigitalocean.app/withdraw/request/",
        requestOptions
      );

      if (response.ok) {
        const result = await response.json();
        setResponseMessage("Withdrawal request submitted successfully.");
        setAmount("");
        setPaymentAddress("");

        const updatedBalance = mainBalance - parseFloat(amount);
        localStorage.setItem("main_balance", updatedBalance.toFixed(2));

        if (closeModal) closeModal();
      } else {
        const errorData = await response.json();
        setResponseMessage(
          errorData?.message || "Failed to submit withdrawal request."
        );
      }
    } catch (error) {
      console.error("Error:", error);
      setResponseMessage("An error occurred while making the request.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: "400px", margin: "0 auto", padding: "1rem" }}>
      <h2>Withdraw Request</h2>

      <form onSubmit={handleWithdrawRequest}>
        <div>
          <label
            htmlFor="amount"
            style={{ display: "block", marginBottom: "0.5rem" }}
          >
            Enter Amount
          </label>
          <input
            type="number"
            id="amount"
            name="amount"
            placeholder="Enter withdrawal amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            style={{
              width: "100%",
              padding: "0.5rem",
              marginBottom: "1rem",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
            required
          />
        </div>

        <div>
          <label
            htmlFor="paymentAddress"
            style={{ display: "block", marginBottom: "0.5rem" }}
          >
            Payment Address
          </label>
          <input
            type="text"
            id="paymentAddress"
            name="payment_address"
            placeholder="Enter wallet address"
            value={paymentAddress}
            onChange={(e) => setPaymentAddress(e.target.value)}
            style={{
              width: "100%",
              padding: "0.5rem",
              marginBottom: "1rem",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
            required
          />
        </div>

        <button
          type="submit"
          disabled={loading}
          style={{
            width: "100%",
            padding: "0.5rem",
            backgroundColor: loading ? "#ccc" : "#007BFF",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            cursor: loading ? "not-allowed" : "pointer",
          }}
        >
          {loading ? "Processing..." : "Submit Request"}
        </button>
      </form>

      {responseMessage && (
        <p
          style={{
            marginTop: "1rem",
            color: responseMessage.includes("successfully") ? "green" : "red",
          }}
        >
          {responseMessage}
        </p>
      )}
    </div>
  );
};

export default WithdrawRequest;