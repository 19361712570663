import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { 
  OverviewIcon, 
  LinkIcon, 
  BrowserIcon, 
  WalletIcon, 
  ChatIcon, 
  UserIcon, 
  SigninIcon 
} from "../helper/icons";
import Logout from "./Logout";
import linkscopelogo from "../static/images/logo.png";

const VerticalMenu = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [expandedSubmenu, setExpandedSubmenu] = useState(null); // Tracks expanded submenu
  const location = useLocation(); // React Router location hook for active state

  const toggleMenu = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleSubmenu = (submenuKey) => {
    setExpandedSubmenu((prevKey) => (prevKey === submenuKey ? null : submenuKey));
  };

  const isSelected = (path) => location.pathname === path;
  const isSubmenuSelected = (submenu) =>
    submenu.some((item) => isSelected(item.href));

  return (
    <div
      style={{
        height: "100vh",
        width: isCollapsed ? "80px" : "250px",
        transition: "width 0.3s",
        overflow: "hidden",
        whiteSpace: "nowrap",
        boxShadow: "2px 0 5px rgba(0,0,0,0.1)",
      }}
      className="d-flex flex-column vertical"
    >
      {/* Header Section with Toggle Button */}
      <div
        style={{
          display: "flex",
          justifyContent: isCollapsed ? "center" : "space-between",
          alignItems: "center",
          height: "60px",
          borderBottom: "1px solid #43434C",
          cursor: "pointer",
          padding: isCollapsed ? "0 10px" : "0 20px",
        }}
      >
        <div className="tog" onClick={toggleMenu}>
          <FaBars size={20} />
        </div>
        {!isCollapsed && (
       <a href="/dashboard" style={{ textDecoration: "none" }}>
       <div
         className="headerlogo"
         style={{
           fontSize: "16px",
           fontWeight: "bold",
           marginLeft: "10px",
         }}
       >
         <img src={linkscopelogo} alt="Logo" />
       </div>
     </a>
        )}
      </div>

      <Nav className="flex-column">
        {/* Links with Submenu Support */}
        {[
          { href: "/dashboard", icon: <OverviewIcon width={20} height={20} />, label: "Dashboard" },
          {
            href: "/listing",
            icon: <LinkIcon width={20} height={20} />,
            label: "Buy Backlinks",
            submenu: [
              { href: "/listing/", label: "All Backlinks" },
              { href: "/favorite", label: "Favorite Backlinks" },
            ],
           
          },
          {
            href: "/orders",
            icon: <LinkIcon width={20} height={20} />,
            label: "Orders",
            submenu: [
              { href: "/orders/", label: "All Orders" },
              { href: "/activeorders/", label: "Active Orders" },
              { href: "/pendingorders/", label: "Pending Orders" },
              { href: "/completedorders/", label: "Completed Orders" },
              { href: "/rejectedorders/", label: "Rejected Orders" },

            ],
          },
          {
            href: "/projects",
            icon: <BrowserIcon width={20} height={20} />,
            label: "Projects",
            submenu: [
              { href: "/projects/", label: "All Projects" },
              { href: "/projects/team", label: "Teams" },
            ],
          },
          { href: "/balance", icon: <WalletIcon width={20} height={20} />, label: "Balance" },
          { href: "/profile", icon: <UserIcon width={20} height={20} />, label: "Profile" },
        ].map(({ href, icon, label, submenu }, index) => (
          <div key={href}>
            <Nav.Link
              href={!submenu ? href : undefined}
              className={`text-light ${isSelected(href) ? "selected" : ""}`}
              onClick={submenu ? () => toggleSubmenu(index) : undefined}
            >
              <div
                className="navitem"
                style={{
                  width: "100%",
                  height: "100%",
                  padding: "13px 20px",
                  borderRadius: 30,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: isCollapsed ? "center" : "flex-start",
                  gap: isCollapsed ? 0 : 12,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 24,
                    height: 24,
                  }}
                >
                  {icon}
                </div>
                {!isCollapsed && (
                  <span
                    style={{
                      fontSize: 16,
                      fontFamily: "Inter",
                      fontWeight: 500,
                      lineHeight: "26px",
                    }}
                  >
                    {label}
                  </span>
                )}
              </div>
            </Nav.Link>

            {/* Submenu */}
            {submenu &&
              (expandedSubmenu === index || isSubmenuSelected(submenu)) && (
                <div
                  style={{
                    marginLeft: isCollapsed ? 0 : "20px",
                    paddingLeft: "10px",
                  }}
                >
                  {submenu.map(({ href, label }) => (
                    <Nav.Link
                      href={href}
                      key={href}
                      className={`text-light ${
                        isSelected(href) ? "selected" : ""
                      }`}
                    >
                      <div
                        className="submenu-item"
                        style={{
                          padding: "8px 20px",
                          borderRadius: 30,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: isCollapsed ? "center" : "flex-start",
                        }}
                      >
                        {!isCollapsed && (
                          <span
                            style={{
                              fontSize: 14,
                              fontFamily: "Inter",
                              fontWeight: 400,
                              lineHeight: "22px",
                              color: "#b0b0b0",
                            }}
                          >
                            {label}
                          </span>
                        )}
                      </div>
                    </Nav.Link>
                  ))}
                </div>
              )}
          </div>
        ))}


      </Nav>
    </div>
  );
};

export default VerticalMenu;
