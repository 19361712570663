import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const MailVerification = () => {
  const navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState("");
  const [message, setMessage] = useState(null); // State to track success/failure message
  const [isSuccess, setIsSuccess] = useState(false); // State to determine message type (success or error)

  const handleChange = (e) => {
    setVerificationCode(e.target.value);
    setMessage(null); // Clear message when user starts typing
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = `https://lionfish-app-nh929.ondigitalocean.app/auth/verify-email/${verificationCode}/`;

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();

      if (response.ok) {
        setMessage(data.message || "Email verified successfully!");
        setIsSuccess(true); // Indicate success
        setTimeout(() => navigate("/signin"), 2000); // Redirect after 2 seconds
      } else {
        setMessage(data.message || "Verification failed. Please try again.");
        setIsSuccess(false); // Indicate error
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("An error occurred. Please try again later.");
      setIsSuccess(false); // Indicate error
    }
  };

  return (
    <div className="container-fluid vh-100 d-flex">
      {/* Left Section */}
      <div className="col-md-6 d-flex flex-column align-items-center justify-content-center bg-light">
        <div className="mb-4">
          <a href="/" className="text-decoration-none">
            Back to Home
          </a>
        </div>
        <div className="w-75">
          <h2 className="mb-4">Email Verification</h2>
          <p>Please check your email inbox and enter the verification code below to continue.</p>
          <hr />

          <form onSubmit={handleSubmit}>
            <div className="mb-3 form-group">
              <label className="form-label">Verification Code*</label>
              <input
                type="text"
                name="verificationCode"
                value={verificationCode}
                onChange={handleChange}
                className="form-control"
                placeholder="Enter your verification code"
                required
              />
              {/* Render success or error message here */}
              {message && (
                <div
                  className={`mt-2 ${
                    isSuccess ? "text-success" : "text-danger"
                  }`}
                >
                  {message}
                </div>
              )}
            </div>

            <div className="d-flex justify-content-between mt-4">
              <button type="submit" className="btn primarybutton">
                Verify
              </button>
            </div>
          </form>
        </div>
        <footer className="d-flex align-items-end mt-5">© 2025 All rights reserved to LinkScope</footer>
      </div>

      {/* Right Section */}
      <div className="col-md-6 d-none d-md-flex align-items-end justify-content-center bg-secondary text-white">
        <div className="footer-links d-flex justify-content-between p-5">
          <a href="/contact">Contact</a>
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="/terms">Terms of Use</a>
          <a href="/blog">Blog</a>
        </div>
      </div>
    </div>
  );
};

export default MailVerification;
