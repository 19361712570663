import React, { useState, useEffect, useRef } from 'react';
import {
  Elements,
  CardElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

const CheckoutForm = ({ amount, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState('');
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentVerified, setPaymentVerified] = useState(false);
  const [error, setError] = useState('');
  const timeoutRef = useRef(null);

  // Clear timeout on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const createPaymentIntent = async () => {
      try {
        const response = await axios.post(
          'https://lionfish-app-nh929.ondigitalocean.app/payments/create-intent/',
          { amount: amount },
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        setClientSecret(response.data.clientSecret);
      } catch (err) {
        setError(err.response?.data?.error || 'Error creating payment intent');
      }
    };

    if (amount > 0) {
      createPaymentIntent();
    }
  }, [amount]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPaymentProcessing(true);
    setError('');
    setPaymentSuccess(false);
    setPaymentVerified(false);

    if (!stripe || !elements) return;

    try {
      const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement),
          }
        }
      );

      if (stripeError) {
        setError(stripeError.message);
        setPaymentProcessing(false);
        return;
      }

      if (paymentIntent.status === 'succeeded') {
        setPaymentSuccess(true);
        
        try {
          await axios.get(
            `https://lionfish-app-nh929.ondigitalocean.app/payments/status/${paymentIntent.id}/`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
          );
          
          setPaymentVerified(true);
          
          // Delay closing the modal for 3 seconds after verification
          timeoutRef.current = setTimeout(() => {
            if (onSuccess) onSuccess();
          }, 3000);

        } catch (err) {
          setError('Payment verification failed - please contact support');
          setPaymentSuccess(false);
          setPaymentVerified(false);
        }
      }
    } catch (err) {
      setError('Payment failed. Please try again.');
      setPaymentSuccess(false);
      setPaymentVerified(false);
    }
    setPaymentProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement
        options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              '::placeholder': { color: '#aab7c4' }
            },
            invalid: { color: '#9e2146' }
          }
        }}
      />
      
      {error && (
        <div className="alert alert-danger mt-2">
          {error}
          <div className="mt-2">
            <small>
              If this persists, please contact support at support@linkscope.com
            </small>
          </div>
        </div>
      )}
      
      {!error && paymentSuccess && !paymentVerified && (
        <div className="alert alert-info mt-2">
          Payment received by Stripe - verifying with system...
        </div>
      )}
      
      {!error && paymentVerified && (
        <div className="alert alert-success mt-2">
          Payment completed and verified! 🎉
          <div className="mt-2">
            <small>This window will close automatically in 3 seconds...</small>
          </div>
        </div>
      )}

      <button 
        type="submit" 
        className="btn btn-primary w-100 mt-3"
        disabled={!stripe || paymentProcessing || paymentVerified}
      >
        {paymentProcessing ? 'Processing...' : paymentVerified ? 'Payment Complete' : `Pay $${amount}`}
      </button>
    </form>
  );
};

const StripePayment = ({ amount, onSuccess }) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm amount={amount} onSuccess={onSuccess} />
  </Elements>
);

export default StripePayment;