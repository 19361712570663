import React, { useState, useEffect } from "react";

const PendingWithdraws = () => {
  const [withdrawRequests, setWithdrawRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchWithdrawRequests = async () => {
      setLoading(true);
      setError("");
      const myHeaders = new Headers();
      const token = localStorage.getItem("token"); // Get token from localStorage
      if (!token) {
        console.error("Access token is missing!");
        return;
      }

      const userid = localStorage.getItem("user"); 
    console.log(userid);// Get token from localStorage
    if (!userid) {
      console.error("user is missing!");
      return;
    }
    const url = `https://lionfish-app-nh929.ondigitalocean.app/withdraw/request/${userid}/`;
   //const url = `http://127.0.0.1:8000/withdraw/request/${userid}/`;

      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch(
       url,
          requestOptions
        );
        if (response.ok) {
          const result = await response.json();
          const pendingRequests = result.withdraw_requests.filter(
            (request) => request.status === "Pending"
          );
          setWithdrawRequests(pendingRequests);
        } else {
          setError("No withdraw request pending");
        }
      } catch (error) {
        console.error(error);
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchWithdrawRequests();
  }, []);

  return (
    <div style={{ padding: "1rem", maxWidth: "100%", overflowX: "auto" }}>
      <h2>Withdrawal Requests</h2>

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p style={{ color: "red" }}>{error}</p>
      ) : withdrawRequests.length > 0 ? (
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "1rem",
          }}
        >
          <thead>
            <tr style={{ backgroundColor: "#f2f2f2", textAlign: "left" }}>
              <th style={styles.th}>Request ID</th>
              <th style={styles.th}>Amount</th>
              <th style={styles.th}>Status</th>
              <th style={styles.th}>Date Created</th>
              <th style={styles.th}>User</th>
            </tr>
          </thead>
          <tbody>
            {withdrawRequests.map((request) => (
              <tr key={request.request_id} style={styles.tr}>
                <td style={styles.td}>{request.request_id}</td>
                <td style={styles.td}>${request.amount.toFixed(2)}</td>
                <td style={styles.td}>{request.status}</td>
                <td style={styles.td}>
                  {new Date(request.date_created).toLocaleString()}
                </td>
                <td style={styles.td}>{request.user}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No withdrawal requests found.</p>
      )}
    </div>
  );
};

const styles = {
  th: {
    padding: "12px",
    border: "1px solid #ddd",
    fontWeight: "bold",
  },
  td: {
    padding: "12px",
    border: "1px solid #ddd",
  },
  tr: {
    borderBottom: "1px solid #ddd",
  },
};

export default PendingWithdraws;
