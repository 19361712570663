import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button"; // Assuming you want to use Bootstrap's Button component

const CategoriesAll = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const accesskey = localStorage.getItem('token');
  const navigate = useNavigate(); // Initialize useNavigate hook

  useEffect(() => {
    const fetchData = async () => {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${accesskey}` // Use backticks for string interpolation
      );

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch("https://lionfish-app-nh929.ondigitalocean.app/categories/all/", requestOptions);
        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
        const result = await response.json();
        
        // Set the data
        setData(result);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // Render the categories with buttons for each niche
  return (
    <div>
      <div className="row">
        <h2>Available Categories</h2>
        {data.niches && data.niches.map((niche, index) => (
          <div key={index} className="mb-3">
            <Button
              variant="primary"
              onClick={() => navigate(`/admin/niche/${niche}`)} // Navigate to the niche page
            >
              {niche} {/* Directly use the string value */}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoriesAll;
