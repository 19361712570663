import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'; // Import CSS for versions before v5.13.0
import Pagination from "react-bootstrap/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

const AdminWithdrawrequest = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const listingsPerPage = 10;
  const [filter, setFilter] = useState("All"); // State to manage the filter

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastListing = currentPage * listingsPerPage;
  const indexOfFirstListing = indexOfLastListing - listingsPerPage;
  const currentListings = filteredData.slice(indexOfFirstListing, indexOfLastListing);

  const accesskey = localStorage.getItem('token');

  const handleApprove = async (requestId) => {
    try {
      const response = await fetch(
        `https://lionfish-app-nh929.ondigitalocean.app/withdraw/request/${requestId}/approve/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accesskey}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error approving request: ${response.status} - ${response.statusText}`);
      }

      alert(`Request ${requestId} approved successfully!`);

      // Update the UI to reflect changes
      setData(
        data.map((item) =>
          item.request_id === requestId ? { ...item, status: "Approved" } : item
        )
      );
    } catch (error) {
      console.error(error);
      alert("Failed to approve the request. Please try again.");
    }
  };

  const handleReject = async (requestId) => {
    try {
      const response = await fetch(
        `https://lionfish-app-nh929.ondigitalocean.app/withdraw/request/${requestId}/reject/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accesskey}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error rejecting request: ${response.status} - ${response.statusText}`);
      }

      alert(`Request ${requestId} rejected successfully!`);

      // Update the UI to reflect changes
      setData(
        data.map((item) =>
          item.request_id === requestId ? { ...item, status: "Rejected" } : item
        )
      );
    } catch (error) {
      console.error(error);
      alert("Failed to reject the request. Please try again.");
    }
  };

  const applyFilter = (filter) => {
    setFilter(filter);
    if (filter === "All") {
      setFilteredData(data);
    } else {
      setFilteredData(data.filter((item) => item.status === filter));
    }
    setCurrentPage(1); // Reset to the first page after applying a filter
  };

  useEffect(() => {
    const fetchData = async () => {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${accesskey}`);
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          "https://lionfish-app-nh929.ondigitalocean.app/withdraw/all/request/",
          requestOptions
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const result = await response.json();
        console.log(result);
        setData(result);
        setFilteredData(result); // Initially show all data
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="table-responsive">
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <h5>Withdraw Requests</h5>
        <DropdownButton
          id="dropdown-basic-button"
          title={`Filter: ${filter}`}
          onSelect={applyFilter}
        >
          <Dropdown.Item eventKey="All">All</Dropdown.Item>
          <Dropdown.Item eventKey="Approved">Approved</Dropdown.Item>
          <Dropdown.Item eventKey="Rejected">Rejected</Dropdown.Item>
          <Dropdown.Item eventKey="Pending">Pending</Dropdown.Item>
        </DropdownButton>
      </div>
      <Table className="table table-transparent">
        <thead>
          <tr>
            <th>Request ID</th>
            <th>User</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Date Created</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentListings.map((listing) => (
            <tr key={listing.request_id}>
              <td>{listing.request_id}</td>
              <td>{listing.user}</td>
              <td>${listing.amount.toFixed(2)}</td>
              <td>{listing.status}</td>
              <td>{new Date(listing.date_created).toLocaleString()}</td>
              <td>
                <div className="action-icons d-flex align-items-center">
                  {/* Approve Action */}
                  <span
                    className="action-icon"
                    data-tooltip-id="approve-tooltip"
                    data-tooltip-content="Approve Request"
                    onClick={() => handleApprove(listing.request_id)}
                  >
                    <FaCheckCircle size={20} color="green" style={{ cursor: "pointer" }} />
                  </span>
                  {/* Reject Action */}
                  <span
                    className="action-icon ms-3"
                    data-tooltip-id="reject-tooltip"
                    data-tooltip-content="Reject Request"
                    onClick={() => handleReject(listing.request_id)}
                  >
                    <FaTimesCircle size={20} color="red" style={{ cursor: "pointer" }} />
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* Add Tooltip Components */}
      <Tooltip id="approve-tooltip" />
      <Tooltip id="reject-tooltip" />

      <Pagination className="justify-content-center">
        {Array.from({ length: Math.ceil(filteredData.length / listingsPerPage) }, (_, i) => (
          <Pagination.Item
            key={i + 1}
            active={i + 1 === currentPage}
            onClick={() => handlePageChange(i + 1)}
          >
            {i + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </div>
  );
};

export default AdminWithdrawrequest;
