import React, { useState } from "react";
import AdminVertical from "../../components/admin/AdminVertical";
import AdminHorizontal from "../../components/admin/AdminHorizontal";
import CouponAdmin from "../../components/admin/CouponAdmin";
const AdminCouponPage = () => {
  const [isVerticalMenuVisible, setVerticalMenuVisible] = useState(false);

  const toggleVerticalMenu = () => {
    setVerticalMenuVisible(!isVerticalMenuVisible);
  };

  return (
    <div className="d-flex">
      {/* Vertical Menu */}
      <div
        className={`vertical-menu-container ${
          isVerticalMenuVisible ? "d-block" : "d-none d-md-block"
        }`}
      >
        <AdminVertical />
      </div>

      {/* Main Content Area */}
      <div style={{ flex: 1 }}>
        {/* Horizontal Menu with Toggle */}
        <AdminHorizontal onToggleVerticalMenu={toggleVerticalMenu} />
        <div className="p-4">
            <CouponAdmin/>
        </div>
      </div>
    </div>
  );
};

export default AdminCouponPage;