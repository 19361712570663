import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import './MessagingStyle.css';

const Messaging = () => {
  const { orderId } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [useremail, setUseremail] = useState(localStorage.getItem("useremail"));
  const user_id = localStorage.getItem("user");

  // Fetch user email on component mount
  useEffect(() => {
    const fetchUserEmail = async () => {
      if (!useremail) {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("Access token is missing!");
          return;
        }

        try {
          const myHeaders = new Headers({
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          });

          const url = `https://lionfish-app-nh929.ondigitalocean.app/users/${user_id}`;
          const response = await fetch(url, {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
          });

          const result = await response.json();

          if (result.email) {
            setUseremail(result.email);
            localStorage.setItem("useremail", result.email);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserEmail();
  }, [user_id, useremail]);

  // Fetch messages for the order
  useEffect(() => {
    const fetchMessages = async () => {
      setLoading(true);
      setError("");

      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Access token is missing!");
        return;
      }

      const url = `https://lionfish-app-nh929.ondigitalocean.app/messages/order/${orderId}/`;
      const myHeaders = new Headers({
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      });

      try {
        const response = await fetch(url, { method: "GET", headers: myHeaders });
        if (response.ok) {
          const result = await response.json();
          setMessages(result);
        } else {
          setError("Failed to fetch messages.");
        }
      } catch (error) {
        setError("An error occurred while fetching messages.");
      } finally {
        setLoading(false);
      }
    };

    fetchMessages();
  }, [orderId]);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Access token is missing!");
      return;
    }

    const url = `https://lionfish-app-nh929.ondigitalocean.app/messages/send/`;
    const myHeaders = new Headers({
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json"
    });

    const body = JSON.stringify({
      order: parseInt(orderId),
      content: newMessage
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: myHeaders,
        body: body
      });
      if (response.ok) {
        setNewMessage("");
        const result = await response.json();
        setMessages((prevMessages) => [...prevMessages, result]);
      } else {
        setError("Failed to send message.");
      }
    } catch (error) {
      setError("An error occurred while sending the message.");
    }
  };

  // Strip unwanted characters from email (leading/trailing spaces, parentheses)
  const cleanEmail = (email) => {
    return email.replace(/[()]/g, "").trim();
  };

  // Handle Enter key press for sending message
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && newMessage.trim()) {
      handleSendMessage();
    }
  };

  return (
    <div className="chat-container">
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : (
        <div className="chat-box">
          <div className="messages-list">
            {messages.length > 0 ? (
              messages.map((message) => {
                const isSender = cleanEmail(message.sender) === cleanEmail(useremail);
                return (
                  <div key={message.id} className={`message-row ${isSender ? "you" : "others"}`}>
                    {isSender ? (
                      <div className="message-bubble-container">
                         <div className="messaginitem">
                        <div className="message-content">{message.content}</div>
                        <div className="message-timestamp">{new Date(message.timestamp).toLocaleString()}</div>
                        </div>
                        <div className="message-name">{message.sender_id}</div>
                      </div>
                    ) : (
                      <div className="others-message">
                       
                        <div className="message-bubble-container">
                        <div className="message-name">{message.sender_id}</div>
                        <div className="messaginitem"><div className="message-content">{message.content}</div>
                        <div className="message-timestamp">{new Date(message.timestamp).toLocaleString()}</div></div>
                          
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <Alert variant="info">No conversations yet.</Alert>
            )}
          </div>
          <Form className="message-input-container" onSubmit={(e) => e.preventDefault()}>
  
              <Form.Control
                type="text"
                placeholder="Type your message and press enter..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={handleKeyPress}
              />

           
          </Form>
        </div>
      )}
    </div>
  );
};

export default Messaging;
