import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container, Button, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import logoimage from "../../static/images/Linkscopelogodark.png";
import "./NavCom.css";
import Logout from "../Logout";

const NavCom = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Check if the user is logged in when the component mounts
  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token); // Set isLoggedIn to true if the token exists
  }, []);

  const handleToggle = () => setShowOffcanvas(!showOffcanvas);
  const handleClose = () => setShowOffcanvas(false);

  const handleNavigation = (path) => {
    navigate(path);
    handleClose();
  };



  return (
    <>
      <Navbar bg="light" expand="lg" className="nav-container">
        <Container fluid="lg" className="d-flex align-items-center justify-content-between">
          <Navbar.Brand href="/" className="d-flex align-items-center">
            <img src={logoimage} alt="Logo" className="nav-logo" />
          </Navbar.Brand>

          {/* Mobile Login button */}
          {!isLoggedIn && (
            <Link to="/signin">
              <Button variant="primary" className="signup-button d-lg-none">
                Login
              </Button>
            </Link>
          )}

          <Button
            variant="outline-primary"
            className="d-lg-none nav-toggle"
            onClick={handleToggle}
          >
            <FaBars />
          </Button>

          <Navbar.Collapse className="d-none d-lg-flex justify-content-between w-100">
            <Nav className="nav-links mx-auto">
              <Nav.Link onClick={() => handleNavigation("/")}>Home</Nav.Link>
              <Nav.Link onClick={() => handleNavigation("/about")}>About Us</Nav.Link>
              <Nav.Link onClick={() => handleNavigation("/contact")}>Contact</Nav.Link>
            </Nav>
            <div className="nav-actions">
              {isLoggedIn ? (
                // If the user is logged in, show the Dashboard button
                <>
                <Link to="/dashboard">
                  <Button variant="primary" className="mainbutton">
                    Dashboard
                  </Button>
                </Link>
    
                </>

                
              ) : (
                // If the user is not logged in, show Sign In and Register buttons
                <>
                  <Link to="/signin">
                    <Button variant="" className="">
                      Log In
                    </Button>
                  </Link>
                  <Link to="/register">
                    <Button variant="primary" className="mainbutton">
                      Create Account
                    </Button>
                  </Link>
                </>
              )}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Offcanvas show={showOffcanvas} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Nav.Link onClick={() => handleNavigation("/")}>Home</Nav.Link>
            <Nav.Link onClick={() => handleNavigation("/about")}>About Us</Nav.Link>
            <Nav.Link onClick={() => handleNavigation("/contact")}>Contact</Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default NavCom;
