import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import NavCom from "../components/home/Nav";
import { Helmet } from "react-helmet";
import Footer from "../components/home/Footer";

const ContactPage = () => {
  return (
    <>
      <Helmet>
        <title>Contact LinkScope - Get Support & Connect With Us</title>
        <meta
          name="description"
          content="Get in touch with LinkScope's support team. We're here to help both link buyers and sellers with their backlink marketplace needs."
        />
        <meta name="keywords" content="LinkScope contact, backlink support, SEO help, marketplace support" />
        <link rel="icon" href="/path-to-favicon.ico" />
      </Helmet>

      <NavCom />

      <div className="container-fluid px-0">
        {/* Hero Section */}
        <div className="bg-primary  text-white py-5 contactbg">
          <div className="container text-center">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <h1 className="display-3 fw-bold mb-4">Contact Us</h1>
                <p className="lead mb-0 fw-light fs-4">We're Here to Support Your Link Building Journey</p>
              </div>
            </div>
          </div>
        </div>

        <div className="container py-5">
          {/* Introduction Section */}
          <section className="mb-5">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="text-center mb-5">
                  <p className="lead">
                    Got questions or need assistance? Whether you're a{" "}
                    <strong>buyer looking for high-quality backlinks</strong> or a{" "}
                    <strong>seller aiming to monetize your website</strong>, we're here to help!
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* Contact Information Section */}
          <section className="mb-5">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <h2 className="h2 mb-4 text-center">
                  <span className="me-2">📩</span>Get in Touch
                </h2>
                <div className="row g-4">
                  {/* Buyers Contact Card */}
                  <div className="col-md-6">
                    <div className="h-100 bg-light p-4 rounded-4 shadow-sm text-center">
                      <h3 className="h4 mb-4 text-primary">For Buyers</h3>
                      <div className="d-flex flex-column gap-3">
                        <div className="p-3 bg-white rounded-3">
                          <p className="mb-2">
                            <span className="fs-5 me-2">📧</span>Email
                          </p>
                          <a href="mailto:abdullah@linkscope.io" className="text-decoration-none">
                            abdullah@linkscope.io
                          </a>
                        </div>
                        <div className="p-3 bg-white rounded-3">
                          <p className="mb-2">
                            <span className="fs-5 me-2">📱</span>WhatsApp
                          </p>
                          <a href="https://wa.me/8801710972107" target="_blank" className="text-decoration-none">
                            +880 1710-972107
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Sellers Contact Card */}
                  <div className="col-md-6">
                    <div className="h-100 bg-light p-4 rounded-4 shadow-sm text-center">
                      <h3 className="h4 mb-4 text-primary">For Sellers</h3>
                      <div className="d-flex flex-column gap-3 contactcolumn">
                        <div className="p-3 bg-white rounded-3">
                          <p className="mb-2">
                            <span className="fs-5 me-2">📧</span>Email
                          </p>
                          <a href="mailto:partners@linkscope.io" className="text-decoration-none">
                            partners@linkscope.io
                          </a>
                        </div>
                        <div className="p-3 bg-white rounded-3">
                          <p className="mb-2">
                            <span className="fs-5 me-2">📱</span>WhatsApp
                          </p>
                          <a href="https://wa.me/8801521478006" target="_blank" className="text-decoration-none">
                            +880 1521-478006
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Social Connect Section */}
          <section className="mb-5">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="bg-white p-4 rounded-4 shadow-sm text-center">
                  <h2 className="h2 mb-4">
                    <span className="me-2">💬</span>Connect with Us
                  </h2>
                  <p className="lead mb-4">
                    Stay updated with the latest opportunities, platform updates, and SEO insights by joining our community:
                  </p>
                  <div className="row justify-content-center g-3">
                    <div className="col-md-4">
                      <a href="https://www.facebook.com/LinkScope.io" 
                         target="_blank" 
                         className="btn btn-outline-primary w-100 p-3 rounded-3">
                        <i className="fab fa-facebook me-2"></i>Facebook Page
                      </a>
                    </div>
                    <div className="col-md-4">
                      <a href="https://www.linkedin.com/company/linkscopeio/" 
                         target="_blank" 
                         className="btn btn-outline-primary w-100 p-3 rounded-3">
                        <i className="fab fa-linkedin me-2"></i>LinkedIn
                      </a>
                    </div>
                    <div className="col-md-4">
                      <a href="https://www.facebook.com/groups/472953375752003/" 
                         target="_blank" 
                         className="btn btn-outline-primary w-100 p-3 rounded-3">
                        <i className="fab fa-facebook-group me-2"></i>Facebook Group
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Contact Form Section */}
          <section>
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="bg-light p-4 p-lg-5 rounded-4 shadow-sm">
                  <h2 className="h2 mb-4 text-center">
                    <span className="me-2">🛠</span>Need Urgent Help?
                  </h2>
                  <script src="https://js.hsforms.net/forms/embed/20808537.js" defer></script>
                  <div className="hs-form-frame" 
                       data-region="na1" 
                       data-form-id="da1c5d9e-53a1-482f-b2d8-cd8398e8630f" 
                       data-portal-id="20808537">
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default ContactPage;