import React, { useState } from "react";
import HorizontalMenu from "../components/HorizontalMenu";
import VerticalMenu from "../components/VerticalMenu";
import AllOrders from "../components/Orders";
import PendingMyOrder from "../components/PendingOrders";
const PendingOrdersPage = () => {
  const [isVerticalMenuVisible, setVerticalMenuVisible] = useState(false);
  const [isWithdrawModalVisible, setWithdrawModalVisible] = useState(false);

  const toggleVerticalMenu = () => {
    setVerticalMenuVisible(!isVerticalMenuVisible);
  };

 

  return (
    <div className="d-flex">
      {/* Vertical Menu */}
      <div
        className={`vertical-menu-container ${
          isVerticalMenuVisible ? "d-block" : "d-none d-md-block"
        }`}
      >
        <VerticalMenu />
      </div>

      {/* Main Content Area */}
      <div style={{ flex: 1 }}>
        {/* Horizontal Menu with Toggle */}
        <HorizontalMenu onToggleVerticalMenu={toggleVerticalMenu} />
        <div className="p-4">

         <PendingMyOrder/>

        </div>
      </div>

    </div>
  );
};

export default PendingOrdersPage;
