import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const PasswordReset = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1); // 1: Email, 2: Verification, 3: Reset Password
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    const url = "https://lionfish-app-nh929.ondigitalocean.app/password-reset/request/";

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();

      if (response.ok) {
        setMessage(data.message || "Password reset email sent.");
        setIsSuccess(true);
        setStep(2);
      } else {
        setMessage(data.message || "Failed to send password reset email.");
        setIsSuccess(false);
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
      setIsSuccess(false);
    }
  };

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();
    const url = `https://lionfish-app-nh929.ondigitalocean.app/password-reset/verify/${verificationCode}/`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      const data = await response.json();

      if (response.ok) {
        setMessage(data.message || "Verification successful.");
        setIsSuccess(true);
        setStep(3);
      } else {
        setMessage(data.message || "Invalid verification code.");
        setIsSuccess(false);
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
      setIsSuccess(false);
    }
  };

  const handlePasswordResetSubmit = async (e) => {
    e.preventDefault();
    const url = `https://lionfish-app-nh929.ondigitalocean.app/password-reset/reset/${verificationCode}/`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ password: newPassword }),
      });
      const data = await response.json();

      if (response.ok) {
        setMessage(data.message || "Password reset successfully.");
        setIsSuccess(true);
        setTimeout(() => navigate("/signin"), 2000);
      } else {
        setMessage(data.message || "Failed to reset password.");
        setIsSuccess(false);
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
      setIsSuccess(false);
    }
  };

  const renderForm = () => {
    if (step === 1) {
      return (
        <form onSubmit={handleEmailSubmit}>
          <div className="mb-3 form-group">
            <label className="form-label">Email Address*</label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="d-flex justify-content-between mt-4">
            <button type="submit" className="btn primarybutton">
              Submit
            </button>
          </div>
        </form>
      );
    }

    if (step === 2) {
      return (
        <form onSubmit={handleVerificationSubmit}>
          <div className="mb-3 form-group">
            <label className="form-label">Verification Code*</label>
            <input
              type="text"
              name="verificationCode"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              className="form-control"
              placeholder="Enter the verification code"
              required
            />
          </div>
          <div className="d-flex justify-content-between mt-4">
            <button type="submit" className="btn primarybutton">
              Verify
            </button>
          </div>
        </form>
      );
    }

    if (step === 3) {
      return (
        <form onSubmit={handlePasswordResetSubmit}>
          <div className="mb-3 form-group">
            <label className="form-label">New Password*</label>
            <input
              type="password"
              name="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="form-control"
              placeholder="Enter your new password"
              required
            />
          </div>
          <div className="d-flex justify-content-between mt-4">
            <button type="submit" className="btn primarybutton">
              Reset Password
            </button>
          </div>
        </form>
      );
    }
  };

  return (
    <div className="container-fluid vh-100 d-flex">
      {/* Left Section */}
      <div className="col-md-6 d-flex flex-column align-items-center justify-content-center bg-light">
        <div className="mb-4">
          <a href="/" className="text-decoration-none">
            Back to Home
          </a>
        </div>
        <div className="w-75">
          <h2 className="mb-4">Password Reset</h2>
          {step === 1 && <p>Please enter your email to request a password reset link.</p>}
          {step === 2 && <p>Enter the verification code sent to your email.</p>}
          {step === 3 && <p>Enter a new password to reset your account password.</p>}
          <hr />

          {renderForm()}

          {/* Render success or error message */}
          {message && (
            <div
              className={`mt-3 ${
                isSuccess ? "text-success" : "text-danger"
              }`}
            >
              {message}
            </div>
          )}
        </div>
        <footer className="d-flex align-items-end mt-5">© 2025 All rights reserved to LinkScope</footer>
      </div>

      {/* Right Section */}
      <div className="col-md-6 d-none d-md-flex align-items-end justify-content-center bg-secondary text-white">
        <div className="footer-links d-flex justify-content-between p-5">
          <a href="/contact">Contact</a>
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="/terms">Terms of Use</a>
          <a href="/blog">Blog</a>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
