import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer-section bg-dark text-white py-5">
      <div className="container">
        <div className="row">
          {/* Left Column: Logo and Description */}
          <div className="col-md-3 mb-4 mb-md-0">
            <div className="footer-logo mb-3">
              <h4 className="text-primary">LinkScope</h4>
            </div>
            <p className="footer-description">
              We’re a bespoke link-building agency that delivers outstanding
              results globally. Our focus is on building white hat links for
              ambitious brands & agencies.
            </p>
          </div>

          {/* Middle Columns: Links */}
          <div className="col-md-3 mb-4 mb-md-0">
            <h5 className="footer-title">Company</h5>
            <ul className="list-unstyled ">
              <li><a href="/" className="footer-link">Home</a></li>
              <li><a href="/about" className="footer-link">About Us</a></li>
              <li><a href="/contact" className="footer-link">Contact</a></li>
            </ul>
          </div>

          <div className="col-md-3 mb-4 mb-md-0 d-none">
            <h5 className="footer-title">Quick Links</h5>
            <ul className="list-unstyled">
              <li><a href="#" className="footer-link">Career</a></li>
              <li><a href="#" className="footer-link">Changelog</a></li>
              <li><a href="#" className="footer-link">Privacy Policy</a></li>
              <li><a href="#" className="footer-link">Term’s of Use</a></li>
              <li><a href="#" className="footer-link">Features</a></li>
            </ul>
          </div>

          {/* Right Column: Newsletter */}
          <div className="col-md-3">
            <h5 className="footer-title">Subscribe to our Newsletter</h5>
            <form>
              <div className="input-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter Email address"
                  aria-label="Email address"
                />
                <button className="btn btn-primary" type="submit">
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* Bottom Row: Social Links */}
        <div className="row mt-4">
          <div className="col text-center">
            <hr className="footer-divider" />
            <p className="mb-2">© 2024-2026, LinkScope, All Rights Reserved</p>
            <div className="social-links">
              <a href="#" className="social-icon"><i className="bi bi-facebook"></i></a>
              <a href="#" className="social-icon"><i className="bi bi-twitter"></i></a>
              <a href="#" className="social-icon"><i className="bi bi-instagram"></i></a>
              <a href="#" className="social-icon"><i className="bi bi-linkedin"></i></a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;