import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { FaCheckCircle, FaTimesCircle, FaEdit, FaTrashAlt } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'; // Import CSS for versions before v5.13.0
import Pagination from "react-bootstrap/Pagination";
import { Modal, Button, Form } from "react-bootstrap";

const RejectedListings = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const listingsPerPage = 10;
  const [showModal, setShowModal] = useState(false);
  const [editListing, setEditListing] = useState(null);
  const accesskey = localStorage.getItem("token");

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastListing = currentPage * listingsPerPage;
  const indexOfFirstListing = indexOfLastListing - listingsPerPage;
  const currentListings = data.slice(indexOfFirstListing, indexOfLastListing);

  const handleApprove = async (listingId) => {
    try {
      const response = await fetch(`https://lionfish-app-nh929.ondigitalocean.app/listings/${listingId}/approve/`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${accesskey}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Error approving listing: ${response.status} - ${response.statusText}`);
      }

      const result = await response.json();
      console.log(result)
      alert(`Listing ${listingId} approved successfully!`);
      
      // Update the UI to remove the approved listing
      setData(data.filter((listing) => listing.listing_id !== listingId));
    } catch (error) {
      console.error(error);
      alert("Failed to approve the listing. Please try again.");
    }
  };

  const handleReject = async (listingId) => {
    try {
      const response = await fetch(`https://lionfish-app-nh929.ondigitalocean.app/listings/${listingId}/reject/`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${accesskey}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Error rejecting listing: ${response.status} - ${response.statusText}`);
      }

      const result = await response.json();
      alert(`Listing ${listingId} rejected successfully!`);
      
      // Update the UI to remove the rejected listing
      setData(data.filter((listing) => listing.listing_id !== listingId));
    } catch (error) {
      console.error(error);
      alert("Failed to reject the listing. Please try again.");
    }
  };

  const handleDelete = async (listingId) => {
    try {
      const response = await fetch(`https://lionfish-app-nh929.ondigitalocean.app/listings/${listingId}/`, {
        method: "DELETE",
        headers: {
          "Authorization": `Bearer ${accesskey}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Error deleting listing: ${response.status} - ${response.statusText}`);
      }

      // Update the UI to remove the deleted listing
      setData(data.filter((listing) => listing.listing_id !== listingId));
    } catch (error) {
      console.error(error);
      alert("Failed to delete the listing. Please try again.");
    }
  };

  const handleEdit = (listingId) => {
    const listingToEdit = data.find((listing) => listing.listing_id === listingId);
    setEditListing(listingToEdit);
    setShowModal(true); // Show the modal for editing
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://lionfish-app-nh929.ondigitalocean.app/listings/${editListing.listing_id}/`, {
        method: "PUT",
        headers: {
          "Authorization": `Bearer ${accesskey}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editListing),
      });

      if (!response.ok) {
        throw new Error(`Error editing listing: ${response.status} - ${response.statusText}`);
      }

      const result = await response.json();
      console.log(result);
      alert("Listing updated successfully!");
      setData(data.map((listing) => (listing.listing_id === result.listing_id ? result : listing)));
      setShowModal(false);
    } catch (error) {
      console.error(error);
      alert("Failed to update the listing. Please try again.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditListing((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${accesskey}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch("https://lionfish-app-nh929.ondigitalocean.app/listings/", requestOptions);
        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
        const result = await response.json();
        const ownListings = result.filter((request) => request.status === "Rejected");
        setData(ownListings);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="table-responsive">
         <Table className="table table-transparent">
  <thead>
    <tr>
      <th>Site</th>
      <th>Type</th>
      <th>Niche</th>
      <th>Subniche</th>
      <th>Language</th>
      <th>Traffic (Ahrefs)</th>
      <th>Traffic (GSC)</th>
      <th>Traffic (SEMrush)</th>
      <th>Traffic Country</th>
      <th>Spam Score</th>
      <th>Ahrefs kw</th>
      <th>DA</th>
      <th>DR</th>
      <th>TAT</th>
      <th>Website Age</th>
      <th>Price</th>
      <th>Link Insert Price</th>
      <th>Other Price</th>
      <th>Status</th>
      <th>Sample</th>
      <th>Total Orders</th>
      <th>Email</th>
      <th>postguidelines</th>
      <th>lastupdate</th>
      <th>trafficgraph</th>
      <th>min_content</th>
      <th>externallinkallow</th>
      <th>googleapproved</th>
      <th>paymentmethod</th>
      <th>listingdate</th>
      <th>refundpolicy</th>
      <th>postexpiry</th>
      <th>anchortextrestrictions</th>
      <th>competitorlinkallowed</th>
      <th>sponsortag</th>
      <th>adminprice</th>
      <th>sellerfinalprice</th>
      <th>adminemail</th>
      <th>adminwhatsapp</th>
      <th>advance</th>
      <th>paymenttime</th>
      <th>sellerwhatsapp1</th>
      <th>sellerwhatsapp2</th>
      <th>sellerwhatsapp3</th>
      <th>selleremail1</th>
      <th>selleremail2</th>
      <th>selleremail3</th>
      <th>sellerfacebook1</th>
      <th>sellerfacebook2</th>
      <th>sellerfacebook3</th>
      <th>sellermarketplace1</th>
      <th>sellermarketplace2</th>
      <th>sellermarketplace3</th>
      <th>sellerprice1</th>
      <th>sellerprice2</th>
      <th>sellerprice3</th>
      <th>visibility</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    {currentListings.map((listing) => (
      <tr key={listing.listing_id}>
        <td>
          <a href={listing.site_url} target="_blank" rel="noopener noreferrer">
            {listing.site_url || "N/A"}
          </a>
        </td>
        <td>{listing.type || "N/A"}</td>
        <td>{listing.niche || "N/A"}</td>
        <td>{listing.subniche || "N/A"}</td>
        <td>{listing.language || "N/A"}</td>
        <td>{listing.ahreftraffic || 0}</td>
        <td>{listing.gsctraffic || 0}</td>
        <td>{listing.semrushtraffic || 0}</td>
        <td>{listing.trafficcountry || "N/A"}</td>
        <td>{listing.spamscore || 0}</td>
        <td>{listing.ahrefkw || "N/A"}</td>
        <td>{listing.da || 0}</td>
        <td>{listing.dr || 0}</td>
        <td>{listing.tat || 0} Days</td>
        <td>{listing.websiteage || 0}</td>
        <td>{listing.amount || 0}</td>
        <td>{listing.linkinsertprice || 0}</td>
        <td>{listing.otherprice || 0}</td>
        <td>{listing.status || "N/A"}</td>
        <td>{listing.sample || "N/A"}</td>
        <td>{listing.order_count || 0}</td>
        <td>{listing.email || "N/A"}</td>
        <td>{listing.postguidelines || "N/A"}</td>
        <td>{listing.lastupdate || "N/A"}</td>
        <td>{listing.trafficgraph || "N/A"}</td>
        <td>{listing.min_content || 0}</td>
        <td>{listing.externallinkallow || 0}</td>
        <td>{listing.googleapproved || "N/A"}</td>
        <td>{listing.paymentmethod || "N/A"}</td>
        <td>{listing.listingdate || "N/A"}</td>
        <td>{listing.refundpolicy || "N/A"}</td>
        <td>{listing.postexpiry || "N/A"}</td>
        <td>{listing.anchortextrestrictions || "N/A"}</td>
        <td>{listing.competitorlinkallowed || "N/A"}</td>
        <td>{listing.sponsortag || "N/A"}</td>
        <td>{listing.adminprice || 0}</td>
        <td>{listing.sellerfinalprice || 0}</td>
        <td>{listing.adminemail || "N/A"}</td>
        <td>{listing.adminwhatsapp || "N/A"}</td>
        <td>{listing.advance || "N/A"}</td>
        <td>{listing.paymenttime || 0}</td>
        <td>{listing.sellerwhatsapp1 || "N/A"}</td>
        <td>{listing.sellerwhatsapp2 || "N/A"}</td>
        <td>{listing.sellerwhatsapp3 || "N/A"}</td>
        <td>{listing.selleremail1 || "N/A"}</td>
        <td>{listing.selleremail2 || "N/A"}</td>
        <td>{listing.selleremail3 || "N/A"}</td>
        <td>{listing.sellerfacebook1 || "N/A"}</td>
        <td>{listing.sellerfacebook2 || "N/A"}</td>
        <td>{listing.sellerfacebook3 || "N/A"}</td>
        <td>{listing.sellermarketplace1 || "N/A"}</td>
        <td>{listing.sellermarketplace2 || "N/A"}</td>
        <td>{listing.sellermarketplace3 || "N/A"}</td>
        <td>{listing.sellerprice1 || 0}</td>
        <td>{listing.sellerprice2 || 0}</td>
        <td>{listing.sellerprice3 || 0}</td>
        <td>{listing.visibility || "N/A"}</td>
        <td>
          <div className="action-icons d-flex align-items-center">
            {listing.status !== "Active" && (
              <span
                className="action-icon"
                data-tooltip-id="approve-tooltip"
                data-tooltip-content="Approve Listing"
                onClick={() => handleApprove(listing.listing_id)}
              >
                <FaCheckCircle size={20} color="green" style={{ cursor: "pointer" }} />
              </span>
            )}
            {listing.status !== "Rejected" && (
              <span
                className="action-icon ms-3"
                data-tooltip-id="reject-tooltip"
                data-tooltip-content="Reject Listing"
                onClick={() => handleReject(listing.listing_id)}
              >
                <FaTimesCircle size={20} color="red" style={{ cursor: "pointer" }} />
              </span>
            )}
            <span
              className="action-icon ms-3"
              data-tooltip-id="edit-tooltip"
              data-tooltip-content="Edit Listing"
              onClick={() => handleEdit(listing.listing_id)}
            >
              <FaEdit size={20} color="blue" style={{ cursor: "pointer" }} />
            </span>
            <span
              className="action-icon ms-3"
              data-tooltip-id="delete-tooltip"
              data-tooltip-content="Delete Listing"
              onClick={() => handleDelete(listing.listing_id)}
            >
              <FaTrashAlt size={20} color="gray" style={{ cursor: "pointer" }} />
            </span>
          </div>
        </td>
      </tr>
    ))}
  </tbody>
</Table>
      {/* Add Tooltip Components */}
      <Tooltip id="approve-tooltip" />
      <Tooltip id="reject-tooltip" />
      <Tooltip id="edit-tooltip" />
      <Tooltip id="delete-tooltip" />

      <Pagination className="justify-content-start">
        {Array.from({ length: Math.ceil(data.length / listingsPerPage) }, (_, i) => (
          <Pagination.Item
            key={i + 1}
            active={i + 1 === currentPage}
            onClick={() => handlePageChange(i + 1)}
          >
            {i + 1}
          </Pagination.Item>
        ))}
      </Pagination>

      {/* Modal for Edit Listing */}
      {editListing && (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Listing</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleEditSubmit}>
              <Form.Group controlId="formSiteUrl">
                <Form.Label>Site URL</Form.Label>
                <Form.Control
                  type="text"
                  name="site_url"
                  value={editListing.site_url}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formType">
                <Form.Label>Type</Form.Label>
                <Form.Control
                  type="text"
                  name="type"
                  value={editListing.type}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formNiche">
                <Form.Label>Niche</Form.Label>
                <Form.Control
                  type="text"
                  name="niche"
                  value={editListing.niche}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formLanguage">
                <Form.Label>Language</Form.Label>
                <Form.Control
                  type="text"
                  name="language"
                  value={editListing.language}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formTraffic">
                <Form.Label>Traffic</Form.Label>
                <Form.Control
                  type="number"
                  name="traffic"
                  value={editListing.traffic}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formDA">
                <Form.Label>DA</Form.Label>
                <Form.Control
                  type="number"
                  name="da"
                  value={editListing.da}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formDR">
                <Form.Label>DR</Form.Label>
                <Form.Control
                  type="number"
                  name="dr"
                  value={editListing.dr}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formAmount">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="number"
                  name="amount"
                  value={editListing.amount}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formPremiumPrice">
                <Form.Label>Premium Price</Form.Label>
                <Form.Control
                  type="number"
                  name="premium_price"
                  value={editListing.premium_price}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="formSample">
                <Form.Label>Sample</Form.Label>
                <Form.Control
                  type="text"
                  name="sample"
                  value={editListing.sample}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="formTAT">
                <Form.Label>TAT (Turnaround Time)</Form.Label>
                <Form.Control
                  type="number"
                  name="tat"
                  value={editListing.tat}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            
              <Form.Group controlId="formPremiumFlag">
                <Form.Label>Premium Flag</Form.Label>
                <Form.Check
                  type="checkbox"
                  name="premium_flag"
                  checked={editListing.premium_flag}
                  onChange={(e) => handleChange({
                    target: { name: 'premium_flag', value: e.target.checked }
                  })}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default RejectedListings;
