import React, { useState, useEffect } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import "./Hero.css";
import { Link } from "react-router-dom";

import HeroImage from "../../static/images/linkhero.webp";
import ricon1 from "../../static/images/Ellipse1.png";
import ricon2 from "../../static/images/Ellipse2.png";
import ricon3 from "../../static/images/Ellipse3.png";
import ricon4 from "../../static/images/Ellipse4.png";

const Hero = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
  
    // Check if the user is logged in when the component mounts
    useEffect(() => {
      const token = localStorage.getItem("token");
      setIsLoggedIn(!!token); // Set isLoggedIn to true if the token exists
    }, []);
  return (
    <div className="hero-section">
      <Container className="text-center">
        <div className="herocontent">
        <div className="hero-reviews">
          <span className="review-icons">
            {/* Replace with actual icons */}
            <img src={ricon1} className="iconimage"></img>
            <img src={ricon1} className="iconimage"></img>
            <img src={ricon1} className="iconimage"></img>
            <img src={ricon1} className="iconimage"></img>
          </span>
          4000+<span className="review-text"> 5★ </span> reviews
        </div>
        <h1 className="hero-title">
        Link Building Marketplace <br/>
        Buy Backlinks in Just 4 Steps
        </h1>
        <p className="hero-description text-center">
        We connect link sellers and buyers under one roof. <br/>
        Sign up now to join this dynamic backlink marketplace with 10,000+ available sites for SEO link-building.
        </p>
        {isLoggedIn ? (
                // If the user is logged in, show the Dashboard button
                <>
                <Link to="/dashboard">
                  <Button variant="primary" className="mainbutton">
                    Dashboard
                  </Button>
                </Link>
    
                </>

                
              ) : (
                // If the user is not logged in, show Sign In and Register buttons
                <>
            
                  <Link to="/register">
                    <Button variant="primary" className="mainbutton">
                      Create Account
                    </Button>
                  </Link>
                </>
              )}
        <div className="hero-stats d-flex justify-content-center mt-4">
          <div className="stat-item">
            <h3>20k</h3>
            <p>Monthly Visitors</p>
          </div>
          <div className="stat-item">
            <h3>6+</h3>
            <p>Awards Received</p>
          </div>
          <div className="stat-item">
            <h3>100k</h3>
            <p>Registered Sites</p>
          </div>
        </div>
        </div>
        <img src={HeroImage} className="heroimage"></img>
      </Container>
    </div>
  );
};

export default Hero;