import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { FaCheckCircle, FaTimesCircle, FaTrash } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

const IncomingOrders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [filter, setFilter] = useState("All");

  const accessKey = localStorage.getItem('token');

  const fetchOrders = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessKey}`);
    myHeaders.append("Content-Type", "application/json");

    try {
      const response = await fetch("https://lionfish-app-nh929.ondigitalocean.app/all/orders", {
        method: "GET",
        headers: myHeaders
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      
      const result = await response.json();
      setOrders(result);
      setFilteredOrders(result);  // Initially show all orders
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  // Handle filter changes
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    if (newFilter === "All") {
      setFilteredOrders(orders);
    } else {
      setFilteredOrders(orders.filter(order => order.status === newFilter));
    }
  };

  const handleAction = async (orderId, action) => {
    const url = `https://lionfish-app-nh929.ondigitalocean.app/orders/${orderId}/${action}/`;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessKey}`);
    myHeaders.append("Content-Type", "application/json");

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: myHeaders
      });

      if (!response.ok) {
        throw new Error(`Failed to ${action} order`);
      }

      setMessage(`Successfully ${action}ed order ${orderId}`);
      setTimeout(() => setMessage(""), 2000);
      fetchOrders();  // Refresh orders after action
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (orderId) => {
    const url = `https://lionfish-app-nh929.ondigitalocean.app/orders/${orderId}/delete/`;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessKey}`);
    myHeaders.append("Content-Type", "application/json");

    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: myHeaders
      });

      if (!response.ok) {
        throw new Error("Failed to delete order");
      }

      setMessage(`Successfully deleted order ${orderId}`);
      setTimeout(() => setMessage(""), 2000);
      fetchOrders();  // Refresh orders after delete
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="table-responsive">
      {message && <div className="alert alert-success">{message}</div>}

      {/* Filter Dropdown */}
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <h5>Incoming Orders</h5>
        <DropdownButton
          id="dropdown-basic-button"
          title={`Filter: ${filter}`}
          onSelect={handleFilterChange}
        >
          <Dropdown.Item eventKey="All">All</Dropdown.Item>
          <Dropdown.Item eventKey="Pending">Pending</Dropdown.Item>
          <Dropdown.Item eventKey="Completed">Completed</Dropdown.Item>
          <Dropdown.Item eventKey="Active">Active</Dropdown.Item>
          <Dropdown.Item eventKey="Rejected">Rejected</Dropdown.Item>
        </DropdownButton>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Order ID</th>
            <th>User</th>
            <th>Amount</th>
            <th>Listing</th>
            <th>Order Type</th>
            <th>Target Site</th>
            <th>Balance Type</th>
            <th>Status</th>
            <th>Details</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders.map((order) => (
            <tr key={order.order_id}>
              <td>{order.order_id}</td>
              <td>{order.user}</td>
              <td>${order.amount.toFixed(2)}</td>
              <td>{order.listing}</td>
              <td>{order.order_type}</td>
              <td>{order.target_site}</td>
              <td>{order.balance_type}</td>
              <td>{order.status}</td>
              <td>
                <a href={`/order/${order.order_id}`} className="btn btn-primary mainbutton">
                  Show Details
                </a>
              </td>
              <td>
                <FaCheckCircle
                  style={{ cursor: "pointer", color: "green", marginRight: "10px" }}
                  data-tooltip-id="approve-tooltip"
                  data-tooltip-content="Approve Order"
                  onClick={() => handleAction(order.order_id, "accept")}
                />
                <FaTimesCircle
                  style={{ cursor: "pointer", color: "red", marginRight: "10px" }}
                  data-tooltip-id="reject-tooltip"
                  data-tooltip-content="Reject Order"
                  onClick={() => handleAction(order.order_id, "reject")}
                />
                <FaTrash
                  style={{ cursor: "pointer", color: "gray" }}
                  data-tooltip-id="delete-tooltip"
                  data-tooltip-content="Delete Order"
                  onClick={() => handleDelete(order.order_id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Tooltip Components */}
      <Tooltip id="approve-tooltip" />
      <Tooltip id="reject-tooltip" />
      <Tooltip id="delete-tooltip" />
    </div>
  );
};

export default IncomingOrders;
