import React from "react";
import { Container, Button, Carousel, Row, Col } from "react-bootstrap";
import "./Hero.css";
import HeroImage from "../../static/images/heroimage.webp";
import ricon1 from "../../static/images/Ellipse1.png";
import img1 from "../../static/images/lands-end.jpg";
import img2 from "../../static/images/lost-fm.png";
import img3 from "../../static/images/mcgil.png";
import img4 from "../../static/images/trump.png";
import img5 from "../../static/images/netflix.png";
import img6 from "../../static/images/credit-suisse.png";
import img7 from "../../static/images/merck.png";
import accountcreate from "../../static/images/createaccount.svg";
import placeorder from "../../static/images/placeorder.svg";
import searchsite from "../../static/images/searchsite.svg";
import done from "../../static/images/done.svg";
import playbutton from "../../static/images/playbutton.png";
import why from "../../static/images/why.png"
import {
  SiteIcon,
  OverviewIcon,
  LinkIcon2,
  SiteIcon2,
  AccountIcon2,
  LoginIcon2,
  ChartIcon3,
  SafeIcon,
} from "../../helper/icons";

import TestimonialCarousel from "./Testimonial";
import CaseStudyCarousel from "./CaseStudy";
import blogImage from "../../static/images/image (8).webp";
import FAQSection from "./Faq";
import HeroSection from "./SeconHero";
import Footer from "./Footer";

const About = () => {
  // Carousel items for authority site links
  const carouselItems = [
    { src: img1, alt: "Lands' End" },
    { src: img2, alt: "Last.fm" },
    { src: img3, alt: "McGill" },
    { src: img4, alt: "Trump Organization" },
    { src: img5, alt: "Netflix" },
    { src: img6, alt: "Credit Suisse" },
    { src: img7, alt: "Merck" },
  ];

  // Group carousel items into chunks of 6
  const groupedItems = [];
  for (let i = 0; i < carouselItems.length; i += 6) {
    groupedItems.push(carouselItems.slice(i, i + 6));
  }

  return (
    <div className="about">
      {/* Authority Site Links Carousel */}
      <Container className="text-center">
        <h2>Authority Site Links We Sell</h2>
        <Carousel className="image-carousel" indicators={false} controls={true}>
          {groupedItems.map((group, index) => (
            <Carousel.Item key={index}>
              <Row className="justify-content-center">
                {group.map((item, idx) => (
                  <Col key={idx} xs={12} sm={3} md={2} className="carousel-col">
                    <img
                      className="d-block mx-auto carousel-img"
                      src={item.src}
                      alt={item.alt}
                    />
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>

      {/* What is LinkScope Section */}
      <Container className="whatis py-5">
        <Row className="py-5 align-items-center">
        <h2 className="text-center subheader">What is LinkScope?</h2>

          <Col md={6}>
            <img className="specialimagess" src={why} />
          </Col>
          <Col md={6} className="textbox">
            <p className="">
              Forget those days when you used to spend weeks buying authority
              backlinks. <br/> 
            </p>
            <p className="text-bold">
               LinkScope is an advanced solution for your website's
              link-building. We recreated the traditional way of purchasing
              quality backlinks into a simple, sophisticated, and reliable
              platform that will help you get the preferred authority link in
              just a few minutes.
            </p>
            <p>
              You don't have to look for sellers, bargain for deals, and send
              follow-up emails for order completions. We made an automated
              system where each and every step of link building will be done in
              a few clicks. All you have to do is place your websites or request
              to buy quality backlinks with anchor texts. The artificial
              intelligence will work accordingly and send updates on your order.
              No sweat at all!
            </p>
          </Col>
        </Row>
      </Container>

      {/* Marketplace Steps Section */}
      <Container className="whatis py-5">
        <Row className="py-5">
          <h2 className="text-center subheader">Our Marketplace Works in a Jiffy</h2>
          <Row className="jiffyrow">
            {[
              {
                img: accountcreate,
                step: "Step 1",
                text: "Register to the marketplace",
              },
              {
                img: searchsite,
                step: "Step 2",
                text: "Search sites with preferred niches, DA, organic traffic, cost, etc.",
              },
              {
                img: placeorder,
                step: "Step 3",
                text: "Place your order with the number of backlinks and other requirements",
              },
              {
                img: done,
                step: "Step 4",
                text: "And done, wait for the live link now",
              },
            ].map((item, index) => (
              <Col md={3} key={index} className="jiffy">
                <div className="jiffy-card">
                  <img src={item.img} className="jiffyimage" alt={item.step} />
                  <h3>{item.step}</h3>
                  <p>{item.text}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Row>
      </Container>

      {/* Play Button Section */}
      <Container className="play py-5">
        <Row className="py-5">
          <Col className="text-center">
            <img src={playbutton} alt="Play Button" className="play-button" />
          </Col>
        </Row>
      </Container>

      {/* Why LinkScope Section */}
      <Container className="why py-5">
        <Row className="py-5">
          <h2 className="text-center subheader mb-5">
            Why is LinkScope Your #1 Marketplace for Buying High Authority
            Backlinks
          </h2>
          <Row>
            {[
              {
                icon: <SiteIcon width={36} height={36} className="custom-icon" />,
                title: "Sites Get Manually Checked & Listed",
                text: "Automation saves time and effort. But manual crosschecks always bring perfection. Our Outreach team always crosschecks a domain's credibility and authoritativeness before presenting to the buyers.",
              },
              {
                icon: <OverviewIcon width={36} height={36} className="custom-icon" />,
                title: "User Friendly Dashboard",
                text: "Aligning with our system isn’t rocket science. Select your preferred options and pick sites accordingly to buy backlinks. You can see the progress of your order quite easily.",
              },
              {
                icon: <LinkIcon2 width={36} height={36} className="custom-icon" />,
                title: "Sky is the Limit with Database Containing 100k+ Sites",
                text: "We gather data from hundreds of niches across the world. LinkScope backlink marketplace isn’t stuck to any specific region, user-base, or niche.",
              },
              {
                icon: <SiteIcon2 width={36} height={36} className="custom-icon" />,
                title: "We Value Different Concepts",
                text: "You can have some unique strategies for your business or blog. Thus, we let you send your customized orders with personal demands.",
              },
              {
                icon: <AccountIcon2 width={36} height={36} className="custom-icon" />,
                title: "One Account, Every Solution",
                text: "A buyer can sell and a seller can also wish to purchase. With just one account you can enjoy the whole feature of our link building automation.",
              },
              {
                icon: <LoginIcon2 width={36} height={36} className="custom-icon" />,
                title: "Authenticity First",
                text: "Your privacy is our concern. Our order and payment system is more secure than ever. Clients get regular updates of their deliveries and orders in one click.",
              },
              {
                icon: <ChartIcon3 width={36} height={36} className="custom-icon" />,
                title: "Fast Forward to Results",
                text: "Our backlink marketplace is breaking the stereotype. From finding a quality backlink to making a complete solution, everything’s possible within 2-5 days.",
              },
              {
                icon: <SafeIcon width={36} height={36} className="custom-icon" />,
                title: "Trust Us & Save Your Money",
                text: "Why give extra bucks to brokers and agencies when you can directly contact the authors and sellers with the best possible deals?",
              },
            ].map((item, index) => (
              <Col md={3} key={index} className="mb-4">
                <div className="whycard">
                  {item.icon}
                  <h3>{item.title}</h3>
                  <p>{item.text}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Row>
      </Container>

      {/* Testimonials Section */}
      <Container fluid>
        <div className="hero-section">
          <Container className="text-center">
            <div className="herocontent">
 
              <h1 className="hero-title">
                See What Our Previous & Existing Clients Say
              </h1>
            </div>
            <TestimonialCarousel />
          </Container>
        </div>
      </Container>

      {/* Case Studies Section */}
      <Container>
        <CaseStudyCarousel />
      </Container>

      {/* Why Link Building is Important Section */}
      <Container className="why py-5">
        <Row className="align-center text-start">
          <Col md={6}>
            <img src={blogImage} className="image" alt="Blog" />
          </Col>
          <Col md={6}>
            <h2 className="subheader">Why is Link Building Important for SEO</h2>
            <p className="text-bold">
              Link building plays a vital role in SEO by signaling to search
              engines that your website is trustworthy and authoritative.
            </p>
            <p>
              Effective backlinks not only drive organic traffic but also help
              you build a strong digital presence. Focusing on quality over
              quantity ensures sustainable SEO growth and long-term success.
            </p>
            <Button className="mainbutton btn btn-primary">View More</Button>
          </Col>
        </Row>
        <FAQSection />
      </Container>

      {/* Hero Section */}
      <HeroSection />

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default About;