import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import { useParams } from "react-router-dom";

const ProjectOrder = () => {
  const [orders, setOrders] = useState([]); // Stores project orders
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { projectId } = useParams();

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      setError("");

      const token = localStorage.getItem("token");
      if (!token) {
        setError("Access token is missing!");
        setLoading(false);
        return;
      }

      const url = `https://lionfish-app-nh929.ondigitalocean.app/projects/${projectId}/orders/`; // API URL
      const myHeaders = new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      });

      try {
        const response = await fetch(url, { method: "GET", headers: myHeaders });
        if (response.ok) {
          const result = await response.json();
          setOrders(result || []); // Update state with fetched orders
        } else {
          setError("Failed to fetch project orders.");
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);
  const handleSeeDetails = (orderId) => {
    window.location.href = `/projects/${projectId}/${orderId}`;
  };
  return (
    <div style={{ padding: "1rem", maxWidth: "100%", overflowX: "auto" }}>
      <h2>Project Orders</h2>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : (
        <Table bordered hover responsive className="mt-4">
          <thead>
            <tr>
              <th>Amount</th>
              <th>Listing</th>
              <th>Order Type</th>
              <th>Target Site</th>
              <th>Balance Type</th>
              <th>Status</th>
              <th>Details</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {orders.length > 0 ? (
              orders.map((order) => (
                <tr key={order.order_id}>
                  <td>${order.amount.toFixed(2)}</td>
                  <td>{order.listing}</td>
                  <td>{order.order_type}</td>
                  <td>{order.target_site}</td>
                  <td>{order.balance_type}</td>
                  <td>{order.status}</td>
                  <td>{order.detail}</td>
                  <td ><button onClick={() => handleSeeDetails(order.order_id)} className="btn btn-primary mainbutton">See Details</button></td>

                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" className="text-center">
                  No orders found for this project.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default ProjectOrder;
