import React, { useState, useEffect } from "react";
import { Navbar, Dropdown, Button, Modal, Form } from "react-bootstrap";
import { FaBars } from "react-icons/fa"; // Toggle icon
import { UserIcon } from "../../helper/icons";
import Logout from "../Logout";
const AdminHorizontal = ({ onToggleVerticalMenu }) => {
  const [mainBalance, setMainBalance] = useState(null);
  const [referralBalance, setReferralBalance] = useState(null);
  const [showSearchModal, setShowSearchModal] = useState(false);

  // Function to fetch balances from API






  return (
    <>
      <Navbar expand="lg" className="horizontal">
        <div className="container-fluid">
          <div className="row w-100 align-items-center">
            {/* Right Section */}
            <div className="col-md-12 d-flex align-items-center horizontalmenu">
              <Button
                variant="light"
                className="d-md-none p-2 me-2 togglebutton"
                onClick={onToggleVerticalMenu}
              >
                <FaBars />
              </Button>
             
             
   

              {/* Profile Dropdown */}
              <Dropdown align="end">
                <Dropdown.Toggle
                  variant="light"
                  id="dropdown-basic"
                  className="p-2 border-0 drop"
                >
                  <UserIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/settings">Settings</Dropdown.Item>
                  <Dropdown.Item href="/profile">Profile</Dropdown.Item>

                  <Dropdown.Divider />
                  <Dropdown.Item > <Logout/> </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </Navbar>

     
    </>
  );
};

export default AdminHorizontal;
