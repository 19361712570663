import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { FaBars, FaTachometerAlt, FaUsers, FaChartBar, FaCog } from "react-icons/fa";
import { OverviewIcon,LinkIcon,BrowserIcon,WalletIcon,ChatIcon,UserIcon,SigninIcon  } from "../../helper/icons";
import Logout from "../Logout";

import linkscopelogo from '../../static/images/logo.png'
import { BiColor } from "react-icons/bi";
const ResellerVerticalMenu = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleMenu = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div
    style={{
      height: "100vh",
      width: isCollapsed ? "80px" : "250px",
      transition: "width 0.3s",
      overflow: "hidden",
      whiteSpace: "nowrap",
      boxShadow: "2px 0 5px rgba(0,0,0,0.1)",
    }}
    className="d-flex flex-column vertical"
  >
    {/* Header Section with Toggle Button */}
    <div
      style={{
        display: "flex",
        justifyContent: isCollapsed ? "center" : "space-between",
        alignItems: "center",
        height: "60px",
        borderBottom: "1px solid #43434C",
        cursor: "pointer",
        padding: isCollapsed ? "0 10px" : "0 20px",
      }}
    >
      <div className="tog" onClick={toggleMenu}>
        <FaBars size={20} />
      </div>
      {!isCollapsed && (
       <a href="/reseller/dashboard" style={{ textDecoration: "none" }}>
       <div
         className="headerlogo"
         style={{
           fontSize: "16px",
           fontWeight: "bold",
           marginLeft: "10px",
         }}
       >
         <img src={linkscopelogo} alt="Logo" />
       </div>
     </a>
      )}
    </div>
  
    <Nav className="flex-column">
      {/* Links */}
      {[
        { href: "/dashboard", icon: <OverviewIcon width={20} height={20} />, label: "Dashboard" },
        { href: "/mylistings", icon: <LinkIcon width={20} height={20} />, label: "All Listings" },
        { href: "/incomingorders", icon: <WalletIcon width={20} height={20} />, label: "Incoming Orders" },
        { href: "/balance", icon: <WalletIcon width={20} height={20} />, label: "Balance" },

        { href: "/messages", icon: <ChatIcon width={20} height={20} />, label: "Messages" },
        { href: "/profile", icon: <UserIcon width={20} height={20} />, label: "Profile" },
      ].map(({ href, icon, label }) => (
        <Nav.Link href={href} className="text-light" key={href}>
          <div
            className="navitem"
            style={{
              width: "100%",
              height: "100%",
              padding: "13px 20px",
              borderRadius: 30,
              display: "flex",
              alignItems: "center",
              justifyContent: isCollapsed ? "center" : "flex-start",
              gap: isCollapsed ? 0 : 12,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 24,
                height: 24,
              }}
            >
              {icon}
            </div>
            {!isCollapsed && (
              <span
                style={{
                  fontSize: 16,
                  fontFamily: "Inter",
                  fontWeight: 500,
                  lineHeight: "26px",
                }}
              >
                {label}
              </span>
            )}
          </div>
        </Nav.Link>
      ))}
  
      {/* Bottom Section */}
      <div className="mt-auto bottom">
        <Nav href="/logout" className="text-light">
          <div
            className="navitem"
            style={{
              width: "100%",
              height: "100%",
              padding: "13px 20px",
              borderRadius: 30,
              display: "flex",
              alignItems: "center",
              justifyContent: isCollapsed ? "center" : "flex-start",
              gap: isCollapsed ? 0 : 12,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 24,
                height: 24,
              }}
            >
              <SigninIcon width={20} height={20} />
            </div>
            {!isCollapsed && (
              <span
                style={{
                  fontSize: 16,
                  fontFamily: "Inter",
                  fontWeight: 500,
                  lineHeight: "26px",
                  color: "white",
                }}
              >
<Logout/>              </span>
            )}
          </div>
        </Nav>
      </div>
    </Nav>
  </div>
  
  );
};

export default ResellerVerticalMenu;
