// src/icons.js

import React from 'react';
import { ReactComponent as AccountIcon } from '../static/icons/Account.svg';
import { ReactComponent as AddIcon } from '../static/icons/Add.svg';
import { ReactComponent as AnalyticsIcon } from '../static/icons/Analytics.svg';
import { ReactComponent as OverviewIcon } from '../static/icons/Overview.svg';
import { ReactComponent as LinkIcon } from '../static/icons/Link.svg';
import { ReactComponent as WalletIcon } from '../static/icons/Wallet.svg';
import { ReactComponent as ChatIcon } from '../static/icons/Chat.svg';
import { ReactComponent as UserIcon } from '../static/icons/User.svg';
import { ReactComponent as SigninIcon } from '../static/icons/Sign In.svg';
import { ReactComponent as BagIcon } from '../static/icons/Bag.svg';
import { ReactComponent as NotificationIcon } from '../static/icons/Notification.svg';
import { ReactComponent as BrowserIcon } from '../static/icons/Browser.svg';
import { ReactComponent as SearchIcon } from '../static/icons/Search.svg';
import { ReactComponent as GoogleIcon } from '../static/icons/google.svg';
import { ReactComponent as ChartIcon } from '../static/icons/Chart.svg';
import { ReactComponent as ChartIcon2 } from '../static/icons/Chart-1.svg';
import { ReactComponent as SafetyIcon } from '../static/icons/Frame-1.svg';
import { ReactComponent as MarketingIcon } from '../static/icons/Marketing.svg';
import { ReactComponent as GlobeIcon } from '../static/icons/Globe.svg';
import { ReactComponent as SiteIcon } from '../static/icons/Site.svg';
import { ReactComponent as SiteIcon2 } from '../static/icons/Site3.svg';
import { ReactComponent as LinkIcon2 } from '../static/icons/Link (2).svg';
import { ReactComponent as LoginIcon2 } from '../static/icons/Login2.svg';
import { ReactComponent as ChartIcon3 } from '../static/icons/Chart2.svg';
import { ReactComponent as AccountIcon2 } from '../static/icons/Account2.svg';
import { ReactComponent as SafeIcon } from '../static/icons/Frame3.svg';






export {
    OverviewIcon,
    AddIcon,
    AccountIcon,
    AnalyticsIcon,
    LinkIcon,
    ChatIcon,
    WalletIcon,
    UserIcon,
    SigninIcon,
    BagIcon,
    NotificationIcon,
    BrowserIcon,
    SearchIcon,
    GoogleIcon,
    ChartIcon2,
    ChartIcon,
    SafetyIcon,
    GlobeIcon,
    MarketingIcon,
    SiteIcon,
    SiteIcon2,
    ChartIcon3,
    LinkIcon2,
    LoginIcon2,
    AccountIcon2,
    SafeIcon
};
