import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const SinglePro = () => {
  const [projects, setProjects] = useState([]); // Stores the project data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false); // Controls the modal visibility
  const [inputValue, setInputValue] = useState(""); // Stores the username/email input value
  const [apiError, setApiError] = useState("");
  const navigate = useNavigate();
  const { projectId } = useParams();



  useEffect(() => {
    const fetchProjects = async () => {
      setLoading(true);
      setError("");

      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Access token is missing!");
        setError("Access token is missing!");
        setLoading(false);
        return;
      }

      const url = `https://lionfish-app-nh929.ondigitalocean.app/projects/${projectId}/`;
      const myHeaders = new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      });

      try {
        const response = await fetch(url, { method: "GET", headers: myHeaders });
        if (response.ok) {
          const result = await response.json();
          setProjects(result);
          console.log(result);
        } else {
          setError("Failed to fetch project details.");
        }
      } catch (error) {
        console.error(error);
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [projectId]);

  const handleRemoveMember = async (projectId, userId) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Access token is missing!");
      alert("Access token is missing!");
      return;
    }
  
    const url = `https://lionfish-app-nh929.ondigitalocean.app/projects/${projectId}/team/remove/`;
    const myHeaders = new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    });
  
    const raw = JSON.stringify({
      member_id: userId, // Pass the user ID in the body
    });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        alert("Team member removed successfully!");
        // Update the local state by removing the user
        setProjects((prevProjects) => ({
          ...prevProjects,
          team_members: prevProjects.team_members.filter(
            (member) => member.user_id !== userId
          ),
        }));
      } else {
        const result = await response.json();
        alert(result.message || "Failed to remove the team member.");
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while removing the team member.");
    }
  };
  
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleAddTeamMember = async () => {
    setApiError("");
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Access token is missing!");
      setApiError("Access token is missing!");
      return;
    }

    const url = `https://lionfish-app-nh929.ondigitalocean.app/projects/${projectId}/add-team/`;
    const myHeaders = new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    });

    const raw = JSON.stringify({
      email: inputValue, // Use the entered username/email
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        alert("Team member added successfully!");
        setInputValue(""); // Clear the input field
        setShowModal(false); // Close the modal
        window.location.reload(); // Refresh the page
      } else {
        const result = await response.json();
        setApiError(result.message || "Failed to add team member.");
      }
    } catch (error) {
      console.error(error);
      setApiError("An error occurred while adding the team member.");
    }
  };

  const handleLeaveTeam = async (projectId) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Access token is missing!");
      alert("Access token is missing!");
      return;
    }
  
    const url = `https://lionfish-app-nh929.ondigitalocean.app/projects/${projectId}/team/leave/`;
    const myHeaders = new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };
  
    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        alert("You have successfully left the team!");
        // Reload the page or update the state to reflect the change
        window.location.reload();
      } else {
        const result = await response.json();
        alert(result.message || "Failed to leave the team.");
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while leaving the team.");
    }
  };
  


  return (
    <div style={{ padding: "1rem", maxWidth: "100%"}}>
      <h2>Project Name: {projects.name}</h2>
      <div className="row">
        <div className="col-md-6">
          <button
            type="button"
            className="btn btn-primary mainbutton"
            onClick={handleShowModal}
          >
            Add New Team Member
          </button>
        </div>
      </div>

      <div className="row">
        <div className="TeamMembers col-md-10 text-center mx-auto ">
            <h2> Team Members</h2>
        <Table bordered hover responsive className="mt-4">
          <thead>
            <tr>
              <th>Email</th>
              <th>Name</th>
              <th>Username</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
          {projects.team_members && projects.team_members.length > 0 ? (
        projects.team_members.map((member, index) => (
          <tr key={index}>
            <td>{member.email || "N/A"}</td>
            <td>
              {member.first_name || member.last_name
                ? `${member.first_name} ${member.last_name}`.trim()
                : "N/A"}
            </td>
            <td>{member.user_id || "N/A"}</td>
            <td>
            {projects.owner?.user_id === localStorage.getItem("user") ? (
            // Show "Remove" button if the current user is the owner
            <button
              type="button"
              className="btn btn-danger btn-sm"
              onClick={() => handleRemoveMember(projects.id, member.user_id)} // Pass project ID and user ID
            >
              Remove
            </button>
          ) : (
            member.user_id === localStorage.getItem("user") && (
              // Show "Leave" button if the current user is not the owner and is part of the team
              <button
                type="button"
                className="btn btn-warning btn-sm"
                onClick={() => handleLeaveTeam(projects.id)} // Call leave API
              >
                Leave
              </button>
            )
          )}
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="4" className="text-center">
            No team members found.
          </td>
        </tr>
      )}
          </tbody>
        </Table>

        </div>
      </div>

      {/* Modal for Adding Team Member */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Team Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="teamMemberInput">
              <Form.Label>Username/Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter username or email"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
            </Form.Group>
            {apiError && <Alert variant="danger" className="mt-3">{apiError}</Alert>}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleAddTeamMember}
            disabled={!inputValue.trim()}
          >
            Add Member
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SinglePro;
