import React, { useState, useEffect } from "react";
import { Navbar, Dropdown, Button, Modal, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { FaBars } from "react-icons/fa"; // Toggle icon
import { UserIcon } from "../../helper/icons";
import Logout from "../Logout";
const ResellerHorizontalMenu = ({ onToggleVerticalMenu }) => {
  const [mainBalance, setMainBalance] = useState(null);
  const [referralBalance, setReferralBalance] = useState(null);
  const [showSearchModal, setShowSearchModal] = useState(false);
 const navigate = useNavigate();

  // Function to fetch balances from API
  const fetchBalances = async () => {
    const accessToken = localStorage.getItem("token"); // Get token from localStorage
    if (!accessToken) {
      console.error("Access token is missing!");
      return;
    }

    const userid = localStorage.getItem("user"); 
    console.log(userid);// Get token from localStorage
    if (!userid) {
      console.error("user is missing!");
      return;
    }
    const url = `https://lionfish-app-nh929.ondigitalocean.app/wallet/${userid}/`;

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        const data = await response.json();
        // Assuming the API returns an object with main_balance and referral_balance fields
        const { main_balance, referral_balance } = data;

        // Save to localStorage
        localStorage.setItem("main_balance", main_balance);
        localStorage.setItem("referral_balance", referral_balance);

        // Update state
        setMainBalance(main_balance);
        setReferralBalance(referral_balance);
      } else {
        console.error("Failed to fetch balances:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching balances:", error);
    }
  };

  // Check localStorage and fetch balances if necessary
  useEffect(() => {
    const storedMainBalance = localStorage.getItem("main_balance");
    const storedReferralBalance = localStorage.getItem("referral_balance");

    if (storedMainBalance && storedReferralBalance) {
      // Use balances from localStorage
      setMainBalance(storedMainBalance);
      setReferralBalance(storedReferralBalance);
    } else {
      // Fetch balances from API
      fetchBalances();
    }
  }, []);

  const handleSwitchToSeller = () => {
    // Your logic for switching to seller
    console.log("Switched to seller mode");
    navigate('/dashboard')

    // For example, you could redirect the user, update a state, etc.
  };

  const handleSearchClick = () => setShowSearchModal(true);
  const handleCloseSearchModal = () => setShowSearchModal(false);

  return (
    <>
      <Navbar expand="lg" className="horizontal">
        <div className="container-fluid">
          <div className="row w-100 align-items-center">
            {/* Right Section */}
            <div className="col-md-12 d-flex align-items-center horizontalmenu">
              <Button
                variant="light"
                className="d-md-none p-2 me-2 togglebutton"
                onClick={onToggleVerticalMenu}
              >
                <FaBars />
              </Button>
              <div className="me-3 d-md-flex align-items-center balance">
                Main Balance
                <span className="bagtext">${mainBalance !== null ? mainBalance : "Loading..."}</span>
              </div>
              <div className="me-3 d-none d-md-flex align-items-center balance">
                Referral Balance
                <span className="bagtext">${referralBalance !== null ? referralBalance : "Loading..."}</span>
              </div>
              <Button className="mainbutton me-3 seller d-none d-md-block"  onClick={handleSwitchToSeller}>
Switch to Customer              </Button>

              {/* Profile Dropdown */}
              <Dropdown align="end">
                <Dropdown.Toggle
                  variant="light"
                  id="dropdown-basic"
                  className="p-2 border-0 drop"
                >
                  <UserIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                  <Button className="mainbutton me-3 seller d-md-block"  onClick={handleSwitchToSeller}>
                Customer
                  </Button>
                  <Dropdown.Divider />
                  <Dropdown.Item > <Logout/> </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </Navbar>

      {/* Search Modal */}
      <Modal show={showSearchModal} onHide={handleCloseSearchModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Control type="text" placeholder="Search..." autoFocus />
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ResellerHorizontalMenu;
