import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom"; // Import useParams and useNavigate hooks

const VerifyListing = () => {
  const { listing_id } = useParams(); // Get listing_id from URL parameters
  const navigate = useNavigate();
  
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    setVerificationCode(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setLoading(true);
    setError(null);

    const requestData = {
      listing_id: parseInt(listing_id),
      verification_code: verificationCode,
    };

    try {
      const response = await fetch("https://lionfish-app-nh929.ondigitalocean.app/verify-listing-email/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        setSuccess(true);
        setTimeout(() => {
          navigate("/mylistings"); // Navigate to /mylistings after success
        }, 2000);
      } else {
        const result = await response.json();
        setError(result.message || "Verification failed.");
      }
    } catch (error) {
      console.error(error);
      setError("An error occurred while verifying the listing.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Verify Listing</h2>
      {success && <Alert variant="success">Verification successful! Redirecting...</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}

      <Form onSubmit={handleSubmit}>
        {/* Verification Code */}
        <Form.Group controlId="verification_code">
          <Form.Label>Verification Code</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter verification code"
            value={verificationCode}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        {/* Submit Button */}
        <Button variant="primary" type="submit" disabled={loading}>
          {loading ? "Verifying..." : "Verify Listing"}
        </Button>
      </Form>
    </div>
  );
};

export default VerifyListing;
