import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";

const CountTotalUser = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const accesskey = localStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${accesskey}` // Use backticks for string interpolation
      );

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch("https://lionfish-app-nh929.ondigitalocean.app/users/", requestOptions);
        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
        const result = await response.json();
        
        // Set the user data
        setData(result);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Counting the number of users (assuming result is an array)
  const totalUsers = data.length;

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
          <div className="row">
          <h2>Total Users</h2>
          <h2>{totalUsers}</h2>

        </div>
      {/* You can render the list or other data here if you need */}
    </div>
  );
};

export default CountTotalUser;
