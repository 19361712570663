import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

const AllRejectedOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filter, setFilter] = useState("Rejected");
  
  
  

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      setError("");
      const myHeaders = new Headers();
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Access token is missing!");
        return;
      }

      const userId = localStorage.getItem("user");
      if (!userId) {
        console.error("User ID is missing!");
        return;
      }

      const url = `https://lionfish-app-nh929.ondigitalocean.app/orders/${userId}`;
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch(url, requestOptions);
        if (response.ok) {
          const result = await response.json();
          console.log(result);
          const orderdata = result.filter((request) => request.status === "Rejected");
          setOrders(orderdata);
        } else {
          setError("Failed to fetch orders.");
        }
      } catch (error) {
        console.error(error);
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    if (newFilter === "All") {
      setFilteredOrders(orders);
    } else {
      setFilteredOrders(orders.filter(order => order.status === newFilter));
    }
  };

  const handleSeeDetails = (orderId) => {
    window.location.href = `/order/${orderId}`;
  };

  return (
    <div style={{ padding: "1rem", maxWidth: "100%", overflowX: "auto" }}>
       <div className="mb-3 d-flex justify-content-between align-items-center">
        <h5>All Rejected Orders</h5>
        <DropdownButton
          id="dropdown-basic-button"
          title={`Filter: ${filter}`}
          onSelect={handleFilterChange}
        >
          <Dropdown.Item eventKey="All">All</Dropdown.Item>
          <Dropdown.Item eventKey="Pending">Pending</Dropdown.Item>
          <Dropdown.Item eventKey="Completed">Completed</Dropdown.Item>
          <Dropdown.Item eventKey="Active">Active</Dropdown.Item>
          <Dropdown.Item eventKey="Rejected">Rejected</Dropdown.Item>
        </DropdownButton>
      </div>
      
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p style={{ color: "red" }}>{error}</p>
      ) : orders.length > 0 ? (
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "1rem",
          }}
        >
          <thead>
            <tr style={{ backgroundColor: "#f2f2f2", textAlign: "left" }}>
              <th style={styles.th}>Order ID</th>
              <th style={styles.th}>Website Name</th>

              <th style={styles.th}>Amount</th>
              <th style={styles.th}>Article Requirement</th>
              <th style={styles.th}>Target Site</th>
              <th style={styles.th}>Status</th>
              <th style={styles.th}>Details</th>
              <th style={styles.th}>Action</th>
            </tr>
          </thead>
          <tbody>
            {orders.slice().reverse().map((order) => (
              <tr key={order.order_id} style={styles.tr}>
                <td style={styles.td}>{order.order_id}</td>
                <td style={styles.td}>{order.site_url}</td>
                <td style={styles.td}>${order.amount.toFixed(2)}</td>
                {(() => {
  // Parse the detail field
  const orderDetail = JSON.parse(order.detail);

  return orderDetail.service_type === "Guest Post" ? (
    order.order_type === "Standard" ? (
      <td style={styles.td}>Buyer is providing Article</td>
    ) : (
      <td style={styles.td}>Buyer asked you to write article</td>
    )
  ) : (
    <td style={styles.td}>No article needed</td>
  );
})()}


            <td style={styles.td}><a href={order.target_site} target="_blank" rel="noopener noreferrer">{order.target_site}</a></td>
                <td style={styles.td}>{order.status}</td>
                <td style={styles.td}> {
                  // Parse the JSON string in 'detail' and display key-value pairs
                  Object.entries(JSON.parse(order.detail)).map(([key, value], index) => (
                    <div key={index}>
                      <strong>{key}:</strong> {value}
                    </div>
                  ))
                }</td>
                <td style={styles.td}><button onClick={() => handleSeeDetails(order.order_id)}>See Details</button></td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No orders found.</p>
      )}
    </div>
  );
};

const styles = {
  th: {
    padding: "12px",
    border: "1px solid #ddd",
    fontWeight: "bold",
  },
  td: {
    padding: "12px",
    border: "1px solid #ddd",
  },
  tr: {
    borderBottom: "1px solid #ddd",
  },
};

export default AllRejectedOrders;
