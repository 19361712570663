import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleIcon  } from "../helper/icons";
import NavCom from "../components/home/Nav";
import SingleHeader from "../components/SingleHeader";
import SingleFooter from "../components/SingleFooter";
import CategoryListings from "../components/CategoryListings";
import Footer from "../components/home/Footer";
const CategoryPublicPage = () => {
  const navigate = useNavigate();
 


  return (
    <>
     < NavCom />
     <SingleHeader/>
        <CategoryListings/>
        <SingleFooter/>

        <Footer/>
    </>
  );
};

export default CategoryPublicPage;
