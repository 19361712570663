import React from "react";
import "./HeroSection.css";
import manlaptop from "../../static/images/image 339.webp"

const HeroSection = () => {
  return (
    <div className="custom-hero-section container-fluid d-flex">
      <div className="container">
        <div className="row">
          {/* Left Column */}
          <div className="col-md-6 text-white">
            <h2 className="custom-hero-title mb-4 text-start footerhero">
              Start Link Building in Your Website the Most Reliable Backlink Marketplace
            </h2>
            <p className="custom-hero-subtitle mb-4 text-start">
              We love talking link building. Let us know about your project and we'll send you a free proposal.
            </p>
            <div className="d-flex gap-3">
              <button className="btn btn-primary btn-lg"><a href="/signup" className="ctabutton">Register Now</a></button>
              <button className="btn btn-outline-light btn-lg"><a href="/contact"className="ctabutton" >Get in Touch</a></button>

            </div>
          </div>

          {/* Right Column */}
          <div className="col-md-6 text-center">
            <img
              src={manlaptop} // Replace with your image URL
              alt="Custom Hero"
              className="custom-hero-image img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
