import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

const SingleFooter = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [headerText, setHeaderText] = useState("");
  const [footerText, setFooterText] = useState("");
  const { nichename } = useParams();

  const navigate = useNavigate();

  // Fetch Data Function
  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://lionfish-app-nh929.ondigitalocean.app/header-footer/${nichename}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 404) {
        // If the API returns 404, set default text
        setData({});
        setHeaderText("Enter Text or Html");
        setFooterText("Enter Text or Html");
        setLoading(false);
        return;
      }

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const result = await response.json();
      setData(result);
      setHeaderText(result.header_text || "Enter Text or Html");
      setFooterText(result.footer_text || "Enter Text or Html");
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  // Run fetchData when component loads
  useEffect(() => {
    fetchData();
  }, [nichename]);

  // Handle Submit (Update API and Refresh Data)


  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>

        <div dangerouslySetInnerHTML={{ __html: data.footer_text }} />


    </div>
  );
};

export default SingleFooter;
