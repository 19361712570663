import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";

const Team = () => {
  const [sentRequests, setSentRequests] = useState([]); // Stores sent requests
  const [receivedRequests, setReceivedRequests] = useState([]); // Stores received requests
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [actionError, setActionError] = useState(""); // Error for accept/reject actions

  useEffect(() => {
    const fetchRequests = async () => {
      setLoading(true);
      setError("");

      const token = localStorage.getItem("token");
      if (!token) {
        setError("Access token is missing!");
        setLoading(false);
        return;
      }

      const url = `https://lionfish-app-nh929.ondigitalocean.app/projects/team/requests/`;
      const myHeaders = new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      });

      try {
        const response = await fetch(url, { method: "GET", headers: myHeaders });
        if (response.ok) {
          const result = await response.json();
          setSentRequests(result.sent_requests || []);
          setReceivedRequests(result.received_requests || []);
        } else {
          setError("Failed to fetch team requests.");
        }
      } catch (error) {
        console.error(error);
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, []);

  const handleAction = async (projectId, action) => {
    setActionError(""); // Clear any existing errors

    const token = localStorage.getItem("token");
    if (!token) {
      setActionError("Access token is missing!");
      return;
    }

    const url = `https://lionfish-app-nh929.ondigitalocean.app/projects/team/requests/${projectId}/${action}/`;
    console.log(url)
    const myHeaders = new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: myHeaders,
      });

      if (response.ok) {
        // Update the received requests list by removing the processed request
        setReceivedRequests((prevRequests) =>
          prevRequests.filter((request) => request.project !== projectId)
        );
      } else {
        const result = await response.json();
        setActionError(result.message || "Failed to process the action.");
      }
    } catch (error) {
      console.error(error);
      setActionError("An error occurred while processing the action.");
    }
  };

  return (
    <div style={{ padding: "1rem", maxWidth: "100%", overflowX: "auto" }}>
      <h2>Team Requests</h2>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : (
        <>
          <div className="mb-4">
            <h3>Sent Requests</h3>
            <Table bordered hover responsive>
              <thead>
                <tr>
                  <th>Request ID</th>
                  <th>Sender</th>
                  <th>Recipient</th>
                  <th>Project ID</th>
                  <th>Status</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {sentRequests.length > 0 ? (
                  sentRequests.map((request) => (
                    <tr key={request.id}>
                      <td>{request.id}</td>
                      <td>{request.sender}</td>
                      <td>{request.recipient}</td>
                      <td>{request.project}</td>
                      <td>{request.status}</td>
                      <td>{new Date(request.created_at).toLocaleString()}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No sent requests found.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          <div className="mb-4">
            <h3>Received Requests</h3>
            {actionError && <Alert variant="danger">{actionError}</Alert>}
            <Table bordered hover responsive>
              <thead>
                <tr>
                  <th>Request ID</th>
                  <th>Sender</th>
                  <th>Recipient</th>
                  <th>Project ID</th>
                  <th>Status</th>
                  <th>Created At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {receivedRequests.length > 0 ? (
                  receivedRequests.filter((request) => request.status === "Pending") // Filter requests with status "Pending"

                  .map((request) => (
                    <tr key={request.id}>
                      <td>{request.id}</td>
                      <td>{request.sender}</td>
                      <td>{request.recipient}</td>
                      <td>{request.project}</td>
                      <td>{request.status}</td>
                      <td>{new Date(request.created_at).toLocaleString()}</td>
                      <td>
                        <button
                          className="btn btn-success btn-sm me-2"
                          onClick={() => handleAction(request.id, "accept")}
                        >
                          Accept
                        </button>
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => handleAction(request.id, "reject")}
                        >
                          Reject
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center">
                      No received requests found.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </>
      )}
    </div>
  );
};

export default Team;
