import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import './userdashboard.css';

const UserDashboard = () => {
  const [mainBalance, setMainBalance] = useState(null);
  const [referralBalance, setReferralBalance] = useState(null);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalProjects, setTotalProjects] = useState(0);
  const [orders, setOrders] = useState([]);
  const [projects, setProjects] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const fetchBalances = async () => {
    const accessToken = localStorage.getItem("token");
    if (!accessToken) {
      console.error("Access token is missing!");
      return;
    }

    const userid = localStorage.getItem("user");
    if (!userid) {
      console.error("User ID is missing!");
      return;
    }

    const url = `https://lionfish-app-nh929.ondigitalocean.app/wallet/${userid}/`;

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        const data = await response.json();
        const { main_balance, referral_balance } = data;

        setMainBalance(main_balance);
        setReferralBalance(referral_balance);

        localStorage.setItem("main_balance", main_balance);
        localStorage.setItem("referral_balance", referral_balance);
      } else {
        console.error("Failed to fetch balances:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching balances:", error);
    }
  };

  const fetchTransactions = async () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Access token is missing!");
      return;
    }

    const userid = localStorage.getItem("user");
    if (!userid) {
      console.error("User is missing!");
      return;
    }

    const url = `https://lionfish-app-nh929.ondigitalocean.app/wallet/${userid}/transactions/`;
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        const result = await response.json();
        setTransactions(result);
      } else {
        setError("No Transactions.");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred while fetching transactions.");
    } finally {
      setLoading(false);
    }
  };

  const fetchOrders = async () => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("user");

    const url = `https://lionfish-app-nh929.ondigitalocean.app/orders/${userId}`;
    const myHeaders = new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    });

    try {
      const response = await fetch(url, { method: "GET", headers: myHeaders });
      if (response.ok) {
        const result = await response.json();
        setOrders(result);
        setTotalOrders(result.length);
      } else {
        setError("Failed to fetch orders.");
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const fetchProjects = async () => {
    const token = localStorage.getItem("token");

    const url = `https://lionfish-app-nh929.ondigitalocean.app/projects/all/`;
    const myHeaders = new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    });

    try {
      const response = await fetch(url, { method: "GET", headers: myHeaders });
      if (response.ok) {
        const result = await response.json();
        setProjects(result);
        setTotalProjects(result.length);
      } else {
        setError("Failed to fetch projects.");
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    fetchBalances();
    fetchTransactions();
    fetchOrders();
    fetchProjects();
  }, []);

  if (loading) return <div>Loading...</div>;

  return (
    <div className="dashboard">
      {/* Dashboard Cards - Responsive Grid */}
      <div className="row">
        <div className="col-12 col-md-3 card-container">
          <div className="card">
            <h3>Total Orders</h3>
            <p>{totalOrders}</p>
            <Link to="/orders" className="show-all-link">Show All</Link> {/* Link to Orders page */}
          </div>
        </div>

        <div className="col-12 col-md-3 card-container">
          <div className="card">
            <h3>Total Projects</h3>
            <p>{totalProjects}</p>
            <Link to="/projects" className="show-all-link">Show All</Link> {/* Link to Projects page */}
          </div>
        </div>

        <div className="col-12 col-md-3 card-container">
          <div className="card">
            <h3>Main Balance</h3>
            <p>{mainBalance}</p>
          </div>
        </div>

        <div className="col-12 col-md-3 card-container">
          <div className="card">
            <h3>Referral Balance</h3>
            <p>{referralBalance}</p>
          </div>
        </div>
      </div>

      {/* Transaction History Table */}
      <div className="transactions">
        <h2>Transaction History</h2>
        {transactions.length > 0 ? (
          <table className="table bordered responsive">
            <thead>
              <tr>
              <th>ID</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction, index) => (
                <tr key={index}>
                   <td>{transaction.transaction_id}</td>
<td>{new Date(transaction.date).toISOString().split('T')[0]}</td>
<td>{transaction.amount}</td>
                  <td>{transaction.type}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>{error}</p>
        )}
      </div>
    </div>
  );
};

export default UserDashboard;
