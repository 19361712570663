import { useState } from "react";
import Form from "react-bootstrap/Form";

const NicheFilter = ({ filters, getUniqueValues, onNicheChange }) => {
  const [nicheSearchTerm, setNicheSearchTerm] = useState(""); // Local search state

  // Get unique niche values and filter based on search
  const filteredNiches = getUniqueValues("niche").filter((niche) =>
    niche.toLowerCase().includes(nicheSearchTerm.toLowerCase())
  );

  return (
    <div className="col-md-3">
      <Form.Group>
        <Form.Label>Niche</Form.Label>

        {/* Select with Searchable Datalist */}
        <Form.Control
          list="nicheOptions" // Connects to the datalist
          name="niche"
          value={filters.niche}
          onChange={onNicheChange}
          placeholder="Search niche..."
        />

        {/* Datalist for Searchable Dropdown */}
        <datalist id="nicheOptions">
          {filteredNiches.map((niche) => (
            <option key={niche} value={niche} />
          ))}
        </datalist>
      </Form.Group>
    </div>
  );
};

export default NicheFilter;
