import React from "react";
import { Helmet } from "react-helmet"; // Import Helmet for managing the <head> section
import { useNavigate } from "react-router-dom";
import NavCom from "../components/home/Nav";
import Hero from "../components/home/Hero";
import About from "../components/home/About";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="container-fluid">
      {/* SEO Title, Meta Description, and Favicon */}
      <Helmet>
        <title>Link Building Marketplace: Easier & Cheaper Than Ever</title>
        <meta
          name="description"
          content="At LinkScope, you get to buy authority backlinks at affordable price ranges. We categorized and automated every aspect of outreach to make things done in 4 clicks."
        />
        <link rel="icon" href="/favicon.ico" /> {/* Add favicon */}
      </Helmet>

      {/* Navigation Bar */}
      <NavCom />

      {/* Hero Section */}
      <Hero />

      {/* About Section */}
      <About />
    </div>
  );
};

export default Home;