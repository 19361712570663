import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleIcon } from "../helper/icons";
import { useAuth } from "../AuthContext";

const LoginPage = () => {
  const navigate = useNavigate();
  const { auth, login } = useAuth();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [redirectMessage, setRedirectMessage] = useState("");

  // Redirect if already authenticated
  useEffect(() => {
    if (auth) {
      const isAdmin = localStorage.getItem("isadmin") === "true";
      if (isAdmin) {
        navigate("/admin/dashboard");
      } else {
        navigate("/dashboard");
      }
    }
  }, [auth, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    try {
      const response = await fetch("https://lionfish-app-nh929.ondigitalocean.app/auth/login/", {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(formData),
        redirect: "follow",
      });

      const result = await response.json();

      if (response.ok) {
        // Save user info to localStorage
        localStorage.setItem("user", result.user_id);
        localStorage.setItem("email", result.user_id);
        localStorage.setItem("refresh_token", result.refresh);
        localStorage.setItem("isadmin", result.is_admin);
        console.log(result.refresh)
        // Use the login function from AuthContext to set the token
        login(result.access);

        setRedirectMessage("Login successful! Redirecting...");
      } else {
        setRedirectMessage(result.message || "Login failed. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setRedirectMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="container-fluid vh-100 d-flex">
      {/* Left Section */}
      <div className="col-md-6 d-flex flex-column align-items-center justify-content-center bg-light">
        <div className="mb-4">
          <a href="/" className="text-decoration-none">Back to Home</a>
        </div>
        <div className="w-75">
          <h2 className="mb-4">Sign in as a Customer</h2>
          <button className="google-signup">
            <GoogleIcon />
            Sign in with Google
          </button>
          <p>or</p>
          <hr />

          <form onSubmit={handleSubmit}>
            <div className="mb-3 form-group">
              <label className="form-label">Email or Username</label>
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="form-control"
                placeholder="example@example.com"
                aria-label="Email"
                required
              />
            </div>
            <div className="mb-3 form-group">
              <label className="form-label">Password*</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="form-control"
                placeholder="Enter a secure password"
                aria-label="Password"
                required
              />
            </div>

            <div className="mt-3 d-flex infog justify-content-between">
              <p className="subcon">
                Don't have an account? <a href="/register">Sign Up</a>
              </p>
              <p className="subcon">
                <a href="/forgot">Forgot Password?</a>
              </p>
            </div>
            <div className="d-flex justify-content-between mt-4">
              <button type="submit" className="btn primarybutton">
                Submit
              </button>
            </div>
          </form>

          {/* Show redirect message */}
          {redirectMessage && (
            <div className="alert alert-info mt-3">
              {redirectMessage}
            </div>
          )}
        </div>
        <footer className="d-flex align-items-end mt-5">© 2025 All rights reserved to LinkScope</footer>
      </div>

      {/* Right Section */}
      <div className="col-md-6 d-none d-md-flex align-items-end justify-content-center bg-secondary text-white">
        <div className="footer-links d-flex justify-content-between p-5">
          <a href="/contact">Contact</a>
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="/terms">Terms of Use</a>
          <a href="/blog">Blog</a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;